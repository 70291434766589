"use client";
import { isEmpty } from "@/shared/utils/string-utils";
import { cn } from "@/lib/utils";
import { Building2 } from "lucide-react";
import { useState } from "react";

interface CompanyLogoProps {
    logo: string | undefined | null;
    domain: string | undefined | null;
    width?: number;
    name?: string;
}

function CompanyLogo({ logo, domain, width = 10, name = 'company' }: CompanyLogoProps) {
    const [logoNotFound, setLogoNotFound] = useState(isEmpty(logo));
    const [clearbitLogoNotFound, setClearbitLogoNotFound] = useState(isEmpty(domain));

    return (
        <>
            {logoNotFound && clearbitLogoNotFound &&
                <div
                    className={cn(`w-${width} h-${width}`, "rounded-sm bg-slate-200 flex items-center justify-center")}>
                    <Building2 strokeWidth={1.5} />
                </div>
            }
            {!logoNotFound && logo &&
                <img
                    className={cn(`w-${width} h-${width}`, "rounded-sm object-scale-down")}
                    src={logo} alt={`${name} logo`}
                    loading="lazy"
                    onError={() => setLogoNotFound(true)} />
            }
            {logoNotFound && !clearbitLogoNotFound &&
                <img
                    className={cn(`w-${width} h-${width}`, "rounded-sm object-scale-down")}
                    src={'https://logo.clearbit.com/' + domain}
                    alt={`${name} logo`}
                    loading="lazy"
                    onError={() => setClearbitLogoNotFound(true)} />
            }
        </>
    )
}

export default CompanyLogo