import { z } from "zod"
import { jobSearchParamsSchema } from "../request/jobs-search-params.schema"

export const queryNewJobSearchSchema = z.object({
    name: z.string().optional(),
    auto_search: z.boolean(),
    draft_mode: z.boolean().nullable().optional(),
    query: jobSearchParamsSchema,
})

export type QueryNewJobSearch = z.infer<typeof queryNewJobSearchSchema>