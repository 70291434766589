import { z } from "zod"

export const metadataSchema = z.object({
    total_results: z.number().nullable(),
    total_companies: z.number().nullable().optional(),
    truncated_results: z.number(),
    truncated_companies: z.number(),
})

export type Metadata = z.infer<typeof metadataSchema>

export const responseBaseSchema = z.object({
    metadata: metadataSchema,
})

export type ResponseBase = z.infer<typeof responseBaseSchema>

export interface APIResponse<T> extends ResponseBase {
    data: T[]
}