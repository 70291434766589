import { Company, companySchema } from "@/shared/schemas/entity/company.schema";
import { useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { useErrorNotification } from "../toast/use-error-notification";
import { getCompaniesByIds } from "@/services/company.service";
import { FullCompany } from "@/schemas/entities/full-company.schema";

export function useRevealCompany({ company, updateCompany, showUpgradeDialog, postRevealAction }: { company: FullCompany, updateCompany: (company: FullCompany) => void, showUpgradeDialog: () => void, postRevealAction?: (company: Company) => void }) {
    const [isDataFetching, setIsDataFetching] = useState(false)
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })

    const revealCompany = () => {
        setIsDataFetching(true)
        getCompaniesByIds([company.id]).then((res) => {

            if (res.metadata.truncated_results > 0) {
                showUpgradeDialog()
            }

            if (Array.isArray(res.data) && res.data.length > 0) {
                const company_unblurred = companySchema.parse(res.data[0])
                if (updateCompany) {
                    const newCompany = { ...company, ...company_unblurred }
                    updateCompany(newCompany)
                    if (postRevealAction) postRevealAction(company_unblurred)
                }
            }

            setIsDataFetching(false)
            queryClient.invalidateQueries({ queryKey: ['me'] })
        }).catch(() => {
            setIsDataFetching(false)
            queryClient.invalidateQueries({ queryKey: ['me'] })
            showErrorNotification()
        })
    }

    return { isDataFetching, showUpgradeDialog, revealCompany };

}