import { Toaster } from "@/components/ui/base/toaster"
import { Outlet } from "react-router-dom"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/base/alert"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import NavBar from "@/components/ui/layout/nav_bar"
import OnboardingFormDialog from "@/components/ui/dialog/onboarding-form-dialog"
import { cn } from "@/lib/utils"
import { PaymentPendingBanner } from "@/components/ui/banners/payment-pending-banner"


function MainLayout() {
  const showServiceUnavailableBanner = false;
  return (
    <div className="flex flex-col flex-grow h-screen w-full">
      <NavBar className="flex-none" />
      <PaymentPendingBanner className="flex-none" />
      {showServiceUnavailableBanner && <UnavailableBanner className="flex-none" />}
      <Outlet />
      <Toaster />
      <OnboardingFormDialog />
    </div>
  )
}

export default MainLayout

function UnavailableBanner({ className }: { className?: string }) {
  return (
    <div className={cn("p-2 bg-white-100", className)}>
      <Alert className="bg-white" variant="destructive" >
        <ExclamationTriangleIcon className="h-4 w-4" />
        <AlertTitle>Service temporarily unavailable</AlertTitle>
        <AlertDescription>
          TheirStack is currently unavailable due to scheduled maintenance until 17/06/2024 16:30 CET. We apologize for the inconvenience.
        </AlertDescription>
      </Alert>
    </div>
  )
}

