import { z } from "zod";
import { companySchema } from "@/shared/schemas/entity/company.schema";
import { technologySchema } from "./technology.schema";

export const jobFoundSchema =
    z.object({
        id: z.number(),
        job_title: z.string(),
        url: z.string(),
        date_posted: z.string(),
    })
export type JobFound = z.infer<typeof jobFoundSchema>;

export const technologyDetailsFoundSchema = z.object({
    technology: technologySchema,
    jobs: z.number(),
    first_date_found: z.string(),
    last_date_found: z.string(),
    rank_within_category: z.number().nullable(),
    relative_occurrence_within_category: z.number().nullable(),
    confidence: z.enum(["low", "medium", "high"]),
    theirstack_score: z.number().nullable(),
    jobs_last_7_days: z.number(),
    jobs_last_30_days: z.number(),
    jobs_last_180_days: z.number(),
})
export type TechnologyDetaislFound = z.infer<typeof technologyDetailsFoundSchema>;

export const fullCompanySchema = companySchema.extend({
    technologies_found: z.array(technologyDetailsFoundSchema),
    jobs_found: z.array(jobFoundSchema),

});

export type FullCompany = z.infer<typeof fullCompanySchema>;

