export const toStringAndSkipQuotationMarks = (value: string | null | undefined) => value ? value.replaceAll('"', '').replaceAll(/\r\n/g, '\n').replaceAll(/\r/g, '\n') : "";

// source https://themightyprogrammer.dev/snippet/js-title-case
export function titleCase(sentence: string) {
    return sentence.replace(/w+/g, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
}

export function isEmpty(value: string | null | undefined) {
    return value === undefined || value === null || value === '';
}
export function hideString(s: string): string {
    return s.replace(/\S/g, "*");
}

export function capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}