"use client";
import { BillingProduct } from "@/shared/schemas/entity/billing-product.schema";
import { useState } from "react";
import { formatMoney, formatNumberWithComma, formatStripeNumber } from "@/shared/utils/number-utils";
import { Button } from "@/shared/ui/base/button";
import { getCreditPrice } from "./one-time-pricing";
import { Separator } from "@/shared/ui/base/separator";
import { Currency } from "@/shared/schemas/response/geolocation.schema";
import HelpCreditTooltip from "@/shared/ui/pricing/help_credit_tooltip";


export const API_CREDIT_CONTENT = <p className="text-xs">A company credit allows you to track one company for a month, giving you access to comprehensive information including all job postings, technologies used and firmographics. <a href="https://theirstack.com/en/pricing#faqs" target="_blank" className="text-blue-500 underline">Learn more</a></p>


export default function RecurrentPricing({ products, currency, onBillingPriceSelected }: { products: BillingProduct[], currency: Currency, onBillingPriceSelected: (price_id: string) => void }) {
    const [frequency] = useState<"month" | "year">("month")
    return (
        <div>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {products
                    .filter(product => product.prices.some(price => price.recurring_interval === frequency && price.currency === currency))
                    .map(product => {
                        const price = product.prices.find(price => price.recurring_interval === frequency && price.currency === currency)
                        const amount = Math.floor(formatStripeNumber(price?.unit_amount ?? 0) / (frequency === "year" ? 12 : 1))
                        const credits = (product?.metadata?.credits ?? 2) as number * (frequency === "year" ? 12 : 1)
                        return (
                            <div key={product.id} className="border rounded-md p-4">
                                <div className="flex flex-col">
                                    <p className="text-lg font-bold">{product.name}</p>
                                    <p className="text-sm mt-4 line-clamp-2 h-10 text-gray-500 mb-2">{product.metadata.description_for_website}</p>
                                    <p className="my-4">
                                        <span className="text-3xl font-semibold">{formatMoney(amount, currency)}</span>
                                        {frequency === "year" && <span className="text-xs font-medium text-gray-600">{`/month (billed annually)`}</span>}
                                        {frequency === "month" && <span className="text-xs font-medium text-gray-600">{`/month`}</span>}
                                    </p>
                                    <Separator className="my-4" />
                                    <p className="text-md text-center font-medium">{formatNumberWithComma(credits)} companies</p>
                                    <div className="flex items-center justify-center gap-1 text-gray-500 mt-1">
                                        <p className="text-xs">{getCreditPrice(price?.unit_amount || 0, credits.toString(), currency)} per company credit</p>
                                        <HelpCreditTooltip>
                                            {API_CREDIT_CONTENT}
                                        </HelpCreditTooltip>
                                    </div>
                                    <Separator className="my-4" />
                                    <Button
                                        className="center mt-4"
                                        onClick={() => onBillingPriceSelected(price?.id ?? "")}>Subscribe</Button>
                                </div>
                            </div>
                        )
                    })}
                <div className="flex flex-col border rounded-md p-4">
                    <p className="text-lg font-bold">Enterprise</p>
                    <p className="text-sm mt-4 line-clamp-2 h-10 text-gray-500 mb-2">Best for teams with high volume</p>
                    <p className="my-4">
                        <span className="text-3xl font-semibold">Custom</span>
                    </p>
                    <Separator className="my-4" />
                    <p className="text-md text-center font-medium mb-6">Custom plans</p>
                    <Separator className="my-4" />
                    <Button
                        className="center mt-4"
                        onClick={() => onBillingPriceSelected("contact_sales")}>Contact Sales</Button>
                </div>
            </div>
        </div>
    )
}