

import SearchFilterBase from "@/components/ui/search-filter/search-filter-base"
import { RowSelectionState, Table } from "@tanstack/react-table"
import ButtonAddFilter from "../../../../../components/ui/table/data-table-filters-add-button"

import { Button } from "@/shared/ui/base/button"
import { renameSearch, updateCompanySearch } from "@/services/saved-search.service"
import { useToast } from "../../../../../components/ui/base/use-toast"
import SearchTitleInput from "../../../../../components/ui/search-title-input"
import AddCompanyToList from "../../../../../components/ui/table/actions/action-add-to-company-to-list"
import FindPeopleButton from "../../../../../components/ui/table/actions/action-find-people"
import { filters } from "../../components/filters"
import useWarnIfUnsavedChanges from "@/components/hooks/use-warn-if-unsaved-changes"
import { CompanySavedSearch } from "@/schemas/entities/saved-search.schema"
import { FullCompany, fullCompanySchema } from "@/schemas/entities/full-company.schema"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification"
import ExportCompaniesButton from "../../components/action-export-companies"
import { Separator } from "@/shared/ui/base/separator"
import SwitchSearchObjectTab from "@/components/ui/switch-search-object-tab"
import { IFiltersProps } from "@/shared/schemas/entity/filter.schema"
import LongRunningQueryBanner, { LongRunningQueryBannerHandle, useLongRunningCompanyQuery } from "@/components/ui/banners/long-running-query-banner"
import { useRef } from "react"
import APICurlButton from "@/components/ui/table/actions/action-get-api-curl"
import { getCompaniesCURL } from "@/services/company.service"
import { isFilterPermanent } from "@/components/ui/search-filter/filter-utils"

interface DataTableToolbarProps {
    search: CompanySavedSearch,
    setSearch: (_search: CompanySavedSearch) => void,
    table: Table<FullCompany>,
    isDataFetching: boolean,
    isCurrentSearchSearched: boolean,
    searchedSP: CompaniesSearchParams,
    totalResults: number,
    isCurrentSearchSave: boolean,
    rowSelection: RowSelectionState,
    onChangeFilters: (_params: CompaniesSearchParams) => void,
    appliedFilters: IFiltersProps[],
    addFilters: (filters: IFiltersProps[]) => void,
    onChangeFilter: (index: number, filter: IFiltersProps) => void,
    onRemoveFilter: (index: number) => void,
    setFiltersAsNotChanged: () => void
}

export function DataTableToolbar({ search,
    isDataFetching,
    setSearch,
    searchedSP,
    table,
    totalResults,
    isCurrentSearchSave,
    appliedFilters,
    addFilters,
    onChangeFilter,
    onRemoveFilter,
    setFiltersAsNotChanged
}: DataTableToolbarProps) {
    const { toast } = useToast()
    useWarnIfUnsavedChanges(!isCurrentSearchSave);
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const longRunningQueryBannerRef = useRef<LongRunningQueryBannerHandle>(null);
    useLongRunningCompanyQuery({ longRunningQueryBannerRef, appliedFilters })

    const handleSaveSearch = () => {
        const newSearch = { ...search, ...{ body: searchedSP } }
        updateCompanySearch(search.id, newSearch).then((response) => {
            setSearch(response)
            setFiltersAsNotChanged()
            toast({ title: "Search saved" })
        }).catch(() => {
            showErrorNotification()
        })
    }

    const handleRenameSearch = (name: string) => {
        renameSearch(search.id, name).then(() => {
            setSearch({ ...search, ...{ name: name } })
            toast({ title: "Search saved" })
        }).catch(() => {
            showErrorNotification()
        })
    }

    const isFilterRemovable = (filter: IFiltersProps) => {
        const exist_job_filter = appliedFilters.some(f => f.id.startsWith('job_filters.') && f.id !== 'job_filters.posted_at')
        if (filter.id === 'job_filters.posted_at' && exist_job_filter) {
            return false
        }
        return true
    }

    return (
        <>
            <div className="flex flex-wrap md:flex-nowrap items-center justify-start gap-2">
                <SwitchSearchObjectTab totalCompanies={totalResults} default_tab="companies" company_filters={searchedSP} was_searched={true} />
                <SearchTitleInput className="grow overflow-hidden" name={search.name} updateName={handleRenameSearch} />
                <div className="flex flex-wrap item-center gap-2 content-center">
                    <div className="flex flex-grap gap-1 border rounded-md bg-white">
                        <AddCompanyToList isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => fullCompanySchema.parse(row))} />
                        <ExportCompaniesButton isDataFetching={isDataFetching} table={table} />
                        <FindPeopleButton isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => fullCompanySchema.parse(row))} />
                        <APICurlButton className="hidden lg:block" curl={getCompaniesCURL(searchedSP, { pageIndex: 0, pageSize: 10 })} />
                    </div>
                </div>
            </div >
            <Separator />
            <div className="flex items-start justify-between gap-1">
                <div className="flex flex-wrap items-center gap-1">
                    {appliedFilters.map((filter, index) => (
                        <SearchFilterBase
                            key={index}
                            index={index}
                            filter={filter}
                            changeFilter={onChangeFilter}
                            removeFilter={onRemoveFilter}
                            appliedFilters={appliedFilters}
                            isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                            isRemovable={isFilterRemovable(filter)}
                            hasChanged={filter.hasChanged || false} />
                    ))}
                    <ButtonAddFilter availableFilters={filters} appliedFilters={appliedFilters} addFilters={addFilters} />
                </div>
                {!isCurrentSearchSave && <Button variant="secondary" size="sm" onClick={() => handleSaveSearch()} className="bg-orange-300 hover:bg-orange-200"> Save</Button>}
            </div>
            <LongRunningQueryBanner ref={longRunningQueryBannerRef} />
        </>
    )
}
