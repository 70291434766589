
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/base/form";
import { MultiSelectValues, SelectOption } from "../../../shared/schemas/entity/filter.schema";
import { Button } from "@/shared/ui/base/button";
import { Checkbox } from "@/components/ui/base/checkbox";
import { FormLabel } from "@/components/ui/base/form";
import { areObjectsEqual } from "@/lib/search-params-utils";
import { Tag } from "./components/tag";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ScrollWithFadeOut } from "../scroll-fade-out";

const FormSchema = z.object({
    items: z.array(z.string())
})

export function SearchFilterValuePreviewMultiSelect({ value, availableValues }: { value: string[], availableValues: MultiSelectValues }) {
    const valueObjects = value ? value.map((item) => availableValues.find((value) => value.id === item)).filter((item): item is SelectOption => item !== undefined) : []
    const valueLabels = valueObjects.map((item) => item?.label)
    const valueString = valueLabels ? valueLabels.slice(0, 1).join(", ") + (valueLabels.length > 1 ? ` +${valueLabels.length - 1}` : '') : ''
    return <p className="text-sm">{valueString}</p>
}

export function SearchFilterFormMultiSelect({ value, onChangeValue, availableValues, onInteractOutside, closePopover }: { value: string[], onChangeValue: (_value: string[] | undefined) => void, availableValues: MultiSelectValues, onInteractOutside: boolean, closePopover: () => void }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedValues, setSelectedValues] = useState<MultiSelectValues>([]);
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            items: value || [],
        },
    })
    const formCurrentValue = useWatch({ control: form.control, name: 'items' })

    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues());
        }
    }, [onInteractOutside]);

    useEffect(() => {
        if (!formCurrentValue) return
        setSelectedValues(availableValues.filter((item) => formCurrentValue.includes(item.id)))
    }, [formCurrentValue])

    const filteredItems = availableValues.filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase()));

    function onSubmit(data: z.infer<typeof FormSchema> | undefined) {
        const newValue = data && data.items && data.items.length > 0 ? data.items : undefined
        if (areObjectsEqual(newValue, value)) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }

    function sortbyStatus(): (a: { id: string; label: string; }, b: { id: string; label: string; }) => number {
        return (a, b) => {
            // Sort active items first
            if (value && value.includes(a.id) && !value.includes(b.id)) {
                return -1;
            }
            if (value && !value.includes(a.id) && value.includes(b.id)) {
                return 1;
            }
            // Sort remaining items alphabetically
            return a.label.localeCompare(b.label);
        };
    }



    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
                <div className={`relative flex flex-col items-start border rounded  max-h-40 overflow-y-auto ${selectedValues.length > 0 ? "p-2" : "px-3"}`} cmdk-input-wrapper="" >
                    <div className="flex flex-row gap-1 flex-wrap">
                        {selectedValues.map((item) => (
                            <Tag key={item.id} id={item.id} name={item.label} img={item.flag} onClick={(id_to_remove) => {
                                form.setValue("items", form.getValues("items").filter((id) => id !== id_to_remove));
                            }} />
                        ))}
                        <input
                            type="text"
                            autoFocus={true}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                            className="h-8 min-w-16 rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                        />
                    </div>
                    {selectedValues.length > 0 && <Cross2Icon className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black" onClick={() => form.setValue("items", [])} />}
                </div>
                <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                        <div className="relative">
                            <FormItem>
                                <ScrollWithFadeOut className="max-h-[200px]">
                                    <FormMessage />
                                    {filteredItems.filter((item) => !selectedValues.map((item) => item.id).includes(item.id))
                                        .sort(sortbyStatus())
                                        .map((item) => (
                                            getFormField(item, form)
                                        ))}
                                </ScrollWithFadeOut>
                            </FormItem>
                        </div>
                    )}
                />
                <Button type="submit" size="sm">Save</Button>
            </form>
        </Form>
    )
}

function getFormField(item: SelectOption, form: any) {
    return <FormField
        key={item.id}
        control={form.control}
        name="items"
        render={({ field }) => {
            return (
                <FormItem
                    key={item.id}
                    className="flex flex-row items-center space-x-3 space-y-0"
                >
                    <FormControl>
                        <Checkbox
                            id={item.id}
                            checked={field.value?.includes(item.id)}
                            onCheckedChange={(checked) => {
                                return checked
                                    ? field.onChange([...(field.value || []), item.id])
                                    : field.onChange(
                                        field.value?.filter(
                                            (value: string) => value !== item.id
                                        )
                                    );
                            }} />
                    </FormControl>
                    <FormLabel htmlFor={item.id} className="text-sm font-normal cursor-pointer hover:underline">
                        <div className="flex space-x-2 align-middle items-center">
                            {item.flag && <img src={item.flag} alt={item.label} className="w-4 h-4" />}
                            <p className="text-sm font-normal"> {item.label} </p>
                        </div>
                    </FormLabel>
                </FormItem>
            );
        }} />;
}
