import { z } from "zod"

export const jobsCountResponseSchema = z.object({
    jobs: z.object({
        count: z.number(),
        revealed: z.number(),
        unrevealed: z.number(),
    }),
    companies: z.object({
        count: z.number(),
        revealed: z.number(),
        unrevealed: z.number(),
    })
})

export type JobCountResponse = z.infer<typeof jobsCountResponseSchema>