import { useEffect, useMemo, useState } from "react"
import { OnChangeFn, PaginationState, SortingState } from "@tanstack/react-table"
import { areObjectsEqual } from "@/lib/search-params-utils"
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification"
import { fromSearchParamsToSortingState } from "@/components/hooks/data-table/use-fetch-data-and-total-results-for-table"
import { BaseSearchParams } from "@/schemas/request/base-search-params.schema"

const FIELDS_TO_OMIT_FOR_COMPARISON = ['page', 'limit', 'include_total_results']

export function useFetchDataForTable<TSearchParams extends BaseSearchParams, TData>(
    savedSearch: TSearchParams | undefined,
    fetchDataFromAPI: (searchParams: TSearchParams, pagination: PaginationState) => Promise<TData[]>,
    shouldCallAPI?: (searchParams: TSearchParams) => boolean,
) {
    const [searchedSP, setSearchedSP] = useState<TSearchParams>()
    const [dirtySP, setDirtySP] = useState<TSearchParams>()
    const [data, setData] = useState<TData[]>()
    const [isDataFetching, setIsDataFetching] = useState<boolean>(true)
    const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })
    const [sorting, setSorting] = useState<SortingState>([])
    const { showErrorNotification } = useErrorNotification({ isError: false })

    const isCurrentSearchSaved = useMemo(() => {
        if (!dirtySP || !savedSearch) return;
        return areObjectsEqual(dirtySP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON)
    }, [dirtySP, savedSearch]);

    const isCurrentSearchSearched = useMemo(() => {
        if (!dirtySP || !searchedSP) return;
        return areObjectsEqual(dirtySP, searchedSP, FIELDS_TO_OMIT_FOR_COMPARISON)
    }, [dirtySP, searchedSP]);

    useEffect(() => {
        if (!savedSearch) return
        if (!searchedSP || !areObjectsEqual(searchedSP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON)) {
            setSearchedSP(savedSearch)
            setDirtySP(savedSearch)
            if (shouldCallAPI && !shouldCallAPI(savedSearch)) return
            setSorting(fromSearchParamsToSortingState(savedSearch))
            fetchData(savedSearch, pagination)
        }
    }, [savedSearch])

    const onChangeFilters = (newSearchParams: TSearchParams) => {
        setSearchedSP(newSearchParams)
        if (shouldCallAPI && !shouldCallAPI(newSearchParams)) return
        const newPagination = resetPagination()
        fetchData(newSearchParams, newPagination)
    }

    const onChangePagination: OnChangeFn<PaginationState> = (updaterOrValue: (PaginationState | ((old: PaginationState) => PaginationState))) => {
        if (!searchedSP) return
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newPagination = updater(pagination)
        setPagination(newPagination)
        if (shouldCallAPI && !shouldCallAPI(searchedSP)) return
        fetchData(searchedSP, newPagination)
    }

    const onChangeSorting: OnChangeFn<SortingState> = (updaterOrValue: (SortingState | ((old: SortingState) => SortingState))) => {
        if (!searchedSP) return
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newSorting = updater(sorting)
        setSorting(newSorting)
        if (shouldCallAPI && !shouldCallAPI(searchedSP)) return
        const newSearch = { ...searchedSP, order_by: [{ field: newSorting[0]?.id, desc: newSorting[0]?.desc }] }
        setSearchedSP(newSearch)
        setDirtySP(newSearch)
        const newPagination = resetPagination()
        fetchData(newSearch, newPagination)
    }

    const fetchData = async (search: TSearchParams, ps: PaginationState) => {
        setIsDataFetching(true)
        fetchDataFromAPI(search, ps).then((data) => {
            setData(data)
            setIsDataFetching(false)
        }, (error) => {
            console.error(error)
            showErrorNotification()
            setIsDataFetching(false)
        })
    }

    const resetPagination = () => {
        const newPagination = { ...pagination, pageIndex: 0 }
        if (pagination.pageIndex != 0) setPagination(newPagination)
        return newPagination
    }

    return { searchedSP, dirtySP, data, isDataFetching, pagination, sorting, onChangeFilters, onChangePagination, onChangeSorting, isCurrentSearchSaved, isCurrentSearchSearched, setDirtySP }
}