import { z } from "zod"
import { billingPriceSchema } from "./billing-price.schema"

export const billingProductSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    metadata: z.object({
        credits: z.string().optional(),
        description_for_website: z.string().optional(),
    }),
    is_api_available: z.boolean(),
    prices: z.array(billingPriceSchema),
})

export type BillingProduct = z.infer<typeof billingProductSchema>