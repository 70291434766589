
import { DataTablePagination } from "@/components/ui/table/data-table-pagination"
import { DataTable } from "@/components/ui/table/data-table"
import { DataTableToolbar } from "./data-table-toolbar"
import { useColumnsVisibilityBasedOnSearch } from "../../components/use-columns-visibility-based-on-search"
import { CompanySavedSearch } from "@/schemas/entities/saved-search.schema"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { useAutoSearchTable } from "@/components/hooks/data-table/use-auto-search-table"
import { getCompaniesFromSearchParams } from "@/services/company.service"
import { companyColumns, hiddenCompanyColumns } from "../../components/columns-company"
import { FullCompany } from "@/schemas/entities/full-company.schema"
import OutOfCreditsBanner from "@/components/ui/banners/out-of-credits-banner"
import DataTableInitialState from "@/components/ui/table/data-table-initial-state"

interface DataTableProps {
    search: CompanySavedSearch,
    setSearch: (_search: CompanySavedSearch) => void,
}

export function DataTableWithCompanyToolbar({
    search,
    setSearch,
}: DataTableProps) {
    const {
        table,
        metadata,
        columnVisibility,
        setColumnVisibility,
        searchedSP,
        isDataFetching,
        totalResults,
        isCurrentSearchSaved,
        isCurrentSearchSearched,
        rowSelection,
        setFiltersAsNotChanged,
        onChangeFilters,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter
    } = useAutoSearchTable<CompaniesSearchParams, FullCompany>(search.body, companyColumns, hiddenCompanyColumns, getCompaniesFromSearchParams)
    useColumnsVisibilityBasedOnSearch(searchedSP, columnVisibility, setColumnVisibility)

    return (
        <>
            {metadata.truncated_results > 0 &&
                <OutOfCreditsBanner hidden_results={metadata.truncated_results} />
            }
            <DataTableToolbar
                search={search}
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                setSearch={setSearch}
                table={table}
                searchedSP={searchedSP}
                totalResults={totalResults}
                isCurrentSearchSave={isCurrentSearchSaved}
                rowSelection={rowSelection}
                setFiltersAsNotChanged={setFiltersAsNotChanged}
                onChangeFilters={onChangeFilters}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
            />
            {!isCurrentSearchSearched && <DataTableInitialState search_type="companies" />}
            {isCurrentSearchSearched &&
                <>
                    <DataTable table={table} columns={companyColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            }
        </>
    )
}