import { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../base/dialog";
import UpgradeButton from "../layout/components/upgrade_button";

export interface OutOfCreditsDialogHandle {
    open: () => void;
    close: () => void;
}

export interface OutOfCreditsDialogProps { }

const OutOfCreditsDialog = forwardRef<OutOfCreditsDialogHandle, OutOfCreditsDialogProps>((_props, ref) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
    }));

    return (
        <Dialog open={show} onOpenChange={(open) => setShow(open)}>
            <DialogContent>
                <div className="w-full flex items-center justify-center  align-middle mb-6">
                    <img src="/secure-feature.svg" alt="Locked Feature" className="w-60" />
                </div>
                <DialogTitle>Upgrade your plan for more credits.</DialogTitle>
                <DialogDescription>
                    Company data cannot be revealed due to lack of account credits.
                </DialogDescription>
                <div className="flex items-center justify-center mt-6">
                    <UpgradeButton label="View pricing plans" showIcon={false} variant="default" />
                </div>

            </DialogContent>
        </Dialog>
    );
});

export default OutOfCreditsDialog;
