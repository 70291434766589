
import { Card } from "@/shared/ui/base/card";
import { CheckIcon, UserIcon } from "lucide-react";
import { SavedSearch } from "@/schemas/entities/saved-search.schema";
import { Popover, PopoverContent, PopoverTrigger } from "@/shared/ui/base/popover";
import { Badge } from "@/shared/ui/base/badge";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "@/shared/ui/base/command";
import { cn } from "@/lib/utils";
import { TeamMember } from "@/schemas/entities/team-members.schema";


export default function UserFilters({ team_members, selectedUserIds, setSelectedUserIds, searches }: { team_members: TeamMember[], selectedUserIds: string[], setSelectedUserIds: (ids: string[]) => void, searches: SavedSearch[] }) {
    return (

        <Popover modal={true}>
            <PopoverTrigger asChild>
                <Card className="h-9 flex items-center px-2 gap-x-2 text-sm flex-shrink-0">
                    <div className="flex space-x-2 items-center">
                        <UserIcon className="h-4 w-4" />
                        <p className="text-sm">Owner</p>
                    </div>
                    {selectedUserIds?.length > 0 && (
                        <>
                            <p className="text-sm text-gray-500"> contains any </p>
                            <Badge
                                variant="secondary"
                                className="rounded-sm px-1 font-normal lg:hidden"
                            >
                                {selectedUserIds?.length}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                                {selectedUserIds.length > 2 ? (
                                    <Badge
                                        variant="secondary"
                                        className="rounded-sm px-1 font-normal"
                                    >
                                        {selectedUserIds.length} selected
                                    </Badge>
                                ) : (
                                    team_members
                                        .filter((option) => selectedUserIds.includes(option.id.toString()))
                                        .map((option) => (
                                            <Badge
                                                variant="secondary"
                                                key={option.id}
                                                className="rounded-sm px-1 font-normal"
                                            >
                                                {option.first_name + ' ' + option.last_name}
                                            </Badge>
                                        ))
                                )}
                            </div>
                        </>
                    )}
                </Card>
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0" align="start" >
                <Command>
                    <CommandInput placeholder="Owner" />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {team_members.map((option) => {
                                const isSelected = selectedUserIds.includes(option.id.toString())
                                return (
                                    <CommandItem
                                        key={option.id.toString()}
                                        onSelect={() => {
                                            if (isSelected) {
                                                setSelectedUserIds(selectedUserIds.filter((id) => id != option.id.toString()))
                                            } else {
                                                setSelectedUserIds([...selectedUserIds, option.id.toString()])
                                            }
                                        }}
                                    >
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                isSelected
                                                    ? "bg-primary text-primary-foreground"
                                                    : "opacity-50 [&_svg]:invisible"
                                            )}
                                        >
                                            <CheckIcon className={cn("h-4 w-4")} />
                                        </div>
                                        <UserIcon className="h-4 w-4" />
                                        <span>{option.first_name + ' ' + option.last_name}</span>
                                        <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                            {searches.filter((search) => search.creator.id == option.id).length}
                                        </span>

                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                        {selectedUserIds.length > 0 && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => setSelectedUserIds([])}
                                        className="justify-center text-center"
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>

    )

}



