import { useRevealCompany } from "@/components/hooks/company/use-reveal-company";
import { ConfirmRevealCompanyDialog } from "@/components/ui/dialog/confirm-reveal-company-dialog";
import { OutOfCreditsDialogHandle } from "@/components/ui/dialog/out-of-credits-dialog";
import { formatCompactNumber } from "@/shared/utils/number-utils";
import { FullCompany } from "@/schemas/entities/full-company.schema";
import { useRef } from "react";

function getAllJobsFromCompanyLink(company_name: string) {
    return '/search/jobs/new?query=' + btoa(encodeURIComponent(JSON.stringify({
        name: 'Jobs posted by ' + company_name + ' in the last year',
        auto_search: true,
        query: {
            page: 0,
            limit: 10,
            company_name_or: [company_name],
            posted_at_max_age_days: 365,
            order_by: [{
                field: 'date_posted',
                desc: true,
            }],
        },
    })))
}

export default function HistoricalJobsColumn({ company, updateCompany }: { company: FullCompany, updateCompany: (company: FullCompany) => void }) {
    const { revealCompany: revealCompanyAndOpenNewTab } = useRevealCompany({ company, updateCompany, postRevealAction: (company) => window.open(getAllJobsFromCompanyLink(company.name), '_blank'), showUpgradeDialog: () => outOfCreditsDialogRef.current?.open() })
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    return (
        <div className="max-w-[300px]">
            {company.has_blurred_data == false &&
                <a
                    target="_blank"
                    href={getAllJobsFromCompanyLink(company.name)}
                    className="max-w-[300px] text-clip font-normal hover:underline">
                    {formatCompactNumber(company.num_jobs || 0)}
                </a>
            }
            {company.has_blurred_data == true &&
                <ConfirmRevealCompanyDialog confirmAction={revealCompanyAndOpenNewTab} n_companies={1}>
                    <p className="max-w-[300px] text-clip font-normal hover:underline cursor-pointer">
                        {formatCompactNumber(company.num_jobs || 0)}
                    </p>
                </ConfirmRevealCompanyDialog>
            }
        </div>
    )
}
