import React, { useEffect, useState } from 'react';

import { DataTableWithJobsToolbar } from './components/data-table';
import useUrlQueryParams from './hooks/use-url-query-params';
import { QueryNewJobSearch, queryNewJobSearchSchema } from '@/schemas/entities/query-new-job-search.schema';

import { useSearchParams } from 'react-router-dom';
import { PageLayout } from '@/components/ui/layout/page_layout';
import { getLocation } from '@/services/geolocation.service';
import { useQuery } from '@tanstack/react-query';
import { Geolocation } from '@/shared/schemas/response/geolocation.schema';

const default_search: QueryNewJobSearch = {
    auto_search: false,
    query: {
        page: 1,
        limit: 10,
        posted_at_max_age_days: 15,
        order_by: [{ desc: true, field: 'date_posted' }],
    },
}

const NewJobSearchPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState<QueryNewJobSearch>()
    const { search: searchFromUrl, isLoading: isLoadingSearch } = useUrlQueryParams<QueryNewJobSearch>(searchParams.get('query'), queryNewJobSearchSchema.parse)
    const { data: location, isLoading, isError } = useQuery<Geolocation>({
        queryKey: ['geolocation'],
        queryFn: getLocation,
        staleTime: Infinity,
    });

    useEffect(() => {
        if (isLoadingSearch) return
        setSearch(searchFromUrl ? searchFromUrl : default_search)
        document.title = searchFromUrl && searchFromUrl.name ? searchFromUrl.name + " · New job search · TheirStack" : "New job search · TheirStack"
    }, [searchFromUrl, isLoadingSearch]);

    useEffect(() => {
        if (searchParams.get('query')) return
        if (!search) return
        if (location && !isLoading && !isError && location.country_code && !search.query.job_country_code_or) {
            const new_search = { ...search, query: { ...search.query, job_country_code_or: [location.country_code] } }
            setSearch(new_search)
        }
    }, [location, search])

    return (
        <PageLayout className='overflow-hidden'>
            <div className='flex flex-col gap-4 h-full flex-grow overflow-hidden'>
                {search && < DataTableWithJobsToolbar search={search} />}
            </div>
        </PageLayout >
    );
};

export default NewJobSearchPage;

