

import { Card } from "@/shared/ui/base/card";
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from "@/shared/ui/base/popover";
import { IFilterValue, IFiltersProps, IOperator, MultiSelectValues, conditionAttributes } from "../../../shared/schemas/entity/filter.schema";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ChevronDown, LucideIcon, X } from "lucide-react";
import { SearchFilterFormArrayString, SearchFilterValuePreviewArrayString } from "./search-filter-form-array-strings";
import { SearchFilterFormNumber, SearchFilterValuePreviewNumber } from "./search-filter-form-number";
import { SearchFilterFormBoolean, SearchFilterValuePreviewBoolean } from "./search-filter-form-boolean";
import { SearchFilterFormMultiSelect, SearchFilterValuePreviewMultiSelect } from "./search-filter-form-multi-select";
import { SearchFilterFormTechnologies, SearchFilterValuePreviewTechnologies } from "./search-filter-form-technologies";
import { SearchFilterFormSelect, SearchFilterValuePreviewSelect } from "./search-filter-form-select";
import { SearchFilterFormCompanyList, SearchFilterValuePreviewCompanyList } from "./search-filter-form-company-list";
import { SearchFilterFormBetweenDates, SearchFilterValuePreviewBetweenDates } from "./search-filter-form-between-dates";
import { PopoverClose } from "@radix-ui/react-popover";
import { SearchFilterFormIndustry, SearchFilterValuePreviewIndustry } from "./search-filter-form-industries";
import { SearchFilterFormTechnologyCategories, SearchFilterValuePreviewTechnologyCategories } from "./search-filter-form-technology-categories";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../base/select";
import { SearchFilterFormSelectNumber, SearchFilterValuePreviewSelectNumber } from "./search-filter-form-select-number";
import { cn } from "@/lib/utils";

interface FilterTitleProps {
    title: string
    icon: LucideIcon,
}

function FilterTitle({ title, icon: Icon }: FilterTitleProps) {
    return (
        <div className="flex space-x-1 items-center">
            <Icon className="h-4 w-4" />
            <p className="text-sm text-nowrap">{title}</p>
        </div>
    )
}

interface FilterProps {
    index: number,
    filter: IFiltersProps,
    changeFilter: (index: number, _value: IFiltersProps) => void,
    removeFilter: (index: number) => void,
    appliedFilters: IFiltersProps[],
    isRemovable: boolean,
    isPermanent: boolean,
    hasChanged: boolean,
}

export default function SearchFilterBase({ index, filter, changeFilter, removeFilter, appliedFilters, isRemovable, isPermanent, hasChanged }: FilterProps) {
    const [isValuePopoverOpen, setIsValuePopoverOpen] = useState<boolean>(filter.value === undefined && !isPermanent)
    const [usedOperators, setUsedOperators] = useState<IOperator[]>(filter.availableOperators)
    const isBigPopOver = filter.data_type == 'number' && filter.operator == IOperator.between_dates
    const [interactOutside, setInteractOutside] = useState(false);

    useEffect(() => {
        const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id)
        const operatorsUsed = appliedFiltersWithSameId.map((appliedFilter) => appliedFilter.operator).filter((operator): operator is IOperator => operator !== undefined);
        setUsedOperators(operatorsUsed)
    }, [appliedFilters, filter])

    const onChangeValue = (value: IFilterValue) => {
        const newFilter = { ...filter }
        newFilter.value = value
        changeFilter(index, newFilter)
        setIsValuePopoverOpen(false)
        setInteractOutside(false)
    }
    const closePopover = () => {
        setIsValuePopoverOpen(false)
        setInteractOutside(false)
    }

    const onChangeCondition = (condition: IOperator) => {
        if (condition !== filter.operator) {
            const newFilter = { ...filter }
            if (condition == IOperator.between_dates || filter.operator == IOperator.between_dates) { // reset value because the type of value is different (string or number)
                newFilter.value = undefined
            }
            newFilter.operator = condition
            changeFilter(index, newFilter)
        }
    }

    const handleInteractOutside = () => {
        setInteractOutside(prevState => !prevState);
    };

    return (
        <Card className="flex items-stretch relative">
            {hasChanged && <div className="rounded-full bg-orange-400 w-3 h-3 absolute -top-1 -right-1"></div>}
            <Popover open={isValuePopoverOpen} onOpenChange={setIsValuePopoverOpen}>
                <PopoverTrigger className={cn("flex items-center px-1 gap-1 hover:bg-slate-100 py-2 flex-wrap", isRemovable ? "rounded-tl-md rounded-bl-md pl-2" : "rounded-md px-2")}>
                    <FilterTitle title={filter.title} icon={filter.icon} />
                    {(!isPermanent || filter.value != undefined) &&
                        <p className="text-sm text-gray-500 text-nowrap">{filter.operator && conditionAttributes[filter.operator].name}</p>
                    }
                    {filter.value == undefined && !isPermanent &&
                        <p className="text-sm text-gray-500">Select value</p>
                    }
                    {filter.value != undefined &&
                        <>
                            {filter.data_type == 'string[]' && <SearchFilterValuePreviewArrayString value={filter.value as string[]} />}
                            {filter.data_type == 'number' && filter.operator != IOperator.between_dates && < SearchFilterValuePreviewNumber value={filter.value as number} />}
                            {filter.data_type == 'number' && filter.operator == IOperator.between_dates && < SearchFilterValuePreviewBetweenDates value={filter.value as string} />}
                            {filter.data_type == 'boolean' && <SearchFilterValuePreviewBoolean value={filter.value as boolean} />}
                            {filter.data_type == 'options' && <SearchFilterValuePreviewSelect value={filter.value as string} availableValues={filter.availableValues as MultiSelectValues} />}
                            {filter.data_type == 'options[]' && <SearchFilterValuePreviewMultiSelect value={filter.value as string[]} availableValues={filter.availableValues as MultiSelectValues} />}
                            {filter.data_type == 'technologies[]' && <SearchFilterValuePreviewTechnologies value={filter.value as string[]} />}
                            {filter.data_type == 'company-list[]' && <SearchFilterValuePreviewCompanyList value={filter.value as number[]} />}
                            {filter.data_type == 'industry[]' && <SearchFilterValuePreviewIndustry value={filter.value as number[]} />}
                            {filter.data_type == 'technology-category[]' && <SearchFilterValuePreviewTechnologyCategories value={filter.value as string[]} />}
                            {filter.data_type == 'number[]' && <SearchFilterValuePreviewSelectNumber value={filter.value as number} availableValues={filter.availableValues as MultiSelectValues} />}
                        </>
                    }
                    {isPermanent &&
                        <div className="flex items-center justify-center">
                            <ChevronDown className="h-4 w-4" strokeWidth={1.5} />
                        </div>
                    }
                </PopoverTrigger>
                <PopoverContent className={`space-y-4 ${isBigPopOver ? 'w-[580px]' : 'w-72 md:w-96'}`} sideOffset={4} align="start" onInteractOutside={handleInteractOutside}>
                    <div className="flex flex-row justify-between items-start">
                        <div className="flex flex-row items-center gap-2 flex-wrap">
                            <FilterTitle title={filter.title} icon={filter.icon} />
                            {filter.availableOperators.length > 1 &&
                                <Select defaultValue={filter.operator} onValueChange={onChangeCondition}  >
                                    <SelectTrigger className="p-1 h-6 text-sm gap-1 w-auto" tabIndex={-1}>
                                        <SelectValue placeholder="Select an option" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {filter.availableOperators.map((operator) => (
                                                <SelectItem key={operator} value={operator} disabled={usedOperators.includes(operator)}>{conditionAttributes[operator].name}  </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            }
                            {filter.availableOperators.length == 1 &&
                                <p className="text-sm text-gray-500 text-nowrap">{filter.operator && conditionAttributes[filter.operator].name}</p>
                            }
                        </div>
                        <div className="h-6 flex items-center justify-center">
                            <PopoverClose className="ml-auto hover:text-black"><Cross2Icon /></PopoverClose>
                        </div>
                    </div>
                    {filter.data_type == 'string[]' &&
                        <SearchFilterFormArrayString
                            id={filter.id}
                            value={filter.value as string[]}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'number' && filter.operator != IOperator.between_dates &&
                        <SearchFilterFormNumber
                            id={filter.id}
                            value={filter.value as number}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'number' && filter.operator == IOperator.between_dates &&
                        <SearchFilterFormBetweenDates
                            id={filter.id}
                            value={filter.value as string}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'boolean' &&
                        <SearchFilterFormBoolean
                            id={filter.id}
                            value={filter.value as boolean}
                            onChangeValue={onChangeValue} />}
                    {filter.data_type == 'options' &&
                        <SearchFilterFormSelect
                            value={filter.value as string}
                            onChangeValue={onChangeValue}
                            availableValues={filter.availableValues as MultiSelectValues} />}
                    {filter.data_type == 'options[]' &&
                        <SearchFilterFormMultiSelect
                            value={filter.value as string[]}
                            onChangeValue={onChangeValue}
                            availableValues={filter.availableValues as MultiSelectValues}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'technologies[]' &&
                        <SearchFilterFormTechnologies
                            id={filter.id}
                            value={filter.value as string[]}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'company-list[]' &&
                        <SearchFilterFormCompanyList
                            value={filter.value as number[]}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'industry[]' &&
                        <SearchFilterFormIndustry
                            value={filter.value as number[]}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'technology-category[]' &&
                        <SearchFilterFormTechnologyCategories
                            value={filter.value as string[]}
                            onChangeValue={onChangeValue}
                            onInteractOutside={interactOutside}
                            closePopover={closePopover} />}
                    {filter.data_type == 'number[]' &&
                        <SearchFilterFormSelectNumber
                            value={filter.value as string}
                            onChangeValue={onChangeValue}
                            availableValues={filter.availableValues as MultiSelectValues} />}
                </PopoverContent>
            </Popover>
            {isRemovable && !isPermanent &&
                <div className="flex items-center justify-center hover:bg-slate-100 cursor-pointer px-1 pr-1  rounded-tr-md rounded-br-md text-slate-500 hover:text-red-500" onClick={() => removeFilter(index)}>
                    <X className="h-4 w-4" strokeWidth={1.5} />
                </div>
            }
        </Card >
    )
}

