import { DownloadIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/base/dropdown-menu";
import { Table } from "@tanstack/react-table";
import { Job, jobsSchema } from "@/schemas/entities/job.schema";
import { Button } from "@/shared/ui/base/button";
import { WithTooltip } from "../../../../shared/ui/base/tooltip";
import { CSVLink } from 'react-csv';
import { toStringAndSkipQuotationMarks } from "@/shared/utils/string-utils";
import { EVENT_EXPORT_CLICK, sendEvent } from "@/services/tracking.service";
import { download_excel } from "@/lib/excel-utils";
import { useCompanyAction } from "@/components/hooks/data-table/use-company-action";
import { companySchema } from "@/shared/schemas/entity/company.schema";
import { z } from "zod";
import { formatNumberWithComma } from "@/shared/utils/number-utils";
import { EXCEL_LIB_MAX_CHAR_PER_LINE } from "../../companies/components/action-export-companies";


export default function ExportJobsButton({ isDataFetching, table }: { isDataFetching: boolean, table: Table<Job> }) {
    const { isActionDisabled, disableReason } = useCompanyAction(isDataFetching, table, (rows) => rows.map(row => companySchema.parse(row.company_object)));
    const date = new Date().toISOString().slice(0, 10);


    const getJobs = () => {
        if (table.options?.meta?.getBulkSelectionData !== undefined && table.options?.meta?.getBulkSelectionData().length > 0) {
            return z.array(jobsSchema).parse(table.options?.meta?.getBulkSelectionData())
        }
        return z.array(jobsSchema).parse(table.getSelectedRowModel().rows.map(row => row.original))
    }

    const getData = () => {
        const jobs = getJobs();
        const csvLines: string[][] = [[
            'job_title',
            'url',
            'posted_date',
            'job_country_code',
            'is_remote',
            'job_location',
            'job_description',
            'salary',
            'hiring_manager_full_name',
            'hiring_manager_first_name',
            'hiring_manager_role',
            'hiring_manager_linkedin_url',
            'company_name',
            'company_url',
            'company_linkedin_url',
            'company_industry',
            'company_employee_count',
            'company_revenue_usd',
            'company_seo_description',
            'company_description',
        ]];

        jobs.forEach((job) => {
            csvLines.push([
                toStringAndSkipQuotationMarks(job.job_title),
                toStringAndSkipQuotationMarks(job.url),
                toStringAndSkipQuotationMarks(job.date_posted),
                toStringAndSkipQuotationMarks(job.country_code),
                job.remote ? "true" : "false",
                toStringAndSkipQuotationMarks(job.location),
                toStringAndSkipQuotationMarks(job.description),
                toStringAndSkipQuotationMarks(job.salary_string),
                toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.full_name),
                toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.first_name),
                toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.role),
                toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.linkedin_url),
                toStringAndSkipQuotationMarks(job.company),
                toStringAndSkipQuotationMarks(job.company_object?.url),
                toStringAndSkipQuotationMarks(job.company_object?.linkedin_url),
                toStringAndSkipQuotationMarks(job.company_object?.industry),
                toStringAndSkipQuotationMarks(job.company_object?.employee_count?.toString()),
                toStringAndSkipQuotationMarks(job.company_object?.annual_revenue_usd?.toString()),
                toStringAndSkipQuotationMarks(job.company_object?.seo_description),
                toStringAndSkipQuotationMarks(job.company_object?.long_description),
            ]);
        });

        csvLines.forEach(line => {
            line.forEach((value, index) => {
                if (value.length > EXCEL_LIB_MAX_CHAR_PER_LINE) {
                    line[index] = value.slice(0, EXCEL_LIB_MAX_CHAR_PER_LINE);
                }
            });
        });

        return csvLines
    };

    const exportXLSX = () => {
        sendEvent(EVENT_EXPORT_CLICK, { format: 'xlsx', type: 'jobs' })
        download_excel(getData(), 'jobs', [1, 13, 14]);
    }

    const data = getData();

    const button = <Button variant="ghost" size="sm" className="text-sm font-normal" disabled={isActionDisabled}><DownloadIcon className="h-4 w-4 md:mr-2" /><p className="hidden lg:inline">Export</p></Button>

    return (
        <>
            {isActionDisabled &&
                <WithTooltip title="Export" message={disableReason}> {button} </WithTooltip>
            }
            {!isActionDisabled &&
                <DropdownMenu>
                    <WithTooltip title="Export">
                        <DropdownMenuTrigger asChild>
                            {button}
                        </DropdownMenuTrigger>
                    </WithTooltip>
                    <DropdownMenuContent align="start" >
                        <DropdownMenuLabel>Export ({formatNumberWithComma(getJobs().length)})</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                            <CSVLink
                                data={data}
                                filename={`job_posting_${date}.csv`}
                                onClick={() => { sendEvent(EVENT_EXPORT_CLICK, { format: 'csv', type: 'jobs' }) }}
                            >
                                .CSV (comma separated values)
                            </CSVLink>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => exportXLSX()}>
                            .XLSX (Excel)
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            }
        </>
    )
}