import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/base/alert-dialog"

export function ConfirmRevealCompanyDialog({ n_companies, confirmAction, children, cancelAction = () => { }, defaultOpen = false }: { n_companies: number, confirmAction: () => void, cancelAction?: () => void, children?: React.ReactNode, defaultOpen?: boolean }) {
    return (
        <AlertDialog defaultOpen={defaultOpen}>
            {children &&
                <AlertDialogTrigger asChild>
                    {children}
                </AlertDialogTrigger>
            }
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{n_companies == 1 ? 'Do you want reveal this company?' : `Do you want reveal ${n_companies} companies?`}</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action will consume {n_companies} credit{n_companies > 1 && `s`} from your account.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={cancelAction}>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={confirmAction}>Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
