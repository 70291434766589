import { z } from "zod"

export const billingPriceSchema = z.object({
    id: z.string(),
    type: z.enum(["one_time", "recurring"]),
    billing_schema: z.enum(["per_unit", "tiered"]),
    unit_amount: z.number(),
    currency: z.enum(["eur", "usd"]),
    recurring_interval: z.enum(["day", "week", "month", "year"]).optional().nullable(),
    metadata: z.object({
        credits: z.string().optional(),
    }),
})

export type BillingPrice = z.infer<typeof billingPriceSchema>