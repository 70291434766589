export const SIGNUP_PATH = "/signup";
export const HOME_PATH = "/home";
export const SEARCH_PATH = "/search";

export const JOB_SEARCH_PATH = SEARCH_PATH + "/jobs";
export const JOB_SEARCH_NEW_PATH = JOB_SEARCH_PATH + "/new";
export const JOB_SEARCH_ID_PATH = JOB_SEARCH_PATH + "/:searchId";

export const TECH_SEARCH_PATH = SEARCH_PATH + "/tech";
export const TECH_SEARCH_NEW_PATH = TECH_SEARCH_PATH + "/new";
export const TECH_SEARCH_ID_PATH = TECH_SEARCH_PATH + "/:searchId";

export const COMPANY_SEARCH_PATH = SEARCH_PATH + "/companies";
export const COMPANY_SEARCH_NEW_PATH = COMPANY_SEARCH_PATH + "/new";
export const COMPANY_SEARCH_ID_PATH = COMPANY_SEARCH_PATH + "/:searchId";

export const COMPANY_LIST_PATH = "/company-list";
export const COMPANY_LIST_ID_PATH = "/company-list/:listId";
