
import { DATES_SEPARATOR, GTE, LTE } from "@/shared/utils/date-utils"
import { format } from "date-fns"
import { IFiltersProps, IOperator, conditionAttributes } from "../../../shared/schemas/entity/filter.schema"
import { isFieldNotEmpty } from "@/lib/object-utils"
import { BaseSearchParams, OrderBy } from "@/schemas/request/base-search-params.schema"


export function paramsToFilters(searchParams: any, all_filters: IFiltersProps[]): IFiltersProps[] {
    const filters: IFiltersProps[] = []
    for (const filter of all_filters) {
        const isNestedField = filter.id.includes('.')
        const filterId = isNestedField ? filter.id.split('.')[1] : filter.id
        const parentId = isNestedField ? filter.id.split('.')[0] : ''

        for (const condition of filter.availableOperators) {
            const { prefix, suffix } = conditionAttributes[condition]
            const field = prefix + filterId + suffix
            if (isFieldNotEmpty(field, isNestedField ? searchParams[parentId] : searchParams)) {
                const newFilter = { ...filter }
                newFilter.value = isNestedField ? searchParams[parentId][field] : searchParams[field]
                newFilter.operator = condition as IOperator
                newFilter.hasChanged = false
                filters.push(newFilter)
                continue;
            }
            if (condition == IOperator.between_dates) {
                const sinceField = filterId + GTE
                const untilField = filterId + LTE
                if (isFieldNotEmpty(sinceField, isNestedField ? searchParams[parentId] : searchParams) && isFieldNotEmpty(untilField, isNestedField ? searchParams[parentId] : searchParams)) {
                    const newFilter = { ...filter }
                    newFilter.value = isNestedField ? searchParams[parentId][sinceField] + DATES_SEPARATOR + searchParams[parentId][untilField] : searchParams[sinceField] + DATES_SEPARATOR + searchParams[untilField]
                    newFilter.operator = condition as IOperator
                    newFilter.hasChanged = false
                    filters.push(newFilter)
                    continue;
                }
            }
        }
    }
    return filters
}

export function filtersToParams<TSearchParms extends BaseSearchParams>(filters: IFiltersProps[], order_by: OrderBy[] | undefined | null): TSearchParms {
    const searchParam = {} as any;
    searchParam['include_total_results'] = false
    searchParam['order_by'] = order_by
    for (const filter of filters) {

        if (filter.value == undefined || filter.operator == undefined) {
            continue;
        }

        if (filter.operator == IOperator.between_dates) {
            SetDateRangeFields(filter, searchParam)
            continue;
        }

        if (!filter.id.includes('.')) {
            const field = conditionAttributes[filter.operator].prefix + filter.id + conditionAttributes[filter.operator].suffix
            searchParam[field] = filter.value
            continue;
        }

        const keys = filter.id.split('.')
        if (!searchParam[keys[0]]) searchParam[keys[0]] = {}
        searchParam[keys[0]][conditionAttributes[filter.operator].prefix + keys[1] + conditionAttributes[filter.operator].suffix] = filter.value
    }

    return searchParam
}

function SetDateRangeFields(filter: IFiltersProps, searchParam: any) {
    const value = (filter.value as string)
    if (value != '' && value != null && value != undefined && typeof value == 'string' && value.split(DATES_SEPARATOR).length == 2) {
        if (!filter.id.includes('.')) {
            searchParam[filter.id + GTE] = format(new Date(value.split(DATES_SEPARATOR)[0]), 'yyyy-MM-dd')
            searchParam[filter.id + LTE] = format(new Date(value.split(DATES_SEPARATOR)[1]), 'yyyy-MM-dd')
        } else {
            const keys = filter.id.split('.')
            if (!searchParam[keys[0]]) searchParam[keys[0]] = {}
            searchParam[keys[0]][keys[1] + GTE] = format(new Date(value.split(DATES_SEPARATOR)[0]), 'yyyy-MM-dd')
            searchParam[keys[0]][keys[1] + LTE] = format(new Date(value.split(DATES_SEPARATOR)[1]), 'yyyy-MM-dd')
        }
    }
}