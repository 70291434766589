import { useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { useErrorNotification } from "../toast/use-error-notification";

import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema";
import { getJobsFromSearchParams } from "@/services/job.service";
import { Job } from "@/schemas/entities/job.schema";

export function useRevealJob({ job, updateRow, showUpgradeDialog, postRevealAction }: { job: Job, updateRow: (job: Job) => void, showUpgradeDialog: () => void, postRevealAction?: () => void }) {
    const [isDataFetching, setIsDataFetching] = useState(false)
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })


    const revealJob = () => {
        if (!updateRow) return
        setIsDataFetching(true)
        const filters: JobsSearchParams = { page: 0, limit: 1, include_total_results: false, job_id_or: [job.id], blur_company_data: false, posted_at_gte: job.date_posted, posted_at_lte: job.date_posted }
        getJobsFromSearchParams(filters, { pageIndex: 0, pageSize: 1, }, false).then((res) => {
            if (res.metadata.truncated_results > 0) {
                showUpgradeDialog()
            }
            if (Array.isArray(res.data) && res.data.length > 0) {
                const unblurred_job = res.data[0]
                unblurred_job.matching_phrases = job.matching_phrases
                unblurred_job.matching_words = job.matching_words
                updateRow(unblurred_job)
                if (postRevealAction) postRevealAction()
            }

            setIsDataFetching(false)
            queryClient.invalidateQueries({ queryKey: ['me'] })
        }).catch(() => {
            setIsDataFetching(false)
            showErrorNotification()
        })
    }

    return { isDataFetching, showUpgradeDialog, revealJob };

}