import { Column, Table } from "@tanstack/react-table"

import { cn } from "@/lib/utils"
import { useQueryClient } from "@tanstack/react-query"
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification"
import { useRef, useState } from "react"
import { WithTooltip } from "../../../shared/ui/base/tooltip"
import { isChecked } from "./columns/column-utils"
import { Job } from "@/schemas/entities/job.schema"
import { ConfirmRevealCompanyDialog } from "../dialog/confirm-reveal-company-dialog"
import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema"
import { getJobsFromSearchParams } from "@/services/job.service"
import OutOfCreditsDialog, { OutOfCreditsDialogHandle } from "../dialog/out-of-credits-dialog"
import RevealButton from "../reveal-button"

interface DataTableColumnHeaderProps<TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<Job, TValue>,
    table: Table<Job>
}

export function DataTableRevealJobColumnHeader<TValue>({
    table,
    className,
}: DataTableColumnHeaderProps<TValue>) {
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    const [showConfirmRevealDialog, setShowConfirmRevealDialog] = useState(false)
    const [isDataFetching, setIsDataFetching] = useState(false)


    const are_all_company_visible = table.getRowModel().rows.map(row => row.original).every(job => job.has_blurred_data === false)
    const selected_jobs = table.getSelectedRowModel().rows.length > 0 ? table.getSelectedRowModel().rows.map(row => row.original) : table.getRowModel().rows.map(row => row.original)
    const jobs_to_reveal = selected_jobs.filter(job => job.has_blurred_data === true)

    const handleRevalAllCompanies = () => {
        const blurred_companies_id = jobs_to_reveal.map(company => company.id)
        if (blurred_companies_id.length > 50) {
            setShowConfirmRevealDialog(true)
        } else {
            revealAllJobs()
        }
    }

    const handleConfirmationRevealAllCompanies = () => {
        revealAllJobs()
        setShowConfirmRevealDialog(false)
    }


    const revealAllJobs = () => {
        setIsDataFetching(true)
        const rows = table.getRowModel().rows
        if (!jobs_to_reveal || jobs_to_reveal.length === 0) return
        const blurred_job_ids = jobs_to_reveal.map(company => company.id)
        const min_date_posted = jobs_to_reveal.reduce((min_date: string, job) => {
            if (min_date === null || new Date(job.date_posted) < new Date(min_date)) return job.date_posted
            return min_date
        }, null as any)
        const max_date_posted = jobs_to_reveal.reduce((max_date: string, job) => {
            if (max_date === null || new Date(job.date_posted) > new Date(max_date)) return job.date_posted
            return max_date
        }, null as any)
        const filters: JobsSearchParams = { job_id_or: blurred_job_ids, blur_company_data: false, posted_at_gte: min_date_posted, posted_at_lte: max_date_posted }
        getJobsFromSearchParams(filters, { pageIndex: 0, pageSize: 500, }, false).then((res) => {
            if (res.metadata.truncated_companies > 0) outOfCreditsDialogRef.current?.open()
            if (Array.isArray(res.data) && res.data.length > 0) {
                rows.map((row) => {
                    const jobs_same_id = res.data.filter(job => job.id === row.original.id)
                    if (jobs_same_id.length > 0) {
                        const new_job = jobs_same_id[0]
                        new_job.matching_phrases = row.original.matching_phrases
                        new_job.matching_words = row.original.matching_words
                        if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, new_job)
                    }
                })
            }
            queryClient.invalidateQueries({ queryKey: ['me'] })
            setIsDataFetching(false)
        }, () => {
            queryClient.invalidateQueries({ queryKey: ['me'] })
            setIsDataFetching(false)
            showErrorNotification()
        })
    }

    return (
        <>
            {!are_all_company_visible &&
                <div className={cn("flex items-center", className)}>
                    <WithTooltip title={`Reveal ${jobs_to_reveal.length} jobs`} message={`Reveal ${jobs_to_reveal.length} companies in return for ${jobs_to_reveal.length} credits`}>
                        <RevealButton
                            disabled={jobs_to_reveal.length == 0}
                            isDataFetching={isDataFetching}
                            revealCompany={handleRevalAllCompanies}
                            variant={isChecked(table) && jobs_to_reveal.length > 0 ? 'default' : 'outline'} />
                    </WithTooltip>
                    <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
                    {showConfirmRevealDialog && <ConfirmRevealCompanyDialog n_companies={jobs_to_reveal.length} confirmAction={handleConfirmationRevealAllCompanies} cancelAction={() => setShowConfirmRevealDialog(false)} defaultOpen={true} />}
                </div>
            }
        </>
    )
}