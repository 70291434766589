import { SheetContent, SheetTrigger, Sheet, SheetTitle } from "@/components/ui/base/sheet";
import { Company } from "@/shared/schemas/entity/company.schema";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../shared/ui/base/tabs";
import TechnologiesTab from "./components/technologies_tab";
import ModalHeader from "./components/modal_header";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import OverviewTab, { getCompanyJobPostingLink } from "./components/overview_tab";
import { formatNumberWithComma } from "@/shared/utils/number-utils";
import { ExternalLink } from "lucide-react";

type Tabs = "overview" | "technologies" | "jobs"

export interface CompanyModalHandle {
    open: () => void;
    close: () => void;
}
interface CompanyModalProps {
    company: Company | undefined;
    children?: React.ReactNode;
    default_tab?: Tabs;
    default_open?: boolean;
}

const CompanyModal = forwardRef<CompanyModalHandle, CompanyModalProps>(({ company, children, default_tab = "overview", default_open = false }: CompanyModalProps, ref) => {
    const [isOpen, setIsOpen] = useState(default_open)

    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }));

    return (
        <Sheet open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
            <SheetTrigger asChild>
                {children}
            </SheetTrigger>
            <SheetContent className="w-full md:w-4/5 h-full overflow-hidden flex flex-col flex-grow gap-4 p-4" aria-describedby={undefined}>
                <VisuallyHidden.Root asChild><SheetTitle>Mobile Nav Bar</SheetTitle></VisuallyHidden.Root>
                {company &&
                    <>
                        <ModalHeader company={company} />
                        <Tabs defaultValue={default_tab} className="flex-grow overflow-hidden flex flex-col gap-2 items-start" >
                            <TabsList className="grid-flow-col gap-2 auto-cols-max items-center" >
                                <TabsTrigger className="lg:px-10" value="overview">Overview</TabsTrigger>
                                <TabsTrigger className="lg:px-10" value="technologies">Technologies<span className="hidden md:block ml-0.5">({formatNumberWithComma(company.technology_slugs?.length)})</span></TabsTrigger>
                                <TabsTrigger className="lg:px-10" value="jobs" asChild disabled={true}><a target="_blank" href={getCompanyJobPostingLink(company)}>Jobs<span className="hidden md:block ml-0.5">({formatNumberWithComma(company.num_jobs) || 0})</span> <ExternalLink className="ml-1 h-4 w-4" /></a></TabsTrigger>
                            </TabsList>
                            <TabsContent value="overview">
                                <OverviewTab company={company} />
                            </TabsContent>
                            <TabsContent value="technologies" className="flex flex-col flex-grow gap-4 overflow-hidden w-full">
                                <TechnologiesTab company={company} />
                            </TabsContent>
                        </Tabs>
                    </>
                }
            </SheetContent>
        </Sheet >
    )
});


export default CompanyModal

