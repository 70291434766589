import { DownloadIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/base/dropdown-menu";
import { Table } from "@tanstack/react-table";
import { Button } from "@/shared/ui/base/button";
import { WithTooltip } from "../../../../shared/ui/base/tooltip";
import { CSVLink } from 'react-csv';

import { FullCompany, fullCompanySchema } from "@/schemas/entities/full-company.schema";

import { EVENT_EXPORT_CLICK, sendEvent } from "@/services/tracking.service";
import { download_excel } from "@/lib/excel-utils";
import { toStringAndSkipQuotationMarks } from "@/shared/utils/string-utils";
import { z } from "zod";
import { useCompanyAction } from "@/components/hooks/data-table/use-company-action";
import { formatNumberWithComma } from "@/shared/utils/number-utils";

export const EXCEL_LIB_MAX_CHAR_PER_LINE = 32766;

const toString = (value: string | undefined | null) => value ? `${value}` : "";

export default function ExportCompaniesButton({ isDataFetching, table }: { isDataFetching: boolean, table: Table<FullCompany> }) {
    const { isActionDisabled, disableReason } = useCompanyAction(isDataFetching, table, (rows) => rows);
    const date = new Date().toISOString().slice(0, 10);

    const getCompanies = () => {
        if (table.options?.meta?.getBulkSelectionData !== undefined && table.options?.meta?.getBulkSelectionData().length > 0) {
            return z.array(fullCompanySchema).parse(table.options?.meta?.getBulkSelectionData())
        }
        return z.array(fullCompanySchema).parse(table.getSelectedRowModel().rows.map(row => row.original))
    }
    const companies = getCompanies()

    const getData = () => {
        const tecnhology_found_slugs = [...new Set(companies.flatMap(company => company.technologies_found?.map(tech => tech.technology.slug) || []))]

        const header = [
            'company_name',
            'company_url',
            'company_linkedin_url',
            'company_country',
            'company_country_code',
            'company_industry',
            'company_employee_count',
            'company_founding_year',
            'technologies_count',
            'technologies',
            'jobs_count',
            'jobs_titles',
            'annual_revenue_usd',
            'company_seo_description',
            'company_description',
        ];

        tecnhology_found_slugs.forEach((slug: string) => { header.push(`${slug}_confidence`); header.push(`${slug}_n_jobs`); });
        const csvLines: string[][] = [header]
        companies.forEach((company) => {
            const line = [
                toString(company.name),
                toString(company.url),
                toString(company.linkedin_url),
                toString(company.country),
                toString(company.country_code),
                toString(company.industry),
                toString(company.employee_count?.toString()),
                toString(company.founded_year?.toString()),
                toString(company.technology_slugs?.length.toString()),
                toString(company.technologies_found?.map(tech => tech.technology.name).join(", ")),
                toString(company.num_jobs?.toString()),
                toString(company.jobs_found?.map(job => job.job_title).join('", "'),),
                toString(company.annual_revenue_usd?.toString()),
                toStringAndSkipQuotationMarks(company?.seo_description),
                toStringAndSkipQuotationMarks(company?.long_description),
            ]
            tecnhology_found_slugs.forEach((slug: string) => {
                const tech = company.technologies_found?.find(tech => tech.technology.slug === slug);
                line.push(tech?.confidence?.toString() || "");
                line.push(tech?.jobs?.toString() || "");
            })
            csvLines.push(line);
        });

        csvLines.forEach(line => {
            line.forEach((value, index) => {
                if (value.length > EXCEL_LIB_MAX_CHAR_PER_LINE) {
                    line[index] = value.slice(0, EXCEL_LIB_MAX_CHAR_PER_LINE);
                }
            });
        });

        return csvLines
    };

    const exportXLSX = () => {
        sendEvent(EVENT_EXPORT_CLICK, { format: 'xlsx', type: 'companies' })
        download_excel(getData(), 'companies', [1, 2]);
    }

    const button = <Button className="text-sm font-normal" variant="ghost" size="sm" disabled={isActionDisabled}><DownloadIcon className="h-4 w-4 md:mr-2" /><p className="hidden lg:inline">Export</p></Button>
    return (
        <>
            {isActionDisabled &&
                <WithTooltip title="Export" message={disableReason}> {button} </WithTooltip>
            }
            {!isActionDisabled &&
                <DropdownMenu>
                    <WithTooltip title="Export" message={disableReason}>
                        <DropdownMenuTrigger asChild>
                            {button}
                        </DropdownMenuTrigger>
                    </WithTooltip>
                    <DropdownMenuContent align="start" >
                        <DropdownMenuLabel>Export ({formatNumberWithComma(companies.length)})</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem disabled={companies.length == 0}>
                            <CSVLink
                                data={getData()}
                                filename={`companies_${date}.csv`}
                                onClick={() => { sendEvent(EVENT_EXPORT_CLICK, { format: 'csv', type: 'companies' }) }}
                            >
                                .CSV (comma separated values)
                            </CSVLink>
                        </DropdownMenuItem>
                        <DropdownMenuItem disabled={companies.length == 0} onClick={() => exportXLSX()}>
                            .XLSX (Excel)
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            }
        </>
    )
}