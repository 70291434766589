import { useSavedSearch } from '@/components/hooks/data-table/use-saved-search';
import { getCompanySearchById } from '@/services/saved-search.service';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataTableWithCompanyToolbar } from './components/data-table';
import NotFoundPage from '@/pages/not-found/not-found.page';
import { CompanySavedSearch } from '@/schemas/entities/saved-search.schema';
import { PageLayout } from '@/components/ui/layout/page_layout';


const SearchCompaniesPage: React.FC = () => {
    const { searchId } = useParams<{ searchId: string }>();
    const { search, setSearch, isFetching } = useSavedSearch<CompanySavedSearch>(searchId, getCompanySearchById)

    useEffect(() => {
        if (search) document.title = search.name + " · Search · TheirStack";
    }, [search]);

    return (
        <PageLayout className='overflow-hidden'>
            <div className='flex flex-col gap-4 h-full flex-grow overflow-hidden'>
                {search &&
                    < DataTableWithCompanyToolbar
                        search={search}
                        setSearch={setSearch}
                    />
                }
                {!search && !isFetching && <NotFoundPage />}
            </div>
        </PageLayout>
    )
};

export default SearchCompaniesPage;

