import { JOB_SEARCH_NEW_PATH } from "@/paths"

import { QueryNewJobSearch } from "@/schemas/entities/query-new-job-search.schema"
import { Company } from "@/shared/schemas/entity/company.schema"
import { CompanyProperties } from "@/components/ui/company/company-modal/components/company-properties"

export const getCompanyJobPostingLink = (company: Company) => {
    const search: QueryNewJobSearch = {
        name: 'Jobs posted by ' + company.name + ' in the last 180 days',
        auto_search: true,
        draft_mode: true,
        query: {
            company_name_or: [company.name],
            order_by: [{
                field: 'date_posted',
                desc: true,
            }],
        },
    }
    return JOB_SEARCH_NEW_PATH + '?query=' + btoa(encodeURIComponent(JSON.stringify(search)))
}

export default function OverviewTab({ company }: { company: Company }) {


    return (
        <>
            <p className="text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 py-4">Summary</p>
            {company.seo_description && <p className="text-sm ">{company.seo_description}</p>}
            <CompanyProperties company={company} />
            {company.long_description &&
                <div className="flex items-start flex-col space-y-1 py-2">
                    <p className="text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 py-4">Company Description</p>
                    <div>{company.long_description.split('\n\n').map((paragraph, index) => (
                        <p className="text-sm" key={index}>{paragraph}</p>
                    ))}</div>
                </div>
            }
        </>)
}