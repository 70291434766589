import React, { useEffect } from "react"

export function decodedQueryParam<TSchema>(query: string, parse: (query: string) => TSchema) {
    const decodedQuery = atob(query)
    const parsedQuery = JSON.parse(decodeURIComponent(decodedQuery))
    return parse(parsedQuery)
}

export default function useUrlQueryParams<TSchema>(query: string | null, parse: (query: string) => TSchema) {
    const [search, setSearch] = React.useState<TSchema>()
    const [isLoading, setIsLoading] = React.useState(true)

    useEffect(() => {
        if (query) {
            setSearch(decodedQueryParam(query, parse))
        }
        setIsLoading(false)
    }, [query])

    return { search, isLoading }
}

