import { LucideIcon } from "lucide-react";

export type IFilterValue = number | string | string[] | boolean | number[] | undefined
export type SelectOption = { id: string, label: string, flag?: string }
export type MultiSelectValues = SelectOption[]

export type FilterGroup = 'Company' | 'Job Posting' | 'Technology'

type FilterDataType =
    'boolean' |
    'number' |
    'string' |
    'string[]' |
    'options[]' |
    'technologies[]' |
    'options' |
    'company-list[]' |
    'industry[]' |
    'technology-category[]' |
    'number[]'

export interface IFiltersProps {
    id: string,
    value?: IFilterValue,
    hasChanged?: boolean,
    availableValues?: MultiSelectValues,
    availableOperators: IOperator[],
    operator?: IOperator,
    areOperatorsAccumulative?: boolean,
    icon: LucideIcon,
    title: string,
    description?: string,
    dependsOnFilterIds?: string[],
    data_type: FilterDataType,
    group: FilterGroup,
    is_permanent?: boolean
}

export enum IOperator {
    is = 'is',

    exists = 'exists',

    max_age_days = 'max_age_days',
    min_age_days = 'min_age_days',
    between_dates = 'between_dates',

    greater = 'greater',
    less = 'less',

    greater_or_null = 'greater_or_null',
    less_or_null = 'less_or_null',

    in = 'in',
    not_in = 'not_in',

    contains_any = 'contains_any',
    contains_any_case_insensitive = 'contains_any_case_insensitive',
    contains_all = 'contains_all',
    not_contains_any = 'not_contains_any',

    contains_any_partial_match = 'contains_any_partial_match',
    not_contains_any_partial_match = 'not_contains_any_partial_match',

    contains_any_regex = 'contains_any_regex',
    contains_all_regex = 'contains_all_regex',
    not_contains_any_regex = 'not_contains_any_regex',
}

interface IConditionAttributes {
    name: string;
    prefix: string;
    suffix: string;
}

export const conditionAttributes: Record<IOperator, IConditionAttributes> = {
    [IOperator.is]: { name: 'is', prefix: '', suffix: '' },

    [IOperator.exists]: { name: 'exists', prefix: '', suffix: '_exists' },

    [IOperator.max_age_days]: { name: 'since N days ago', prefix: '', suffix: '_max_age_days' },
    [IOperator.min_age_days]: { name: 'until N days ago', prefix: '', suffix: '_min_age_days' },

    [IOperator.between_dates]: { name: 'is between', prefix: '', suffix: '' },

    [IOperator.greater]: { name: 'is greater than', prefix: 'min_', suffix: '' },
    [IOperator.less]: { name: 'is less than', prefix: 'max_', suffix: '' },

    [IOperator.greater_or_null]: { name: 'is unknown or greater than', prefix: 'min_', suffix: '_or_null' },
    [IOperator.less_or_null]: { name: 'is unknown or less than', prefix: 'max_', suffix: '_or_null' },

    [IOperator.in]: { name: 'is any of', prefix: '', suffix: '_or' },
    [IOperator.not_in]: { name: 'is not any of', prefix: '', suffix: '_not' },

    [IOperator.contains_any]: { name: 'is any of', prefix: '', suffix: '_or' },
    [IOperator.contains_any_case_insensitive]: { name: 'is any of (case insensitive)', prefix: '', suffix: '_case_insensitive_or' },
    [IOperator.not_contains_any]: { name: 'is not any of', prefix: '', suffix: '_not' },
    [IOperator.contains_all]: { name: 'contains all of', prefix: '', suffix: '_and' },

    [IOperator.contains_any_regex]: { name: 'matches any (regex pattern)', prefix: '', suffix: '_pattern_or' },
    [IOperator.contains_all_regex]: { name: 'matches all (regex pattern)', prefix: '', suffix: '_pattern_and' },
    [IOperator.not_contains_any_regex]: { name: 'not matches (regex pattern)', prefix: '', suffix: '_pattern_not' },

    [IOperator.contains_any_partial_match]: { name: 'matches partially any', prefix: '', suffix: '_partial_match_or' },
    [IOperator.not_contains_any_partial_match]: { name: 'not matches partially any', prefix: '', suffix: '_partial_match_not' },
};


