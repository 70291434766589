import CompanyModal from '@/components/ui/company/company-modal/company-modal';
import { Company } from '@/shared/schemas/entity/company.schema';
import { LinkedInLogoIcon } from '@radix-ui/react-icons';
import { Building2, Globe } from 'lucide-react';
import CompanyLogo from '../../../shared/ui/company/company-logo';
import { WithTooltip } from '../../../shared/ui/base/tooltip';

function CompanyCard({ company }: { company: Company }) {
    return (
        <>
            {company.has_blurred_data &&
                <WithTooltip title="This company is hidden" message='Click on "Reveal" to see it' side='top'>
                    <div className="grid grid-flow-col gap-4 auto-cols-max items-center">
                        <div className="w-10 h-10 rounded-sm bg-slate-200 flex items-center justify-center blur-sm"> <Building2 strokeWidth={1.5} /> </div>
                        <div className="blur-sm">
                            <p className="max-w-56 font-normal underline text-nowrap truncate" translate="no">
                                {company.name}
                            </p>
                            <div className="mt-1 flex space-x-2 text-gray-500">
                                {company?.url && <p ><Globe size={15} /></p>}
                                {company?.linkedin_url && <p ><LinkedInLogoIcon /></p>}
                            </div>
                        </div>
                    </div >
                </WithTooltip>
            }
            {
                !company.has_blurred_data &&
                <div className="grid grid-flow-col gap-4 auto-cols-max items-center">
                    <div className="h-11 w-11 flex-shrink-0">
                        <CompanyLogo logo={company.logo} domain={company.domain} />
                    </div>
                    <div>
                        <CompanyModal company={company} >
                            <p className="max-w-56 text-nowrap font-normal hover:underline cursor-pointer underline truncate" translate="no">{company.name}</p>
                        </CompanyModal>
                        <div className="mt-1 flex space-x-2 text-gray-500">
                            {company?.url && <a href={company.url} target="_blank"><Globe size={15} /></a>}
                            {company?.linkedin_url && <a href={company.linkedin_url} target="_blank"><LinkedInLogoIcon /></a>}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CompanyCard