"use client"

import { useState } from "react";
import { Button } from "@/shared/ui/base/button";
import { BillingProduct } from "@/shared/schemas/entity/billing-product.schema";
import { BillingPrice } from "@/shared/schemas/entity/billing-price.schema";
import { formatMoney, formatNumberWithComma, formatStripeNumber } from "@/shared/utils/number-utils";
import { Badge } from "@/shared/ui/base/badge";
import { Currency } from "@/shared/schemas/response/geolocation.schema";
import HelpCreditTooltip from "@/shared/ui/pricing/help_credit_tooltip";
import { getCreditPrice } from "../app/one-time-pricing";

const API_CREDIT_MESSAGE = "One API Credit is consumed for each record (job or company) returned from our API endpoints."
export const API_CREDIT_CONTENT = <p className="text-xs">{API_CREDIT_MESSAGE}</p>

export default function APIPricing({ product, currency, onBillingPriceSelected }: { product: BillingProduct, currency: Currency, onBillingPriceSelected: (price_id: string) => void }) {
    const prices = product.prices
        .filter(price => price.currency === currency)
        .sort((a, b) => a.unit_amount - b.unit_amount)
    const [selectedPrice, setSelectedPrice] = useState<BillingPrice>(prices[0]);

    return (
        <div className="flex flex-col gap-4">
            <p className="text-sm text-gray-500">
                Our API plans offer direct access to our large scale databases (jobs, technology usage and companies) through a powerful API. <a href="https://api.theirstack.com" target="_blank" className="text-primary underline">Learn more about our API</a>
            </p>
            <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2">
                <div className="md:col-span-3 flex flex-col gap-2">
                    <table className="w-full border-separate border-spacing-y-2">
                        <tbody>
                            {prices.map((item: BillingPrice) => (
                                <tr
                                    key={item.id}
                                    className={` cursor-pointer ${selectedPrice?.id === item.id ? "bg-secondary" : "hover:bg-gray-200"}`}
                                    onClick={() => setSelectedPrice(item)}
                                >
                                    <td className="p-2 rounded-l-md">{formatNumberWithComma(+(item.metadata.credits ?? 1))} records returned</td>
                                    <td className="p-2 text-gray-500 text-sm inline-flex items-center gap-1">
                                        <p>{getCreditPrice(item.unit_amount, item.metadata.credits, item.currency)} per api credit</p>
                                        <HelpCreditTooltip>
                                            {API_CREDIT_CONTENT}
                                        </HelpCreditTooltip>
                                    </td>
                                    <td className="p-2 rounded-r-md">
                                        {formatMoney(formatStripeNumber(item.unit_amount), item.currency)}
                                        <span className="text-xs font-medium text-gray-600 ml-1">{`/month`}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {selectedPrice && (
                    <div className="col-span-1 flex justify-center items-center bg-gray-100 rounded-md p-2">
                        <div className="flex flex-col items-center gap-4">
                            <Badge variant="outline">API plan</Badge>
                            <p className="text-4xl">
                                {formatMoney(formatStripeNumber(selectedPrice.unit_amount), selectedPrice.currency)}
                                <span className="text-xs font-medium text-gray-600 ml-1">{`/month`}</span>
                            </p>
                            <p className="text-lg">{formatNumberWithComma(+(selectedPrice.metadata.credits ?? 1))} records returned</p>
                            <div className="flex items-center gap-1 text-gray-500">
                                <p className="text-sm">{getCreditPrice(selectedPrice.unit_amount, selectedPrice.metadata.credits, selectedPrice.currency)} per api credit</p>
                                <HelpCreditTooltip>
                                    {API_CREDIT_CONTENT}
                                </HelpCreditTooltip>
                            </div>
                            <Button onClick={() => onBillingPriceSelected(selectedPrice.id)}>Subscribe</Button>
                        </div>
                    </div>
                )}
            </div>
            <p className="text-xs text-gray-500">
                {API_CREDIT_MESSAGE}
            </p>
        </div>
    )
}