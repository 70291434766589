import { Braces, Copy, ExternalLink } from "lucide-react";
import { Button } from "@/shared/ui/base/button";
import { WithTooltip } from "../../../../shared/ui/base/tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@/shared/ui/base/popover";


export default function APICurlButton({ curl, className }: { curl: string, className?: string }) {
    return (
        <div className={`${className}`}>
            <Popover >
                <WithTooltip title="Pull this list with our API">
                    <PopoverTrigger asChild>
                        <Button variant="ghost" size="sm" className="text-sm font-normal"><Braces className="h-4 w-4 md:mr-2" /><p className="hidden lg:inline">API</p></Button>
                    </PopoverTrigger>
                </WithTooltip>
                <PopoverContent align="end" className="w-72 md:w-96 grid gap-2">
                    <h4 className="text-lg">Pull this list with our API</h4>
                    <div className="overflow-scroll bg-gray-50 p-2 max-h-[200px]">
                        <p className="text-xs text-gray-500 text-clip" dangerouslySetInnerHTML={{ __html: curl.replace(/\n/g, '<br/>') }}></p>
                    </div>
                    <div className="flex gap-2">
                        <Button size="sm" variant={"outline"} onClick={() => { navigator.clipboard.writeText(curl) }}>
                            <Copy className="mr-2 h-4 w-4" />
                            cURL
                        </Button>
                        <Button size="sm" variant={"outline"} onClick={() => { window.open('https://api.theirstack.com', '_blank') }}>
                            <ExternalLink className="mr-2 h-4 w-4" />
                            API Docs
                        </Button>
                    </div>
                </PopoverContent>
            </Popover >
        </div>
    )
}