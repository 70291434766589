import { useAuthentication } from "@/components/hooks/authentication/use-authentication";
import { decodedQueryParam } from "@/pages/search/jobs/new/hooks/use-url-query-params";
import { COMPANY_SEARCH_NEW_PATH } from "@/paths";
import { queryNewCompanySearchSchema } from "@/schemas/entities/query-new-company-search.schema";
import { Technology } from "@/schemas/entities/technology.schema";
import { getTechnologiesBySlugs } from "@/services/technology.service";
import { EVENT_ONBOARDING_QUESTIONS_SUBMIT, EVENT_ONBOARDING_QUESTIONS_VIEW, sendEvent } from "@/services/tracking.service";
import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { useLocation, useSearchParams } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle } from "../base/dialog";
import TallyForm from "../tally.form";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

export default function OnboardingFormDialog() {
    const [showOnboardingDialog, setShowOnboardingDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { user } = useAuthentication();
    const [technologyInterestedSlug, setTechnologyInterestedSlug] = useState<string[]>([]);
    const { data: technologies = [] } = useQuery<Technology[]>({
        queryKey: ['technologies', 'slug', '0', technologyInterestedSlug],
        queryFn: () => { return getTechnologiesBySlugs(technologyInterestedSlug) },
        staleTime: Infinity,
    });

    useEffect(() => {
        const value = localStorage.getItem('ask-onboarding-questions');
        if (value === 'true') {
            sendEvent(EVENT_ONBOARDING_QUESTIONS_VIEW);
            if (location.pathname === COMPANY_SEARCH_NEW_PATH) {
                const query = decodedQueryParam(searchParams.get('query') || '', queryNewCompanySearchSchema.parse).query;
                if (query.company_technology_slug_or) {
                    setTechnologyInterestedSlug(query.company_technology_slug_or);
                }
            }
            setShowOnboardingDialog(true);
        }
    }, []);

    const onFormSubmitted = () => {
        localStorage.removeItem('ask-onboarding-questions');
        setShowOnboardingDialog(false);
        sendEvent(EVENT_ONBOARDING_QUESTIONS_SUBMIT);
    }

    return (
        <>
            {showOnboardingDialog &&
                (technologyInterestedSlug.length == 0 || technologies.length > 0) && /*Technologies are not loaded yet or no technology is selected*/
                <Dialog open={true} >
                    <DialogContent allowToClose={false} className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]" aria-describedby={undefined}>
                        <VisuallyHidden.Root asChild><DialogTitle>Mobile Nav Bar</DialogTitle></VisuallyHidden.Root>
                        <TallyForm
                            src={`https://tally.so/embed/wzDleZ?hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(user?.email || '')}${technologies.length > 0 ? '&technology_interested=' + encodeURIComponent(technologies[0].name) : ''}`}
                            onFormSubmitted={onFormSubmitted} />
                    </DialogContent>
                </Dialog>
            }
        </>
    );
}
