

import SearchFilterBase from "@/components/ui/search-filter/search-filter-base"
import { RowSelectionState, Table } from "@tanstack/react-table"
import ButtonAddFilter from "../../../../../components/ui/table/data-table-filters-add-button"

import { Button } from "@/shared/ui/base/button"
import { NewTechSavedSearch } from "@/schemas/entities/saved-search.schema"
import { createCompanySearch } from "@/services/saved-search.service"
import { useNavigate } from "react-router-dom"
import FindPeopleButton from "../../../../../components/ui/table/actions/action-find-people"
import { filters } from "../../components/filters"
import useWarnIfUnsavedChanges from "@/components/hooks/use-warn-if-unsaved-changes"
import ExportCompaniesButton from "../../components/action-export-companies"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { FullCompany, fullCompanySchema } from "@/schemas/entities/full-company.schema"
import AddCompanyToList from "@/components/ui/table/actions/action-add-to-company-to-list"
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification"
import { Separator } from "@/shared/ui/base/separator"
import SwitchSearchObjectTab from "@/components/ui/switch-search-object-tab"
import { IFiltersProps } from "@/shared/schemas/entity/filter.schema"
import LongRunningQueryBanner, { LongRunningQueryBannerHandle, useLongRunningCompanyQuery } from "@/components/ui/banners/long-running-query-banner"
import { useRef } from "react"
import { SearchIcon } from "lucide-react"
import APICurlButton from "@/components/ui/table/actions/action-get-api-curl"
import { getCompaniesCURL } from "@/services/company.service"
import { isFilterPermanent } from "@/components/ui/search-filter/filter-utils"

interface DataTableToolbarProps {
    title: string,
    table: Table<FullCompany>,
    isDataFetching: boolean,
    wasSearched: boolean,
    isCurrentSearchSearched: boolean,
    searchedSP: CompaniesSearchParams,
    totalResults: number,
    rowSelection: RowSelectionState,
    draft_mode: boolean,
    appliedFilters: IFiltersProps[],
    addFilters: (filters: IFiltersProps[]) => void,
    onChangeFilter: (index: number, filter: IFiltersProps) => void,
    onRemoveFilter: (index: number) => void,
    runSearch: () => void,
}

export function DataTableToolbar({
    isDataFetching,
    wasSearched,
    table,
    searchedSP,
    totalResults,
    addFilters,
    draft_mode,
    appliedFilters,
    runSearch,
    onChangeFilter,
    onRemoveFilter
}: DataTableToolbarProps) {
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const navigate = useNavigate()
    useWarnIfUnsavedChanges(wasSearched && !draft_mode);
    const longRunningQueryBannerRef = useRef<LongRunningQueryBannerHandle>(null);
    useLongRunningCompanyQuery({ longRunningQueryBannerRef, appliedFilters })

    const handleSaveSearch = () => {
        const newSearch: NewTechSavedSearch = {
            body: searchedSP,
            type: 'companies',
            is_alert_active: false,
        }
        createCompanySearch(newSearch).then((search) => {
            navigate('/search/companies/' + search.id)
        }).catch(() => {
            showErrorNotification()
        })
    }

    const isFilterRemovable = (filter: IFiltersProps) => {
        const exist_job_filter = appliedFilters.some(f => f.id.startsWith('job_filters.') && f.id !== 'job_filters.posted_at')
        if (filter.id === 'job_filters.posted_at' && exist_job_filter) {
            return false
        }
        return true
    }

    return (
        <>
            <div className="flex flex-wrap md:flex-nowrap items-center justify-start gap-2">
                <SwitchSearchObjectTab totalCompanies={totalResults} default_tab="companies" company_filters={searchedSP} was_searched={wasSearched} />
                <div className="grow overflow-hidden" />
                <div className="flex flex-grap gap-1 border rounded-md bg-white">
                    <AddCompanyToList isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => fullCompanySchema.parse(row))} />
                    <ExportCompaniesButton isDataFetching={isDataFetching} table={table} />
                    <FindPeopleButton isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => fullCompanySchema.parse(row))} />
                    <APICurlButton className="hidden lg:block" curl={getCompaniesCURL(searchedSP, { pageIndex: 0, pageSize: 10 })} />
                </div>
                {!wasSearched && <Button variant="default" size="sm" onClick={() => runSearch()}> <SearchIcon className="mr-2 h-4 w-4" /> Search</Button>}
            </div >
            <Separator />
            <div className="flex items-start justify-between gap-1">
                <div className="flex flex-wrap items-center gap-2">
                    {appliedFilters.map((filter, index) => (
                        <SearchFilterBase
                            key={index}
                            index={index}
                            filter={filter}
                            changeFilter={onChangeFilter}
                            removeFilter={onRemoveFilter}
                            appliedFilters={appliedFilters}
                            isRemovable={isFilterRemovable(filter)}
                            isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                            hasChanged={filter.hasChanged || false} />
                    ))}
                    <ButtonAddFilter availableFilters={filters} appliedFilters={appliedFilters} addFilters={addFilters} />
                </div>
                {wasSearched && <Button variant="secondary" size="sm" onClick={() => handleSaveSearch()} className="bg-orange-300 hover:bg-orange-200">Save</Button>}
            </div>
            <LongRunningQueryBanner ref={longRunningQueryBannerRef} />
        </>
    )
}
