import { z } from "zod"

export const searchTypeSchema = z.enum(['jobs', 'companies', 'technologies'])

export type SearchType = z.infer<typeof searchTypeSchema>

export const recentSearchSchema = z.object({
    id: z.number(),
    name: z.string(),
    body: z.any(),
    type: searchTypeSchema,
    start_datetime: z.string(),
})

export type RecentSearch = z.infer<typeof recentSearchSchema>
