import React, { useEffect, useState } from 'react';


import { DataTableWithCompanyToolbar } from './components/data-table';
import useUrlQueryParams from '../../jobs/new/hooks/use-url-query-params';
import { useSearchParams } from 'react-router-dom';
import { QueryNewCompanySearch, queryNewCompanySearchSchema } from '@/schemas/entities/query-new-company-search.schema';
import { PageLayout } from '@/components/ui/layout/page_layout';
import { useQuery } from '@tanstack/react-query';
import { getLocation } from '@/services/geolocation.service';
import { Geolocation } from '@/shared/schemas/response/geolocation.schema';

const default_search: QueryNewCompanySearch = {
    auto_search: false,
    query: {
        page: 1,
        limit: 10,
        order_by: [{ desc: true, field: 'confidence' }, { desc: true, field: 'jobs' }, { desc: true, field: 'num_jobs' }],
    },
}

const NewSearchCompaniesPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState<QueryNewCompanySearch>()
    const { search: searchFromUrl, isLoading: isLoadingSearch } = useUrlQueryParams<QueryNewCompanySearch>(searchParams.get('query'), queryNewCompanySearchSchema.parse,)
    const { data: location, isLoading, isError } = useQuery<Geolocation>({
        queryKey: ['geolocation'],
        queryFn: getLocation,
        staleTime: Infinity,
    });
    useEffect(() => {
        if (isLoadingSearch) return
        setSearch(searchFromUrl ? searchFromUrl : default_search)
        document.title = searchFromUrl && searchFromUrl.name ? searchFromUrl.name + " · New company search · TheirStack" : "New company search · TheirStack"
    }, [searchFromUrl, isLoadingSearch]);

    useEffect(() => {
        if (searchParams.get('query')) return
        if (!search) return
        if (location && !isLoading && !isError && location.country_code && !search.query.company_country_code_or) {
            const new_search = { ...search, query: { ...search.query, company_country_code_or: [location.country_code] } }
            setSearch(new_search)
        }
    }, [location, search])

    return (
        <PageLayout className='overflow-hidden'>
            <div className='flex flex-col gap-4 h-full flex-grow overflow-hidden'>
                {search &&
                    <DataTableWithCompanyToolbar
                        search={search}
                    />
                }
            </div>
        </PageLayout>
    )
};

export default NewSearchCompaniesPage;

