
import { DataTablePagination } from "@/components/ui/table/data-table-pagination"
import { DataTable } from "@/components/ui/table/data-table"
import { DataTableToolbar } from "./data-table-toolbar"
import DataTableInitialState from "@/components/ui/table/data-table-initial-state"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { useColumnsVisibilityBasedOnSearch } from "../../components/use-columns-visibility-based-on-search"
import { QueryNewCompanySearch } from "@/schemas/entities/query-new-company-search.schema"
import { getCompaniesFromSearchParams } from "@/services/company.service"
import { useAutoSearchTable } from "@/components/hooks/data-table/use-auto-search-table"
import OutOfCreditsBanner from "@/components/ui/banners/out-of-credits-banner"
import { FullCompany } from "@/schemas/entities/full-company.schema"
import { companyColumns, hiddenCompanyColumns } from "../../components/columns-company"

interface DataTableProps {
    search: QueryNewCompanySearch
}

export function DataTableWithCompanyToolbar({
    search
}: DataTableProps) {

    const {
        table,
        metadata,
        columnVisibility,
        setColumnVisibility,
        searchedSP,
        isDataFetching,
        totalResults,
        isCurrentSearchSearched,
        rowSelection,
        onChangeFilter,
        onRemoveFilter,
        appliedFilters,
        runSearch,
        addFilters
    } = useAutoSearchTable<CompaniesSearchParams, FullCompany>(search.query, companyColumns, hiddenCompanyColumns, getCompaniesFromSearchParams, search?.auto_search)
    useColumnsVisibilityBasedOnSearch(searchedSP, columnVisibility, setColumnVisibility)

    return (
        <>
            {metadata.truncated_results > 0 &&
                <OutOfCreditsBanner hidden_results={metadata.truncated_results} />
            }
            <DataTableToolbar
                title={search.name || ''}
                wasSearched={isCurrentSearchSearched}
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                table={table}
                draft_mode={search.draft_mode || false}
                totalResults={totalResults}
                rowSelection={rowSelection}
                searchedSP={searchedSP}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                runSearch={runSearch}
            />
            {!isCurrentSearchSearched && <DataTableInitialState search_type="companies" />}
            {isCurrentSearchSearched &&
                <>
                    <DataTable table={table} columns={companyColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            }
        </>
    )
}
