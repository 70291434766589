import * as React from "react";
import {
    CheckIcon
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import debouce from "lodash.debounce";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../shared/ui/base/popover";
import { Dialog } from "../../base/dialog";
import { Button } from "../../../../shared/ui/base/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../../../../shared/ui/base/command";
import { User, UserWithTeam } from "@/schemas/entities/user";
import { getAllActiveUsers, getMyProfile } from "@/services/user.service";
import { useNavigate } from "react-router-dom";
import { impersonate, stopImpersonating } from "@/services/auth/auth.service";
import { useQuery } from "@tanstack/react-query";
import { DAY_IN_MS, HOUR_IN_MS } from "@/shared/utils/date-utils";
import { UserRoundCogIcon } from "lucide-react";
import { useImpersonating } from "@/components/hooks/authentication/use-impersonating";

export default function UserSwitcher() {
    const [searchQuery, setSearchQuery] = React.useState("");
    const { impersonatingEmail } = useImpersonating()
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    });
    const showUserSwitcher = me && (me.is_superuser || impersonatingEmail);
    const { data: users = [], refetch } = useQuery<User[]>({
        queryKey: ['users'],
        queryFn: () => getAllActiveUsers(searchQuery),
        staleTime: DAY_IN_MS,
        enabled: (showUserSwitcher && searchQuery != "") || false,
    });
    const [open, setOpen] = React.useState(false)
    const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false)

    const [selectedEmail, setSelectedEmail] = React.useState<string>()
    const navigate = useNavigate();

    React.useEffect(() => {
        if (impersonatingEmail) {
            setSelectedEmail(impersonatingEmail)
        } else {
            setSelectedEmail(me?.email)
        }

    }, [me, impersonatingEmail, users])

    React.useEffect(() => {
        if (searchQuery !== "") {
            refetch()
        }
    }, [searchQuery])

    const handleChange = (query: string) => {
        setSearchQuery(query);
    };

    const debouncedResults = React.useMemo(() => {
        return debouce(handleChange, 300);
    }, []);

    const setNewSelectedUser = (user: User) => {
        if (!me) return
        setSelectedEmail(user.email)
        if (user.email != me.email) {
            impersonate(user.email, user.token)
        } else {
            stopImpersonating()
        }
        navigate(0)
    }


    return (
        <>
            {showUserSwitcher &&
                <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={open}
                                aria-label="Select a team"
                                className={cn("max-w-[300px] justify-between", impersonatingEmail ? "bg-red-300 hover:bg-red-400" : "")}
                                style={{ overflow: "hidden" }}
                            >
                                {impersonatingEmail && selectedEmail}
                                {!impersonatingEmail && < UserRoundCogIcon className="h-4 w-4" />}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[250px] p-0">
                            <Command shouldFilter={false}>
                                <CommandList>
                                    <CommandInput onValueChange={debouncedResults} placeholder="Search team..." />
                                    <CommandEmpty>No team found.</CommandEmpty>
                                    <CommandGroup heading="My account">
                                        <CommandItem
                                            onSelect={() => {
                                                setNewSelectedUser(me)
                                                setOpen(false)
                                            }}
                                            className="text-sm"
                                        >
                                            {me.email}
                                            <CheckIcon
                                                className={cn(
                                                    "ml-auto h-4 w-4",
                                                    selectedEmail === me.email
                                                        ? "opacity-100"
                                                        : "opacity-0"
                                                )}
                                            />
                                        </CommandItem>
                                    </CommandGroup>
                                    <CommandGroup heading="Other accounts">
                                        {users.map((user) => (
                                            <CommandItem
                                                key={user.email}
                                                onSelect={() => {
                                                    setNewSelectedUser(user)
                                                    setOpen(false)
                                                }}
                                                className="text-sm"
                                            >
                                                {user.email}
                                                <CheckIcon
                                                    className={cn(
                                                        "ml-auto h-4 w-4",
                                                        selectedEmail === user.email
                                                            ? "opacity-100"
                                                            : "opacity-0"
                                                    )}
                                                />
                                            </CommandItem>
                                        ))
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>

                </Dialog>
            }
        </>
    )
}