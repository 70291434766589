

import { ColumnDef } from "@tanstack/react-table"

import { Checkbox } from "@/components/ui/base/checkbox"


import CompanyCard from "@/components/ui/company/company-card"
import { DataTableColumnHeader } from "@/components/ui/table/data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"
import { isChecked } from "@/components/ui/table/columns/column-utils"
import { CompanyFromList } from "@/schemas/entities/companies-from-company-list"
import { format } from "date-fns"
import { formatNumberWithComma } from "@/shared/utils/number-utils"
import CountryCard from "@/components/ui/country/country-card"

export const hiddenCompanyColumns = {}

export const companyColumns: ColumnDef<CompanyFromList>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <Checkbox
                checked={isChecked(table)}
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
                className="translate-y-[2px]"
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px]"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.company_object && <CompanyCard company={row.original.company_object} />}
                </>
            )
        },
    },
    {
        accessorKey: "added_at_last_time",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Added to list at" />
        ),
        cell: ({ row }) => {
            return (
                <p>{format(row.original.added_at, "MMM dd, yyyy")}</p>
            )
        },
        enableHiding: false,
    },

    {
        id: "company_country",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Country" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original.company_object?.country_code &&
                        <CountryCard countryIso={row.original.company_object.country_code} />
                    }
                </>
            )
        },
        enableSorting: false,
    },

    {
        id: "industry",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Industry" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-[300px] text-clip font-normal">
                    {row.original.company_object?.industry}
                </p>
            )
        },
        enableSorting: false,
    },
    {
        id: "employees",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Employees" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-[300px] text-clip font-normal">
                    {row.original.company_object?.employee_count ? formatNumberWithComma(row.original.company_object.employee_count) : ''}
                </p>
            )
        },
        enableSorting: false,
    },

    {
        id: "actions",
        cell: ({ row }) => <DataTableRowActions row={row} />,
    },
]