

import { ColumnDef } from "@tanstack/react-table"

import { Checkbox } from "@/components/ui/base/checkbox"
import {
    Link2Icon
} from "@radix-ui/react-icons"

import { useRevealJob } from "@/components/hooks/job/use-reveal-job"
import { HighlightKeywords } from "@/components/ui/base/highlight-keywords"
import CompanyCard from "@/components/ui/company/company-card"
import CountryCard from "@/components/ui/country/country-card"
import { ConfirmRevealCompanyDialog } from "@/components/ui/dialog/confirm-reveal-company-dialog"
import OutOfCreditsDialog, { OutOfCreditsDialogHandle } from "@/components/ui/dialog/out-of-credits-dialog"
import { DataTableColumnHeader } from "@/components/ui/table/data-table-column-header"
import { DataTableRevealJobColumnHeader } from "@/components/ui/table/data-table-reveal-job-column-header"
import SelectAllCheckbox from "@/components/ui/table/data-table-select-all-checkbox"
import { Job } from "@/schemas/entities/job.schema"
import { formatDateStringToReadableDate, formatDateTimeStringToReadableDate } from "@/shared/utils/date-utils"
import { formatCompactNumber, formatNumberWithComma } from "@/shared/utils/number-utils"
import { Globe } from "lucide-react"
import { useRef } from "react"
import JobModal, { JobModalHandle } from "../../../../components/ui/job/job-modal"
import HiringManagerCard from "./hiring-manager-card"
import ColumnaRevealButton from "./reveal-job-button"

export const hiddenJobColumns = { discovered_at: false, matching_phrases: false, matching_words: false, manager_roles: false }
export const jobsColumns: ColumnDef<Job>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <SelectAllCheckbox table={table} hasBlurredData={(row) => row.original.has_blurred_data || false} />
        ),
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px]"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },

    {
        id: "job_title",
        accessorKey: "job_title",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Job Title" />
        ),
        cell: ({ row, table }) => <JobTitleColumn job={row.original} updateRow={(job: Job) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, job) }} />,
    },
    {
        accessorKey: "url",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Job URL" />
        ),
        cell: ({ row }) => {
            return (
                <div className="min-w-[60px]">
                    {row.original.has_blurred_data && <p className="blur-sm "><Link2Icon /></p>}
                    {!row.original.has_blurred_data &&
                        <a href={`${row.original.url}`} target="_blank"><Link2Icon /></a>
                    }
                </div >
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "reveal",
        accessorKey: "reveal",
        header: ({ column, table }) => (
            <DataTableRevealJobColumnHeader column={column} table={table} />
        ),
        cell: ({ row, table }) => {
            return (
                <ColumnaRevealButton
                    job={row.original}
                    updateRow={(job: Job) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, job) }} />
            )
        },
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "company",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original.company_object && <CompanyCard company={row.original.company_object} />}
                </>
            )
        },
        enableHiding: true,
    },
    {
        accessorKey: "date_posted",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Posted at" />
        ),
        cell: ({ row }) => {
            return (
                <p>{formatDateStringToReadableDate(row.getValue("date_posted"))}</p>
            )
        },
        enableHiding: true,
    },
    {
        accessorKey: "discovered_at",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Discovered at" />
        ),
        cell: ({ row }) => {
            return (
                <p>{formatDateTimeStringToReadableDate(row.getValue("discovered_at"))}</p>
            )
        },
        enableHiding: true,
    },
    {
        accessorKey: "country",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Job Country" />
        ),
        cell: ({ row }) => {
            const n_countries = Array.isArray(row.original.country_codes) ? row.original.country_codes.length : 0
            return (
                <>
                    {
                        n_countries == 1 && row.original.country_codes &&
                        <CountryCard countryIso={row.original.country_codes[0]} />
                    }
                    {
                        n_countries > 1 &&
                        <div className="grid grid-flow-col gap-2 auto-cols-max items-center">
                            <Globe className="w-4 h-4" />
                            <p>{n_countries} countries</p>
                        </div>
                    }
                </>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: "remote",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Remote" />
        ),
        cell: ({ row }) => {
            return row.getValue("remote") ? <p>Yes</p> : <p>No</p>
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: "location",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Job Location" />
        ),
        cell: ({ row }) => {
            const location: string = row.getValue("location")

            if (location === undefined || location === null || location.length === 0) {
                return <p></p>
            }

            if (location?.split(", ").length < 4) {
                return (
                    <div className="min-w-[150px] max-w-[300px]">
                        <p>{location}</p>
                    </div >
                )
            }

            return (
                <div className="min-w-[150px] max-w-[300px]">
                    <p>{location.split(", ").length} locations</p>
                </div >
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: "salary",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Salary" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original.company_object?.has_blurred_data && <p className="min-w-[150px] blur-sm">{row.original.salary_string}</p>}
                    {!row.original.company_object?.has_blurred_data && <p className="min-w-[150px]">{row.original.salary_string}</p>}
                </>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: "hiring_team",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Hiring Manager" />
        ),
        cell: ({ row }) => (
            <HiringManagerCard job={row.original} />
        ),
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: "manager_roles",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Reports To" />
        ),
        cell: ({ row }) => {
            return (
                <div className="w-[400px] text-clip font-normal">
                    {row?.original?.manager_roles?.map((phrase: string, index: number) => (
                        <div key={phrase + index}>
                            <HighlightKeywords text={phrase} keywords={[]} />
                            <br />
                        </div>
                    ))
                    }
                </div >

            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "matching_phrases",
        accessorKey: "matching_phrases",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Matching Phrases" />
        ),
        cell: ({ row }) => {
            return (
                <div className="w-[400px] text-clip font-normal">
                    {row?.original?.matching_phrases?.map((phrase: string, index: number) => (
                        <div key={phrase + index}>
                            <HighlightKeywords text={phrase} keywords={row.original.matching_words ?? []} />
                            <br />
                        </div>
                    ))
                    }
                </div >

            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "matching_words",
        accessorKey: "matching_words",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Matching Words" />
        ),
        cell: ({ row }) => {
            return (
                <div className="w-[100px] text-clip font-normal">
                    {row.original.matching_words?.join(", ")}
                </div>

            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "company_country",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Country" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {(row?.original.company_object?.country_code) &&
                        <CountryCard countryIso={row.original.company_object.country_code} />
                    }
                </>

            )
        },
        enableSorting: false,
        enableHiding: true,
    },

    {
        id: "company_city",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company City" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-56 truncate font-normal">
                    {row.original.company_object?.city}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },

    {
        id: "company_employees",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Employees" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-[300px] text-clip font-normal">
                    {row && row.original.company_object && row?.original.company_object.employee_count ? formatNumberWithComma(row.original.company_object.employee_count) : ''}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "company_industry",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Industry" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-56 truncate font-normal">
                    {row.original.company_object?.industry}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "company_revenue",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company Revenue" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-20 truncate font-normal">
                    {row.original?.company_object?.annual_revenue_usd ? '$' + formatCompactNumber(row.original.company_object.annual_revenue_usd) : ''}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
]


function JobTitleColumn({ job, updateRow }: { job: Job, updateRow: (job: Job) => void }) {
    const jobModalRef = useRef<JobModalHandle>(null);
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    const { revealJob } = useRevealJob({ job, updateRow, postRevealAction: () => jobModalRef?.current?.open(), showUpgradeDialog: () => outOfCreditsDialogRef.current?.open() })
    return (
        <div className="min-w-[250px] max-w-[400px] text-clip font-normal">
            {job.has_blurred_data &&
                <ConfirmRevealCompanyDialog confirmAction={revealJob} n_companies={1}>
                    <p className="max-w-[300px] text-clip font-normal hover:underline cursor-pointer underline">
                        {job.job_title}
                    </p>
                </ConfirmRevealCompanyDialog>
            }
            {!job.has_blurred_data &&
                <p className="max-w-[300px] text-clip font-normal hover:underline cursor-pointer underline" onClick={() => jobModalRef?.current?.open()}>
                    {job.job_title}
                </p>
            }
            <JobModal ref={jobModalRef} job={job} />

            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />

        </div>
    )

}