import { Eye } from "lucide-react";
import { Button } from "../../shared/ui/base/button";
import { Spinner } from "../../shared/ui/base/spinner";

export default function RevealButton({ isDataFetching, revealCompany, disabled = false, variant = "secondary" }: { isDataFetching: boolean, revealCompany: () => void, disabled?: boolean, variant?: "secondary" | "default" | "outline" }) {
    return (
        <Button
            onClick={revealCompany}
            variant={variant}
            size="sm"
            disabled={isDataFetching || disabled}
        >
            {!isDataFetching && <Eye className="h-5 w-5 mr-2" />}
            {isDataFetching && <Spinner className="h-4 w-4 mr-2" />}
            Reveal
        </Button>
    )
}