import { useErrorNotification } from "@/components/hooks/toast/use-error-notification";
import { useQuery } from '@tanstack/react-query';
import { Card } from "@/shared/ui/base/card";
import { DAY_IN_MS, HOUR_IN_MS, timeAgo } from "@/shared/utils/date-utils";
import { Link } from "react-router-dom";
import { Input } from "@/components/ui/base/input";
import { useEffect, useState } from "react";
import { BriefcaseBusiness, Building2 } from "lucide-react";
import { SavedSearch } from "@/schemas/entities/saved-search.schema";
import { getAllSearches } from "@/services/saved-search.service";
import CardActions from "./card-action";
import { titleCase } from "@/shared/utils/string-utils";
import { getMyProfile } from "@/services/user.service";
import { TeamMember } from "@/schemas/entities/team-members.schema";
import { getTeamMembers } from "@/services/team-member.service";
import { UserWithTeam } from "@/schemas/entities/user";
import { getImpersonatingEmail } from "@/services/auth/auth.service";
import UserFilters from "./user-filters";
import { Separator } from "@/shared/ui/base/separator";
import { SearchType } from "@/schemas/entities/recent-search.schema";
import { ScrollWithFadeOut } from "@/components/ui/scroll-fade-out";

export default function SavedSearchCard({ show_filters = true, search_type }: { show_filters?: boolean, search_type?: SearchType }) {
    const { data: searches = [], isLoading, isError } = useQuery<SavedSearch[]>({
        queryKey: ['searches'],
        queryFn: () => getAllSearches(),
    });
    const [searchKeywords, setSearchKeywords] = useState<string>("")
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    });
    const { data: team_members = [] } = useQuery<TeamMember[]>({
        queryKey: ['team-members'],
        queryFn: getTeamMembers,
        staleTime: DAY_IN_MS,
    });
    const filteredSearches = searches
        .filter((saved_search) => search_type ? saved_search.type == search_type : true)
        .filter((saved_search) => selectedUserIds.length > 0 ? selectedUserIds.includes(saved_search.creator.id.toString()) : true)
        .filter((saved_search) => searchKeywords ? saved_search.name.toLowerCase().includes(searchKeywords.toLowerCase()) : true);
    useErrorNotification({ isError })

    useEffect(() => {
        if (me && me.is_superuser == false) {
            setSelectedUserIds([me.id.toString()])
        }
        if (me && me.is_superuser == true) {
            const isImpersonating = getImpersonatingEmail() != null
            if (!isImpersonating) {
                setSelectedUserIds([me.id.toString()])
            }
        }
    }, [me])

    return (
        <div className="flex flex-col items-start space-y-4 w-full h-full relative">
            {show_filters &&
                <div className="flex flex-row w-full space-x-2">
                    <Input placeholder="Filter by name..." onChange={(event) => setSearchKeywords(event.target.value)} className="h-9 max-w-60" />
                    {team_members && team_members.length > 1 &&
                        <UserFilters team_members={team_members} selectedUserIds={selectedUserIds} setSelectedUserIds={setSelectedUserIds} searches={searches} />
                    }
                </div>
            }

            {isLoading &&
                < div className="flex flex-col space-y-2 w-full overflow-y-auto max-h-72">
                    {[...Array(3)].map((_, i) => (
                        <Card key={i} className="w-full flex flex-row p-2 space-x-2 items-center justify-between h-10 bg-gray-200 animate-pulse" />
                    ))
                    }
                </div>
            }
            {!isLoading && filteredSearches.length == 0 &&
                <div className="flex flex-col w-full items-center space-y-4 p-6">
                    <p className="text-base mt-6 text-gray-500">No saved searches yet</p>
                </div>
            }
            {
                !isLoading && searches.length != 0 &&
                <ScrollWithFadeOut className="flex flex-col w-full max-h-72 relative">
                    {filteredSearches.map((saved_search, index) => (
                        <div key={saved_search.id}>
                            <Row saved_search={saved_search} show_filters={show_filters} />
                            {index !== filteredSearches.length - 1 && <Separator className="mx-1" />}
                        </div>
                    ))}
                </ScrollWithFadeOut>
            }
        </div >
    )
}

function Row({ saved_search, show_filters }: { saved_search: SavedSearch, show_filters: boolean }) {
    return (

        <div className="w-full flex flex-row flex-grow p-2 space-x-2 items-center  hover:bg-gray-50 rounded-md">
            <Link
                className="flex-grow"
                to={saved_search.type == 'jobs' ? `/search/jobs/${saved_search.id}` : saved_search.type == 'intent_details' ? `/search/tech/${saved_search.id}` : `/search/companies/${saved_search.id}`}>
                <div className="flex flex-row space-x-2 items-center">
                    <div className="w-8 h-8 rounded-sm bg-slate-200 flex items-center justify-center shrink-0">
                        {saved_search.type == 'jobs' ? <BriefcaseBusiness strokeWidth={1.5} /> : <Building2 strokeWidth={1.5} />}
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm text-ellipsis max-h-5 overflow-hidden"> {saved_search.name}</p>
                        {show_filters &&
                            <p className="text-xs text-gray-400">{saved_search.type == 'jobs' ? 'Jobs' : 'Companies'} · {saved_search.creator.first_name + ' ' + saved_search.creator.last_name} · {timeAgo(saved_search.created_at)} {saved_search.is_alert_active ? ' · ' + titleCase(saved_search.frequency || '') + ' alert ' : ''} </p>
                        }
                        {!show_filters &&
                            <p className="text-xs text-gray-400">{timeAgo(saved_search.created_at)}</p>
                        }
                    </div>
                </div>
            </Link>
            <CardActions saved_search={saved_search} />
        </div >


    );
}
