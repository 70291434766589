import { useRevealCompany } from "@/components/hooks/company/use-reveal-company";
import { WithTooltip } from "@/shared/ui/base/tooltip";
import OutOfCreditsDialog, { OutOfCreditsDialogHandle } from "@/components/ui/dialog/out-of-credits-dialog";
import RevealButton from "@/components/ui/reveal-button";
import { FullCompany } from "@/schemas/entities/full-company.schema";
import { useRef } from "react";


export default function RevealCompanyButton({ company, updateCompany }: { company: FullCompany, updateCompany: (company: FullCompany) => void }) {
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    const { isDataFetching, revealCompany } = useRevealCompany({ company, updateCompany, showUpgradeDialog: () => outOfCreditsDialogRef.current?.open() })
    return (
        <>
            {company.has_blurred_data &&
                <WithTooltip title="Reveal company in return for 1 credit">
                    <RevealButton isDataFetching={isDataFetching} revealCompany={revealCompany} />
                </WithTooltip>
            }
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}