

import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"

import { Button } from "@/shared/ui/base/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem, DropdownMenuTrigger
} from "@/components/ui/base/dropdown-menu"
import { useNavigate, useParams } from "react-router-dom"
import { removeCompanyToCompanyList } from "@/services/company_list.service"
import { CompanyFromList } from "@/schemas/entities/companies-from-company-list"

interface DataTableRowActionsProps {
    row: Row<CompanyFromList>,
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
    const navigate = useNavigate()
    const { listId } = useParams<{ listId: string }>();

    const handleDelete = () => {
        if (!listId) return
        removeCompanyToCompanyList(+listId, [row.original.company_name]).then(() => {
            navigate(0)
        })
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem onClick={handleDelete}>Remove</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}