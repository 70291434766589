
import { isObjectEmpty } from "@/lib/object-utils"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { VisibilityState } from "@tanstack/react-table"
import { useEffect } from "react"

export function useColumnsVisibilityBasedOnSearch(searchedSearchParams: CompaniesSearchParams, columnVisibility: VisibilityState, setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>) {
    useEffect(() => {
        if (isObjectEmpty(searchedSearchParams.job_filters)) {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = false
            setColumnVisibility(newColumnVisibility)
        } else {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = true
            setColumnVisibility(newColumnVisibility)
        }
    }, [searchedSearchParams])


    return {}
}