export function isFieldNotEmpty(field: string, object: any): boolean {
    if (object == null || object == undefined) {
        return false
    }
    if (object[field] == null || object[field] == undefined) {
        return false
    }
    if (typeof object[field] == 'number' || typeof object[field] == 'string' || typeof object[field] == 'boolean') {
        return true
    }
    if (typeof object[field] == 'object' && object[field].length > 0) {
        return true
    }
    return false
}

export function isObjectEmpty(obj: any): boolean {
    if (obj == null || obj == undefined) {
        return true
    }
    return Object.keys(obj).length === 0
}