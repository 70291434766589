


import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema"
import { DataTablePagination } from "@/components/ui/table/data-table-pagination"
import { DataTable } from "@/components/ui/table/data-table"
import { DataTableToolbar } from "./data-table-toolbar"
import { useColumnsVisibilityBasedOnSearch } from "../../components/use-columns-visibility-based-on-search"
import DataTableInitialState from "@/components/ui/table/data-table-initial-state"
import { Job } from "@/schemas/entities/job.schema"
import { useFetchDataAndTotalResultsSeparatedForTable } from "@/components/hooks/data-table/use-fetch-data-and-total-results-for-table"
import OutOfCreditsBanner from "@/components/ui/banners/out-of-credits-banner"
import { QueryNewJobSearch } from "@/schemas/entities/query-new-job-search.schema"
import { getJobsFromSearchParams } from "@/services/job.service"
import { hiddenJobColumns, jobsColumns } from "../../components/columns-job"

interface DataTableProps {
    search: QueryNewJobSearch,
}

export function DataTableWithJobsToolbar({
    search
}: DataTableProps) {
    const {
        table,
        metadata,
        searchedSP: searchedSearchParams,
        wasFirstSearchTriggered,
        totalResults,
        columnVisibility,
        setColumnVisibility,
        dirtySP: dirtySearchParams,
        isDataFetching,
        isCurrentSearchSearched,
        onChangeFilters,
        setDirtySP: setDirtySearchParams,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
    } = useFetchDataAndTotalResultsSeparatedForTable<JobsSearchParams, Job>(search.query, getJobsFromSearchParams, jobsColumns, hiddenJobColumns, search?.auto_search)

    useColumnsVisibilityBasedOnSearch(searchedSearchParams, columnVisibility, setColumnVisibility)

    return (
        <>
            {metadata.truncated_results > 0 &&
                <OutOfCreditsBanner hidden_results={metadata.truncated_results} />
            }
            <DataTableToolbar
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                table={table}
                draft_mode={search.draft_mode || false}
                dirtySearchParams={dirtySearchParams}
                setDirtySearchParams={setDirtySearchParams}
                totalResults={totalResults}
                wasSearched={isCurrentSearchSearched}
                onChangeFilters={onChangeFilters}
                title={search.name || ''}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                metadata={metadata}
            />
            {!wasFirstSearchTriggered && <DataTableInitialState search_type="jobs" />}
            {wasFirstSearchTriggered &&
                <>
                    <DataTable table={table} columns={jobsColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            }
        </>
    )
}