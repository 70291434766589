import { Company } from "@/shared/schemas/entity/company.schema";
import CompanyLogo from "../../../../../shared/ui/company/company-logo";
import { Badge } from "@/shared/ui/base/badge";
import { Link2Icon } from "lucide-react";
import { LinkedInLogoIcon } from "@radix-ui/react-icons";


export default function ModalHeader({ company }: { company: Company }) {
    return (
        <>
            <div className="flex items-center gap-2 flex-wrap">
                <div className="h-11 w-11 flex-shrink-0">
                    <CompanyLogo logo={company.logo} domain={company.domain} />
                </div>
                <div>
                    <h3 className="text-xl font-medium flex" translate="no"> {company.name}</h3>
                </div>
            </div>

            <div className="flex gap-2 flex-wrap">
                {company.url &&
                    <a href={company.url} target="_blank">
                        <Badge variant="outline" className=" hover:bg-gray-100">
                            <Link2Icon size={15} />
                            <p className="pl-1">{company.url}</p>
                        </Badge>
                    </a>
                }
                {company.linkedin_url &&
                    <a href={company.linkedin_url} target="_blank">
                        <Badge variant="outline" className=" hover:bg-gray-100">
                            <LinkedInLogoIcon />
                            <p className="pl-1 max-w-50 truncate">{company.linkedin_url?.replace('https://www.', '').replace('http://www.', '')}</p>
                        </Badge>
                    </a>
                }
            </div>
        </>
    )
}