import { Badge } from "@/shared/ui/base/badge"
import { DataTableColumnHeader } from "@/components/ui/table/data-table-column-header"
import { formatDateStringToReadableDate } from "@/shared/utils/date-utils"
import { QueryNewJobSearch } from "@/schemas/entities/query-new-job-search.schema"
import { TechDetails } from "@/schemas/entities/tech-details.schema"
import { ColumnDef } from "@tanstack/react-table"
import TechnologyLogo from "../../../../../shared/ui/technology/technology-logo"

export const hiddenTechnologyColumns = { category_name: false, job_objects: false, jobs_last_30_days: false, jobs_last_7_days: false, "company.industry": false, "company.employee_count": false, "company.country": false }

export const technologiesColumns: ColumnDef<TechDetails>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Technology" />
        ),
        filterFn: (row, _columnId: string, filterValue) => {
            if (!filterValue) return true
            return row.original.technology.name.toLowerCase().includes(filterValue.toLowerCase())
        },
        cell: ({ row }) => {
            return (
                <div className="grid grid-flow-col gap-2 auto-cols-max items-center">
                    <div className="h-11 w-11 flex-shrink-0">
                        <TechnologyLogo href={row.original.technology.logo} />
                    </div>
                    <div>
                        <p className="text-clip font-normal" translate="no" >
                            {row.original.technology.name}
                        </p>
                        <div className="mt-1 flex space-x-2 text-gray-500 text-xs">
                            {row.original.technology.category}
                        </div>
                    </div>
                </div>
            )
        },
        enableSorting: false,
    },
    {
        accessorKey: "category_name",
        accessorFn: (row) => row.technology?.category,
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Category" />
        ),
        filterFn: (row, _columnId: string, filterValue: string[]) => {
            if (!filterValue) return true
            return filterValue.includes(row.original.technology.category || '')
        },
        cell: ({ row }) => {
            return (
                <p>{row.original.technology.category}</p>
            )
        },
        enableSorting: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: "jobs",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Jobs" />
        ),
        cell: ({ row }) => {
            const search: QueryNewJobSearch = {
                name: 'Jobs using ' + row.original.technology.name + ' posted by ' + row.original.company_name,
                auto_search: true,
                draft_mode: true,
                query: {
                    include_total_results: false,
                    blur_company_data: true,
                    company_name_or: [row.original.company_name],
                    job_technology_slug_or: [row.original.technology.slug],
                    order_by: [{
                        field: 'date_posted',
                        desc: true,
                    }],
                },
            }
            const link = '/search/jobs/new?query=' + btoa(encodeURIComponent(JSON.stringify(search)))
            return (
                <a href={link} target="_blank" className="underline" >{row.original.jobs}</a>
            )
        },
    },
    {
        accessorKey: "confidence",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Confidence" />
        ),
        cell: ({ row }) => {
            const color = row.original.confidence == "high" ? 'text-primary border-primary' : row.original.confidence == "medium" ? 'text-orange-300 border-orange-300' : "text-red-400 border-red-400"
            return (
                <Badge className={`text-xs ${color}`} variant="outline"> {row.original.confidence}</Badge>
            )
        },
    },
    {
        accessorKey: "first_date_found",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="First date found" />
        ),
        cell: ({ row }) => {
            return (
                <p>{formatDateStringToReadableDate(row.original.first_date_found)}</p>
            )
        },
    },
    {
        accessorKey: "last_date_found",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Last date found" />
        ),
        cell: ({ row }) => {
            return (
                <p>{formatDateStringToReadableDate(row.original.last_date_found)}</p>
            )
        },
    },
    {
        accessorKey: "relative_occurrence_within_category",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Category occurence" />
        ),
        cell: ({ row }) => {
            return (
                row.original.relative_occurrence_within_category &&
                <p>{new Intl.NumberFormat(undefined, { style: "percent" }).format(row.original.relative_occurrence_within_category)}</p>
            )
        },
    },

]