
"use client";

import { BillingProduct } from "@/shared/schemas/entity/billing-product.schema";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shared/ui/base/tabs";
import OneTimePricing from "./one-time-pricing";
import RecurrentPricing from "./recurrent-pricing";
import { Currency } from "@/shared/schemas/response/geolocation.schema";


export default function AppPricing({ mode = 'both', products, currency, onBillingPriceSelected }: { mode: 'both' | 'one-time' | 'recurrent', products: BillingProduct[], currency: Currency, onBillingPriceSelected: (price_id: string) => void }) {
    const recurrentProducts = products.filter(product => product.prices.some(price => price.type === "recurring"))
    const oneTimeProducts = products.filter(product => product.prices.some(price => price.type === "one_time"))

    return (
        <div className="flex flex-col gap-4">

            <p className="text-sm text-gray-500">
                Our <a href="https://app.theirstack.com" target="_blank" className="text-primary underline">TheirStack UI</a> plans provide easy access to all features through: export company lists based on technology usage, search for jobs across multiple job boards simultaneously, perform detailed company lookups...
            </p>

            <Tabs defaultValue={mode == 'both' ? 'recurrent' : mode} >
                <div className="flex justify-center">
                    <TabsList className={`grid h-auto p-0 border ${mode == 'both' ? 'grid-cols-2' : 'grid-cols-1'}`}>
                        {mode != 'one-time' &&
                            <TabsTrigger
                                value="recurrent"
                                className="grid grid-flow-row auto-rows-max h-auto data-[state=active]:shadow-none data-[state=active]:border-b-4 data-[state=active]:border-b-primary">
                                <p className="text-lg font-semibold">Subscription</p>
                                <p className="text-xs md:text-sm text-gray-500 text-wrap">New credits every month</p>
                            </TabsTrigger>
                        }
                        {mode != 'recurrent' &&
                            <TabsTrigger
                                value="one-time"
                                className="grid grid-flow-row auto-rows-max h-auto data-[state=active]:shadow-none data-[state=active]:border-b-4 data-[state=active]:border-b-primary">
                                <p className="text-lg font-semibold">Pay as you go</p>
                                <p className="text-xs md:text-sm text-gray-500 text-wrap">One-time purchase, credits lasts 1 year</p>
                            </TabsTrigger>
                        }
                    </TabsList>
                </div>
                <TabsContent value="one-time" className="bg-white rounded-md shadow-sm mt-6">
                    <OneTimePricing product={oneTimeProducts[0]} currency={currency} onBillingPriceSelected={onBillingPriceSelected} />
                </TabsContent>
                <TabsContent value="recurrent" className="bg-white  rounded-md shadow-sm mt-6">
                    <RecurrentPricing products={recurrentProducts} currency={currency} onBillingPriceSelected={onBillingPriceSelected} />
                </TabsContent>
            </Tabs>
        </div>)
}