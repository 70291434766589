import { useRevealJob } from "@/components/hooks/job/use-reveal-job";
import { WithTooltip } from "@/shared/ui/base/tooltip";
import OutOfCreditsDialog, { OutOfCreditsDialogHandle } from "@/components/ui/dialog/out-of-credits-dialog";
import RevealButton from "@/components/ui/reveal-button";
import { Job } from "@/schemas/entities/job.schema";
import { useRef } from "react";


export default function RevealJobButton({ job, updateRow }: { job: Job, updateRow: (job: Job) => void }) {
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    const { isDataFetching, revealJob } = useRevealJob({ job, updateRow, showUpgradeDialog: () => outOfCreditsDialogRef.current?.open() })
    return (
        <>
            {job.has_blurred_data &&
                <WithTooltip title="Reveal company jobs in return for 1 credit">
                    <RevealButton isDataFetching={isDataFetching} revealCompany={revealJob} />
                </WithTooltip>
            }
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}