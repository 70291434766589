import { getSessionId } from "@/services/tracking.service";


export const HTTP_STATUS_CODES = {
    PAYMENT_REQUIRED: 402,
}

export const sourceSessionParamsObject = () => ({
    source: 'app',
    session_id: getSessionId() ? getSessionId() : ''
})


export function constructURL(baseUrl: string, params: Record<string, number | string | boolean | string[] | undefined | null>): string {
    const url = new URL(baseUrl);

    // Loop through the parameters object and append each one to the URL
    for (const [key, value] of Object.entries(params)) {
        if (value != undefined && value != null) {
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    url.searchParams.append(key, String(item));
                });
            } else {
                url.searchParams.append(key, String(value));
            }
        }
    }

    return url.href; // This returns the full URL as a string
}

export function fetchToCurl(url: string, options: RequestInit): string {
    const method = options.method || 'GET';
    const headers = options.headers ? Object.entries(options.headers).map(([key, value]) => `--header "${key}: ${value}"`).join(' \\\n') : '';
    const body = options.body ? `-d '${JSON.stringify(JSON.parse(options.body as string), null, 2)}'` : '';
    return `curl --request ${method} \\\n --url "${url}" \\\n${headers} \\\n${body}`;
}
