import { zodResolver } from "@hookform/resolvers/zod"
import { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/base/form"
import { Input } from "@/components/ui/base/input"
import { Check, Pencil, X } from "lucide-react"
import { Button } from "../../shared/ui/base/button"

import { useOnClickOutside } from "usehooks-ts"
import { WithTooltip } from "../../shared/ui/base/tooltip"
import { cn } from "@/lib/utils"


const FormSchema = z.object({
    title: z.string().min(1, { message: "Title is required" }),
})

function SearchTitleInput({ name, updateName, className }: { name: string, updateName: (name: string) => void, className?: string }) {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            title: name,
        },
    })
    const divRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(divRef, () => { setIsEditing(false); form.reset({ title: name }) })

    function onSubmit() {
        updateName(form.getValues().title)
        setIsEditing(false)
    }

    return (
        <div className={cn(className, "")}>
            {!isEditing &&
                <WithTooltip title="Edit search name">
                    <div className="hover:bg-gray-200 hover:rounded-sm flex items-center justify-start gap-2 h-8 cursor-pointer pl-2" onClick={() => setIsEditing(true)}>
                        <h2 className="text-lg font-medium tracking-tight truncate">{form.getValues().title}</h2>
                        <Pencil className="h-4 w-4 cursor-pointer hover:bg-gray-200" />
                    </div>
                </WithTooltip >
            }
            {
                isEditing &&
                <div ref={divRef} className="flex gap-2">
                    <Form {...form}  >
                        <form className="grow" onSubmit={form.handleSubmit(onSubmit)}>
                            <FormField
                                control={form.control}
                                name="title"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input className=" h-9 focus-visible:ring-1" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </Form>
                    <Button variant="outline" size="sm" onClick={onSubmit}><Check className="h-4 w-4" /></Button>
                    <Button variant="outline" size="sm" onClick={() => setIsEditing(false)}><X className="h-4 w-4" /></Button>
                </div>
            }
        </div>
    )
}

export default SearchTitleInput