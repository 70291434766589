import { z } from "zod"
import { companySchema } from "@/shared/schemas/entity/company.schema"

export const companyFromListSchema = z.object({
    company_name: z.string(),
    added_at: z.coerce.date(),
    added_at_last_time: z.coerce.date(),
    company_object: companySchema.nullable().optional(),
})

export type CompanyFromList = z.infer<typeof companyFromListSchema>
