import { HTTP_STATUS_CODES, fetchToCurl, sourceSessionParamsObject } from "@/lib/http-utils"
import { constructURL } from "@/lib/http-utils"

import { getHTTPHeaders, getHTTPHeadersImpersonating } from "@/lib/authentication/authentication-http-headers"
import { isRevealCompaniesAutomaticallyEnabled } from "@/pages/settings/billing/billing.page"

import { PaginationState } from "@tanstack/react-table"
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema"
import { CompanySearchResponse, companiesSearchResponseSchema } from "@/schemas/responses/company-search-response.schema"
import { SmallCompany } from "@/schemas/entities/small-company.schema"
import { CompanyCountResponse, companyCountResponseSchema } from "@/schemas/responses/company-count-response.schema"

const RESOURCE_URL = '/v1/companies/search'

export function getCompaniesFromSearchParamsBody(params: CompaniesSearchParams, pagination: PaginationState, include_total_results: boolean) {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    newParams.include_total_results = include_total_results
    if (newParams.blur_company_data === undefined) {
        newParams.blur_company_data = isRevealCompaniesAutomaticallyEnabled() ? false : true
    }
    if (Array.isArray(newParams.order_by) && newParams.order_by.length > 0 && newParams.order_by[0].field === 'company_name') {
        newParams.order_by[0].field = 'company.name'
    }
    return newParams;
}

export async function getCompaniesFromSearchParams(params: CompaniesSearchParams, pagination: PaginationState, include_total_results: boolean, signal: AbortSignal): Promise<CompanySearchResponse> {
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}`, sourceSessionParamsObject())
    const options = {
        method: 'POST',
        headers: getHTTPHeaders(),
        body: JSON.stringify(getCompaniesFromSearchParamsBody(params, pagination, include_total_results)),
        signal: signal,
    };
    const res = await fetch(url, options)
    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) throw new Error('Failed to fetch data')
    return companiesSearchResponseSchema.parse(await res.json())
}

export function getCompaniesCURL(params: CompaniesSearchParams, pagination: PaginationState): string {
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL
    const body = getCompaniesFromSearchParamsBody(params, pagination, false)
    body.blur_company_data = false
    const options = {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(body),
    };
    return fetchToCurl(url, options)
}

export async function getCompaniesByName(company_names: string[]): Promise<CompanySearchResponse> {
    const params: CompaniesSearchParams = {
        page: 0,
        limit: 50,
        include_total_results: false,
        blur_company_data: false,
        company_name_or: company_names
    }
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}`, sourceSessionParamsObject())
    const res = await fetch(url, {
        method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(params)
    })

    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) throw new Error('Failed to fetch data')
    return companiesSearchResponseSchema.parse(await res.json())
}

export async function getCompaniesByIds(company_ids: string[]): Promise<CompanySearchResponse> {
    const params: CompaniesSearchParams = {
        page: 0,
        limit: 500,
        include_total_results: false,
        blur_company_data: false,
        company_id_or: company_ids
    }
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}`, sourceSessionParamsObject())
    const res = await fetch(url, {
        method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(params)
    })

    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) throw new Error('Failed to fetch data')
    return companiesSearchResponseSchema.parse(await res.json())
}


export async function autoCompleteByName(name_pattern: string): Promise<SmallCompany[]> {
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v1/companies/autocomplete`, { text: name_pattern, ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeaders() })
    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) throw new Error('Failed to fetch data')
    return (await res.json() as { data: SmallCompany[] }).data;
}

export async function countSearchCompanies(params: CompaniesSearchParams, pagination: PaginationState): Promise<CompanyCountResponse> {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    if (Array.isArray(newParams.order_by) && newParams.order_by.length > 0 && newParams.order_by[0].field === 'company_name') {
        newParams.order_by[0].field = 'company.name'
    }
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}/counts_within_limit`, sourceSessionParamsObject())
    const res = await fetch(url, {
        method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(newParams)
    })
    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) throw new Error('Failed to fetch data')
    return companyCountResponseSchema.parse(await res.json())
}