import { getHTTPHeadersImpersonating } from "@/lib/authentication/authentication-http-headers"
import { CompanyFromList, companyFromListSchema } from "@/schemas/entities/companies-from-company-list"
import { CompanyList, companyListSchema } from "@/schemas/entities/company-list.schema"
import { CompanyFromListSearchParams } from "@/schemas/request/companies-from-list-search-params.schema"
import { PaginationState } from "@tanstack/table-core"
import { z } from "zod"

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/company_lists'

export async function getAllCompanyLists(): Promise<CompanyList[]> {
    const res = await fetch(RESOURCE_URL, { method: 'GET', headers: getHTTPHeadersImpersonating() },)
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(companyListSchema).parse(await res.json())
}

export async function getCompanyList(id: number): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'GET', headers: getHTTPHeadersImpersonating() },)
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}

export async function duplicateCompanyList(id: number): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL + `/${id}/duplicate`, { method: 'POST', headers: getHTTPHeadersImpersonating() },)
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}

export async function getCompanyFromList(searchParams: CompanyFromListSearchParams, pagination: PaginationState): Promise<CompanyFromList[]> {
    const order_by = Array.isArray(searchParams.order_by) ? searchParams.order_by[0] : { field: 'company_name', desc: false }
    let url = RESOURCE_URL + `/${searchParams.list_id}/companies?page=${pagination.pageIndex}&limit=${pagination.pageSize}&order_by=${order_by.field}&order_direction=${order_by.desc ? 'desc' : 'asc'}`
    if (searchParams.company_name_partial) {
        url += `&company_name_partial=${searchParams.company_name_partial}`
    }
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() },)
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(companyFromListSchema).parse(await res.json())
}

export async function deleteCompanyList(id: number): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'DELETE', headers: getHTTPHeadersImpersonating() },)
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status
}

export async function renameList(id: number, name: string): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'PATCH', headers: getHTTPHeadersImpersonating(), body: JSON.stringify({ new_name: name }) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}

export async function createList(name: string): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL, { method: 'POST', headers: getHTTPHeadersImpersonating(), body: JSON.stringify({ name }) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}

export async function addCompanyToCompanyList(companyListId: number, company_names: string[]): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL + `/${companyListId}/add_companies`, { method: 'POST', headers: getHTTPHeadersImpersonating(), body: JSON.stringify({ company_names }) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}

export async function removeCompanyToCompanyList(companyListId: number, company_names: string[]): Promise<CompanyList> {
    const res = await fetch(RESOURCE_URL + `/${companyListId}/remove_companies`, { method: 'POST', headers: getHTTPHeadersImpersonating(), body: JSON.stringify({ company_names }) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companyListSchema.parse(await res.json())
}