
import { getCheckoutSessionUrl } from '@/services/user.service';
import { EVENT_CONTACT_SALES_CLICK, EVENT_SUBSCRIBE_CLICK, sendEvent } from '@/services/tracking.service';
import PricingBox from '@/shared/ui/pricing/pricing-box';
import { BillingProduct } from '@/shared/schemas/entity/billing-product.schema';
import { useQuery } from '@tanstack/react-query';
import { getBillingProducts } from '@/services/billing.service';
import { getLocation } from '@/services/geolocation.service';
import { Geolocation } from '@/shared/schemas/response/geolocation.schema';
import { Button } from '@/shared/ui/base/button';
import { Spinner } from '../../../../shared/ui/base/spinner';

export default function PricingGrid({ mode = 'both', defaultTab = 'app' }: { mode?: 'both' | 'one-time' | 'recurrent', defaultTab?: 'app' | 'api' | 'dataset' }) {
    const { data: products = [] } = useQuery<BillingProduct[]>({
        queryKey: ['billing-products'],
        queryFn: getBillingProducts,
        staleTime: Infinity,
    });

    const { data: location } = useQuery<Geolocation>({
        queryKey: ['geolocation'],
        queryFn: getLocation,
        staleTime: Infinity,
    });

    const onSubscribe = (plan_id: string) => {
        if (plan_id == 'contact_sales') {
            sendEvent(EVENT_CONTACT_SALES_CLICK)
            window.location.replace('https://theirstack.com/en/contact')
        } else {
            sendEvent(EVENT_SUBSCRIBE_CLICK)
            getCheckoutSessionUrl(plan_id).then((url) => {
                window.location.replace(url)
            })
        }
    }

    const showPricingBox = products.length > 0 && location;


    return (
        <>
            {showPricingBox && (
                <PricingBox
                    defaultTab={defaultTab}
                    mode={mode}
                    products={products}
                    currency={location.currency}
                    onBillingPriceSelected={onSubscribe}
                />
            )}
            {!showPricingBox && (
                <div className='flex items-center justify-center mt-10'>
                    <Spinner />
                </div>
            )}

            <div className='flex items-center justify-center mt-6'>
                <a href="https://theirstack.com/en/pricing#faqs" target="_blank" className="cursor-pointer underline text-sm">
                    <Button variant="outline">Frequently asked questions</Button>
                </a>
            </div>
        </>
    )
}
