import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSavedSearch } from '@/components/hooks/data-table/use-saved-search';
import { JobsSavedSearch } from '@/schemas/entities/saved-search.schema';

import { getJobSearchById } from '@/services/saved-search.service';
import { DataTableWithJobsToolbar } from './components/data-table';
import NotFoundPage from '@/pages/not-found/not-found.page';
import { PageLayout } from '@/components/ui/layout/page_layout';

const JobSearchDetailsPage: React.FC = () => {
    const { searchId } = useParams<{ searchId: string }>();
    const { search, setSearch, isFetching: isSearchFetching } = useSavedSearch<JobsSavedSearch>(searchId, getJobSearchById)

    useEffect(() => {
        if (search) document.title = search.name + " · Search · TheirStack";
    }, [search]);

    return (
        <PageLayout className='overflow-hidden'>
            <div className='flex flex-col gap-4 h-full flex-grow overflow-hidden'>
                {search &&
                    <>
                        <DataTableWithJobsToolbar search={search} setSearch={setSearch} />
                    </>
                }
                {!search && !isSearchFetching && <NotFoundPage />}
            </div>
        </PageLayout>
    );
};

export default JobSearchDetailsPage;

