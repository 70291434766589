import { useErrorNotification } from "@/components/hooks/toast/use-error-notification";
import { Button } from "@/shared/ui/base/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/base/dropdown-menu";
import { useToast } from "@/components/ui/base/use-toast";
import { HOUR_IN_MS } from "@/shared/utils/date-utils";
import { titleCase } from "@/shared/utils/string-utils";
import { JobsSavedSearch, SavedSearch, jobsSavedSearchSchema } from "@/schemas/entities/saved-search.schema";
import { UserWithTeam } from "@/schemas/entities/user";
import { createCompanySearch, createJobsSearch, deleteSearch, getCompanySearchById, getJobSearchById, updateCompanySearch, updateJobSearch } from "@/services/saved-search.service";
import { getMyProfile } from "@/services/user.service";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Bell, BellOff, Copy, Trash2 } from "lucide-react";

import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function CardActions({ saved_search }: { saved_search: SavedSearch }) {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    });
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const handleDelete = () => {
        deleteSearch(saved_search.id).then(() => {
            toast({ title: "Search deleted" })
            queryClient.invalidateQueries({ queryKey: ['searches'] })
        })
    }

    const handleDuplicate = async () => {
        if (saved_search.type == 'jobs') {
            const jobSearch = await getJobSearchById(saved_search.id)
            const newSearch = { ...jobSearch, ...{ name: `${saved_search.name} (copy)` } }
            createJobsSearch(newSearch).then(() => {
                toast({ title: "Search duplicated" })
                queryClient.invalidateQueries({ queryKey: ['searches'] })
            })
        } else if (saved_search.type == 'companies') {
            const companySearch = await getCompanySearchById(saved_search.id)
            const newSearch = { ...companySearch, ...{ name: `${saved_search.name} (copy)` } }
            createCompanySearch(newSearch).then(() => {
                toast({ title: "Search duplicated" })
                queryClient.invalidateQueries({ queryKey: ['searches'] })
            })
        }
    }

    const handleActivateorDesactivateAlert = (is_alert_active: boolean, frequency: 'daily' | 'weekly' | 'monthly' = 'daily') => {
        if (saved_search.type == 'jobs') {
            getJobSearchById(saved_search.id).then((search) => {
                const jobSearch: JobsSavedSearch = jobsSavedSearchSchema.parse({ ...search, ...{ is_alert_active, frequency } })
                updateJobSearch(jobSearch.id, jobSearch).then(() => {
                    const title = is_alert_active ? `${titleCase(frequency)} alert enabled` : `${titleCase(frequency)} alert disabled`
                    toast({ title })
                    queryClient.invalidateQueries({ queryKey: ['searches'] })
                })
            }).catch(() => {
                showErrorNotification()
            })
        } else if (saved_search.type == 'companies') {
            getCompanySearchById(saved_search.id).then((search) => {
                const companySearch = { ...search, ...{ is_alert_active, frequency } }
                updateCompanySearch(companySearch.id, companySearch).then(() => {
                    const title = is_alert_active ? `${titleCase(frequency)} alert enabled` : `${titleCase(frequency)} alert disabled`
                    toast({ title })
                    queryClient.invalidateQueries({ queryKey: ['searches'] })
                })
            }).catch(() => {
                showErrorNotification()
            })
        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
                <DropdownMenuItem disabled={!(me && (me.id == saved_search.user_id || me.is_superuser == true))} onClick={handleDelete} ><Trash2 className="mr-2 h-4 w-4" /> Delete</DropdownMenuItem>
                <DropdownMenuItem onClick={handleDuplicate}><Copy className="mr-2 h-4 w-4" />  Duplicate</DropdownMenuItem>
                <>
                    {saved_search.is_alert_active == true && <DropdownMenuItem onClick={() => handleActivateorDesactivateAlert(false)}> <BellOff className="mr-2 h-4 w-4" />Disable alert</DropdownMenuItem>}
                    {saved_search.is_alert_active == false && <DropdownMenuItem onClick={() => handleActivateorDesactivateAlert(true, 'daily')}> <Bell className="mr-2 h-4 w-4" /> Enable daily alert</DropdownMenuItem>}
                    {saved_search.is_alert_active == false && <DropdownMenuItem onClick={() => handleActivateorDesactivateAlert(true, 'weekly')}><Bell className="mr-2 h-4 w-4" />Enable weekly alert</DropdownMenuItem>}
                </>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}