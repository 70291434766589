import { Clock, X } from "lucide-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IFiltersProps, IOperator } from "@/shared/schemas/entity/filter.schema";
import { fromStringToDateRange, getDaysBetweenDates } from "@/shared/utils/date-utils";


export interface LongRunningQueryBannerHandle {
    open: () => void;
    close: () => void;
    setTitle: (title: string) => void;
    setDescription: (description: string) => void;
}
interface LongRunningQueryBannerProps {
}

const LongRunningQueryBanner = forwardRef<LongRunningQueryBannerHandle, LongRunningQueryBannerProps>((_props, ref) => {

    const [isOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        setTitle: (title: string) => setTitle(title),
        setDescription: (description: string) => setDescription(description),
    }));
    return (
        <>
            {isOpen &&
                <div className="flex items-start justify-between space-x-4 py-4 pl-8 pr-4  md:flex bg-orange-100 rounded-lg">
                    <div className="flex items-center justify-start space-x-4">
                        <Clock className="h-6 w-6 text-orange-800 inline " />
                        <div>
                            <p className="text-sm text-orange-800 font-bold">{title}</p>
                            <p className="text-sm text-orange-800  font-normal">{description}</p>
                        </div>
                    </div>
                    <X className=" h-5 w-5 text-orange-800 hover:text-gray-800 inline cursor-pointer" onClick={() => setIsOpen(false)} />
                </div>
            }
        </>
    )
})

export default LongRunningQueryBanner


export function useLongRunningJobQuery({ longRunningQueryBannerRef, appliedFilters }: { longRunningQueryBannerRef: React.RefObject<LongRunningQueryBannerHandle>, appliedFilters: IFiltersProps[] }) {
    useEffect(() => {
        if (!longRunningQueryBannerRef || !longRunningQueryBannerRef.current) return
        longRunningQueryBannerRef.current.setTitle("This search will probably be slow")

        const exist_posted_at_greater_90 = appliedFilters.some(filter => (filter.id === 'posted_at' && filter.operator === IOperator.max_age_days && filter.value as number > 90))
        const exist_company_name = appliedFilters.some(filter => (filter.id === 'company_name'))
        const exist_posted_at_between_dates = appliedFilters.some(filter => {
            if (filter.id === 'posted_at' && filter.operator === IOperator.between_dates) {
                const range = fromStringToDateRange(filter.value as string)
                if (!range) return false
                return getDaysBetweenDates(range.from, range.to) > 90
            }
            return false
        })

        if ((exist_posted_at_greater_90 || exist_posted_at_between_dates) && !exist_company_name) {
            longRunningQueryBannerRef.current.setDescription("This search may take a long time or time out. Filter by date more aggressively or contact us at founders@theirstack.com.")
            longRunningQueryBannerRef.current.open()
        } else {
            longRunningQueryBannerRef.current.close()
        }
    }, [appliedFilters, longRunningQueryBannerRef])
}

export function useLongRunningCompanyQuery({ longRunningQueryBannerRef, appliedFilters }: { longRunningQueryBannerRef: React.RefObject<LongRunningQueryBannerHandle>, appliedFilters: IFiltersProps[] }) {
    useEffect(() => {
        if (!longRunningQueryBannerRef || !longRunningQueryBannerRef.current) return
        longRunningQueryBannerRef.current.setTitle("This search will probably be slow")

        const exist_posted_at_greater_90 = appliedFilters.some(filter => (filter.id === 'job_filters.posted_at' && filter.operator === IOperator.max_age_days && filter.value as number > 90))
        const exist_company_name = appliedFilters.some(filter => (filter.id === 'company_name'))
        const exist_posted_at_between_dates = appliedFilters.some(filter => {
            if (filter.id === 'job_filters.posted_at' && filter.operator === IOperator.between_dates) {
                const range = fromStringToDateRange(filter.value as string)
                if (!range) return false
                return getDaysBetweenDates(range.from, range.to) > 90
            }
            return false
        })

        if ((exist_posted_at_greater_90 || exist_posted_at_between_dates) && !exist_company_name) {
            longRunningQueryBannerRef.current.setDescription("This search may take a long time or time out. Filter by date more aggressively or contact us at founders@theirstack.com.")
            longRunningQueryBannerRef.current.open()
        } else {
            longRunningQueryBannerRef.current.close()
        }
    }, [appliedFilters, longRunningQueryBannerRef])
}