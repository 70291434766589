

import {
    ColumnDef, flexRender
} from "@tanstack/react-table";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/shared/ui/base/table";

import { DataTableViewOptions } from "./data-table-view-options";
import { CompaniesSearchParams } from "@/schemas/request/companies-search-params.schema";


declare module '@tanstack/react-table' {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends unknown> {
        updateRow?: (rowIndex: number, row: TData) => void,
        getBulkSelectionData: () => TData[],
        getTotalResults: () => number,
        calculateCredits: (n_companies: number) => Promise<number>,
        fetchAndAddRowsIncrementally: (pageIndex: number, pageSize: number) => Promise<{ is_out_of_credits: boolean }>,
        getCompanySearchParams?: () => CompaniesSearchParams,
    }

}

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    table: import("@tanstack/table-core").Table<TData>,
    isDataFetching: boolean
}

export function DataTable<TData, TValue>({
    columns,
    table,
    isDataFetching
}: DataTableProps<TData, TValue>) {

    return (
        <div className="overflow-auto rounded-md border">
            <Table className="bg-white ">
                <TableHeader sticky={true}>
                    <>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                                <th className="flex items-center justify-center h-12">
                                    <DataTableViewOptions table={table} />
                                </th>
                            </TableRow>
                        ))}
                    </>
                </TableHeader>
                <TableBody>
                    {isDataFetching &&
                        [...Array(25)].map((_, i) => (
                            <TableRow key={i}>
                                {Array
                                    .from({ length: table.getVisibleFlatColumns().length + 1 }) // +1 for the view options
                                    .map((_, i) => (
                                        <TableCell key={i}>
                                            <div className="h-6 bg-gray-200  rounded animate-pulse" />
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))
                    }
                    {!isDataFetching &&
                        <>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </>}
                </TableBody>
            </Table>
        </div >
    )
}