
import { z } from "zod"

export const technologySchema = z.object({
    name: z.string(),
    category: z.string().nullable().optional(),
    slug: z.string(),
    category_slug: z.string().nullable().optional(),
    one_liner: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    logo: z.string().optional().nullable(),
    logo_thumbnail: z.string().optional().nullable(),
    thumbnail: z.string().optional().nullable(),
    url: z.string().optional().nullable(),
    jobs: z.number().optional().nullable(),
    companies: z.number().optional().nullable(),
    companies_found_last_week: z.number().optional().nullable(),
})

export type Technology = z.infer<typeof technologySchema>

