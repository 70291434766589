import { useAuthentication } from "@/components/hooks/authentication/use-authentication";
import { useNavigate } from "react-router-dom";

function SignUpPage() {
    const { register, isLoading, isAuthenticated } = useAuthentication()
    const navigate = useNavigate()

    if (isLoading) {
        return <></>;
    }

    if (isLoading === false && isAuthenticated === false) {
        register()
    } else {
        navigate('/search')
    }

    return (<></>)
}

export default SignUpPage