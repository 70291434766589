export const countries = [
    {
        id: "AF",
        label: "Afghanistan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1eb.svg"
    },
    {
        id: "AX",
        label: "\u00c5land Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fd.svg"
    },
    {
        id: "AL",
        label: "Albania",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f1.svg"
    },
    {
        id: "DZ",
        label: "Algeria",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ff.svg"
    },
    {
        id: "AS",
        label: "American Samoa",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f8.svg"
    },
    {
        id: "AD",
        label: "Andorra",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e9.svg"

    },
    {
        id: "AO",
        label: "Angola",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f4.svg"
    },
    {
        id: "AI",
        label: "Anguilla",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ee.svg"
    },
    {
        id: "AQ",
        label: "Antarctica",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f6.svg"
    },
    {
        id: "AG",
        label: "Antigua and Barbuda",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ec.svg"
    },
    {
        id: "AR",
        label: "Argentina",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f7.svg"
    },
    {
        id: "AM",
        label: "Armenia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f2.svg"
    },
    {
        id: "AW",
        label: "Aruba",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fc.svg"
    },
    {
        id: "AU",
        label: "Australia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fa.svg"
    },
    {
        id: "AT",
        label: "Austria",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f9.svg"
    },
    {
        id: "AZ",
        label: "Azerbaijan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ff.svg"
    },
    {
        id: "BS",
        label: "Bahamas",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f8.svg"
    },
    {
        id: "BH",
        label: "Bahrain",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ed.svg"
    },
    {
        id: "BD",
        label: "Bangladesh",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e9.svg"
    },
    {
        id: "BB",
        label: "Barbados",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e7.svg"
    },
    {
        id: "BY",
        label: "Belarus",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fe.svg"
    },
    {
        id: "BE",
        label: "Belgium",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ea.svg"
    },
    {
        id: "BZ",
        label: "Belize",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ff.svg"
    },
    {
        id: "BJ",
        label: "Benin",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ef.svg"
    },
    {
        id: "BM",
        label: "Bermuda",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f2.svg"
    },
    {
        id: "BT",
        label: "Bhutan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f9.svg"
    },
    {
        id: "BO",
        label: "Bolivia, Plurinational State of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f4.svg"
    },
    {
        id: "BQ",
        label: "Bonaire, Sint Eustatius and Saba",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f6.svg"
    },
    {
        id: "BA",
        label: "Bosnia and Herzegovina",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e6.svg"
    },
    {
        id: "BW",
        label: "Botswana",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fc.svg"
    },
    {
        id: "BV",
        label: "Bouvet Island",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fb.svg"
    },
    {
        id: "BR",
        label: "Brazil",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f7.svg"
    },
    {
        id: "IO",
        label: "British Indian Ocean Territory",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f4.svg"
    },
    {
        id: "BN",
        label: "Brunei Darussalam",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f3.svg"
    },
    {
        id: "BG",
        label: "Bulgaria",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ec.svg"
    },
    {
        id: "BF",
        label: "Burkina Faso",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1eb.svg"
    },
    {
        id: "BI",
        label: "Burundi",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ee.svg"
    },
    {
        id: "KH",
        label: "Cambodia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ed.svg"
    },
    {
        id: "CM",
        label: "Cameroon",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f2.svg"
    },
    {
        id: "CA",
        label: "Canada",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg"
    },
    {
        id: "CV",
        label: "Cape Verde",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fb.svg"
    },
    {
        id: "KY",
        label: "Cayman Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fe.svg"
    },
    {
        id: "CF",
        label: "Central African Republic",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1eb.svg"
    },
    {
        id: "TD",
        label: "Chad",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e9.svg"
    },
    {
        id: "CL",
        label: "Chile",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f1.svg"
    },
    {
        id: "CN",
        label: "China",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f3.svg"
    },
    {
        id: "CX",
        label: "Christmas Island",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fd.svg"
    },
    {
        id: "CC",
        label: "Cocos (Keeling) Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e8.svg"
    },
    {
        id: "CO",
        label: "Colombia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f4.svg"
    },
    {
        id: "KM",
        label: "Comoros",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f2.svg"
    },
    {
        id: "CG",
        label: "Congo",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ec.svg"
    },
    {
        id: "CD",
        label: "Congo, the Democratic Republic of the",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e9.svg"
    },
    {
        id: "CK",
        label: "Cook Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f0.svg"
    },
    {
        id: "CR",
        label: "Costa Rica",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f7.svg"
    },
    {
        id: "CI",
        label: "C\u00f4te d'Ivoire",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ee.svg"
    },
    {
        id: "HR",
        label: "Croatia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f7.svg"
    },
    {
        id: "CU",
        label: "Cuba",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fa.svg"
    },
    {
        id: "CW",
        label: "Cura\u00e7ao",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fc.svg"
    },
    {
        id: "CY",
        label: "Cyprus",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fe.svg"
    },
    {
        id: "CZ",
        label: "Czech Republic",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ff.svg"
    },
    {
        id: "DK",
        label: "Denmark",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f0.svg"
    },
    {
        id: "DJ",
        label: "Djibouti",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ef.svg"
    },
    {
        id: "DM",
        label: "Dominica",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f2.svg"
    },
    {
        id: "DO",
        label: "Dominican Republic",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f4.svg"
    },
    {
        id: "EC",
        label: "Ecuador",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e8.svg"
    },
    {
        id: "EG",
        label: "Egypt",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ec.svg"
    },
    {
        id: "SV",
        label: "El Salvador",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fb.svg"
    },
    {
        id: "GQ",
        label: "Equatorial Guinea",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f6.svg"
    },
    {
        id: "ER",
        label: "Eritrea",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f7.svg"
    },
    {
        id: "EE",
        label: "Estonia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ea.svg"
    },
    {
        id: "ET",
        label: "Ethiopia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f9.svg"
    },
    {
        id: "FK",
        label: "Falkland Islands (Malvinas)",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f0.svg"
    },
    {
        id: "FO",
        label: "Faroe Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f4.svg"
    },
    {
        id: "FJ",
        label: "Fiji",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ef.svg"
    },
    {
        id: "FI",
        label: "Finland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ee.svg"
    },
    {
        id: "FR",
        label: "France",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f7.svg"
    },
    {
        id: "GF",
        label: "French Guiana",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1eb.svg"
    },
    {
        id: "PF",
        label: "French Polynesia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1eb.svg"
    },
    {
        id: "TF",
        label: "French Southern Territories",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1eb.svg"
    },
    {
        id: "GA",
        label: "Gabon",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e6.svg"
    },
    {
        id: "GM",
        label: "Gambia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f2.svg"
    },
    {
        id: "GE",
        label: "Georgia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ea.svg"
    },
    {
        id: "DE",
        label: "Germany",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ea.svg"
    },
    {
        id: "GH",
        label: "Ghana",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ed.svg"
    },
    {
        id: "GI",
        label: "Gibraltar",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ee.svg"
    },
    {
        id: "GR",
        label: "Greece",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f7.svg"
    },
    {
        id: "GL",
        label: "Greenland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f1.svg"
    },
    {
        id: "GD",
        label: "Grenada",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e9.svg"
    },
    {
        id: "GP",
        label: "Guadeloupe",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f5.svg"
    },
    {
        id: "GU",
        label: "Guam",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fa.svg"
    },
    {
        id: "GT",
        label: "Guatemala",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f9.svg"
    },
    {
        id: "GG",
        label: "Guernsey",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ec.svg"
    },
    {
        id: "GN",
        label: "Guinea",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f3.svg"
    },
    {
        id: "GW",
        label: "Guinea-Bissau",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fc.svg"
    },
    {
        id: "GY",
        label: "Guyana",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fe.svg"
    },
    {
        id: "HT",
        label: "Haiti",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f9.svg"
    },
    {
        id: "HM",
        label: "Heard Island and McDonald Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f2.svg"
    },
    {
        id: "VA",
        label: "Holy See (Vatican City State)",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e6.svg"
    },
    {
        id: "HN",
        label: "Honduras",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f3.svg"
    },
    {
        id: "HK",
        label: "Hong Kong",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f0.svg"
    },
    {
        id: "HU",
        label: "Hungary",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1fa.svg"
    },
    {
        id: "IS",
        label: "Iceland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f8.svg"
    },
    {
        id: "IN",
        label: "India",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f3.svg"
    },
    {
        id: "ID",
        label: "Indonesia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e9.svg"
    },
    {
        id: "IR",
        label: "Iran, Islamic Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f7.svg"
    },
    {
        id: "IQ",
        label: "Iraq",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f6.svg"
    },
    {
        id: "IE",
        label: "Ireland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1ea.svg"
    },
    {
        id: "IM",
        label: "Isle of Man",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f2.svg"
    },
    {
        id: "IL",
        label: "Israel",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f1.svg"
    },
    {
        id: "IT",
        label: "Italy",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f9.svg"
    },
    {
        id: "JM",
        label: "Jamaica",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f2.svg"
    },
    {
        id: "JP",
        label: "Japan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f5.svg"
    },
    {
        id: "JE",
        label: "Jersey",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1ea.svg"
    },
    {
        id: "JO",
        label: "Jordan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f4.svg"
    },
    {
        id: "KZ",
        label: "Kazakhstan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ff.svg"
    },
    {
        id: "KE",
        label: "Kenya",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ea.svg"
    },
    {
        id: "KI",
        label: "Kiribati",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ee.svg"
    },
    {
        id: "KP",
        label: "Korea, Democratic People's Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f5.svg"
    },
    {
        id: "KR",
        label: "Korea, Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f7.svg"
    },
    {
        id: "KW",
        label: "Kuwait",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fc.svg"
    },
    {
        id: "KG",
        label: "Kyrgyzstan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ec.svg"
    },
    {
        id: "LA",
        label: "Lao People's Democratic Republic",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e6.svg"
    },
    {
        id: "LV",
        label: "Latvia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fb.svg"
    },
    {
        id: "LB",
        label: "Lebanon",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e7.svg"
    },
    {
        id: "LS",
        label: "Lesotho",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f8.svg"
    },
    {
        id: "LR",
        label: "Liberia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f7.svg"
    },
    {
        id: "LY",
        label: "Libya",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fe.svg"
    },
    {
        id: "LI",
        label: "Liechtenstein",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1ee.svg"
    },
    {
        id: "LT",
        label: "Lithuania",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f9.svg"
    },
    {
        id: "LU",
        label: "Luxembourg",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fa.svg"
    },
    {
        id: "MO",
        label: "Macao",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f4.svg"
    },
    {
        id: "MK",
        label: "Macedonia, the Former Yugoslav Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f0.svg"
    },
    {
        id: "MG",
        label: "Madagascar",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ec.svg"
    },
    {
        id: "MW",
        label: "Malawi",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fc.svg"
    },
    {
        id: "MY",
        label: "Malaysia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fe.svg"
    },
    {
        id: "MV",
        label: "Maldives",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fb.svg"
    },
    {
        id: "ML",
        label: "Mali",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f1.svg"
    },
    {
        id: "MT",
        label: "Malta",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f9.svg"
    },
    {
        id: "MH",
        label: "Marshall Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ed.svg"
    },
    {
        id: "MQ",
        label: "Martinique",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f6.svg"
    },
    {
        id: "MR",
        label: "Mauritania",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f7.svg"
    },
    {
        id: "MU",
        label: "Mauritius",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fa.svg"
    },
    {
        id: "YT",
        label: "Mayotte",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1f9.svg",
    },
    {
        id: "MX",
        label: "Mexico",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fd.svg"
    },
    {
        id: "FM",
        label: "Micronesia, Federated States of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f2.svg"
    },
    {
        id: "MD",
        label: "Moldova, Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e9.svg"
    },
    {
        id: "MC",
        label: "Monaco",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e8.svg"
    },
    {
        id: "MN",
        label: "Mongolia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f3.svg"
    },
    {
        id: "ME",
        label: "Montenegro",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ea.svg"
    },
    {
        id: "MS",
        label: "Montserrat",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f8.svg"
    },
    {
        id: "MA",
        label: "Morocco",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e6.svg"
    },
    {
        id: "MZ",
        label: "Mozambique",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ff.svg"
    },
    {
        id: "MM",
        label: "Myanmar",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f2.svg"
    },
    {
        id: "NA",
        label: "Namibia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e6.svg"
    },
    {
        id: "NR",
        label: "Nauru",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f7.svg"
    },
    {
        id: "NP",
        label: "Nepal",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f5.svg"
    },
    {
        id: "NL",
        label: "Netherlands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f1.svg"
    },
    {
        id: "NC",
        label: "New Caledonia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e8.svg"
    },
    {
        id: "NZ",
        label: "New Zealand",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ff.svg"
    },
    {
        id: "NI",
        label: "Nicaragua",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ee.svg"
    },
    {
        id: "NE",
        label: "Niger",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ea.svg"
    },
    {
        id: "NG",
        label: "Nigeria",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ec.svg"
    },
    {
        id: "NU",
        label: "Niue",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1fa.svg"
    },
    {
        id: "NF",
        label: "Norfolk Island",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1eb.svg"
    },
    {
        id: "MP",
        label: "Northern Mariana Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f5.svg"
    },
    {
        id: "NO",
        label: "Norway",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f4.svg"
    },
    {
        id: "OM",
        label: "Oman",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f4-1f1f2.svg"
    },
    {
        id: "PK",
        label: "Pakistan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f0.svg"
    },
    {
        id: "PW",
        label: "Palau",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fc.svg"
    },
    {
        id: "PS",
        label: "Palestine, State of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f8.svg"
    },
    {
        id: "PA",
        label: "Panama",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1e6.svg"
    },
    {
        id: "PG",
        label: "Papua New Guinea",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ec.svg"
    },
    {
        id: "PY",
        label: "Paraguay",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fe.svg"
    },
    {
        id: "PE",
        label: "Peru",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ea.svg"
    },
    {
        id: "PH",
        label: "Philippines",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ed.svg"
    },
    {
        id: "PN",
        label: "Pitcairn",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f3.svg"
    },
    {
        id: "PL",
        label: "Poland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f1.svg"
    },
    {
        id: "PT",
        label: "Portugal",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f9.svg"
    },
    {
        id: "PR",
        label: "Puerto Rico",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f7.svg"
    },
    {
        id: "QA",
        label: "Qatar",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f6-1f1e6.svg"
    },
    {
        id: "RE",
        label: "R\u00e9union",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1ea.svg"
    },
    {
        id: "RO",
        label: "Romania",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f4.svg"
    },
    {
        id: "RU",
        label: "Russian Federation",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fa.svg"
    },
    {
        id: "RW",
        label: "Rwanda",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fc.svg"
    },
    {
        id: "BL",
        label: "Saint Barth\u00e9lemy",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f1.svg"
    },
    {
        id: "SH",
        label: "Saint Helena, Ascension and Tristan da Cunha",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ed.svg"
    },
    {
        id: "KN",
        label: "Saint Kitts and Nevis",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f3.svg"
    },
    {
        id: "LC",
        label: "Saint Lucia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e8.svg"
    },
    {
        id: "MF",
        label: "Saint Martin (French part)",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1eb.svg"
    },
    {
        id: "PM",
        label: "Saint Pierre and Miquelon",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f2.svg"
    },
    {
        id: "VC",
        label: "Saint Vincent and the Grenadines",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e8.svg"
    },
    {
        id: "WS",
        label: "Samoa",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1f8.svg"
    },
    {
        id: "SM",
        label: "San Marino",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f2.svg"
    },
    {
        id: "ST",
        label: "Sao Tome and Principe",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f9.svg"
    },
    {
        id: "SA",
        label: "Saudi Arabia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e6.svg"
    },
    {
        id: "SN",
        label: "Senegal",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f3.svg"
    },
    {
        id: "RS",
        label: "Serbia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f8.svg"
    },
    {
        id: "SC",
        label: "Seychelles",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e8.svg"
    },
    {
        id: "SL",
        label: "Sierra Leone",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f1.svg"
    },
    {
        id: "SG",
        label: "Singapore",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ec.svg"
    },
    {
        id: "SX",
        label: "Sint Maarten (Dutch part)",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fd.svg"
    },
    {
        id: "SK",
        label: "Slovakia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f0.svg"
    },
    {
        id: "SI",
        label: "Slovenia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ee.svg"
    },
    {
        id: "SB",
        label: "Solomon Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e7.svg"
    },
    {
        id: "SO",
        label: "Somalia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f4.svg"
    },
    {
        id: "ZA",
        label: "South Africa",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1e6.svg"
    },
    {
        id: "GS",
        label: "South Georgia and the South Sandwich Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f8.svg"
    },
    {
        id: "SS",
        label: "South Sudan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f8.svg"
    },
    {
        id: "ES",
        label: "Spain",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f8.svg"
    },
    {
        id: "LK",
        label: "Sri Lanka",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f0.svg"
    },
    {
        id: "SD",
        label: "Sudan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e9.svg"
    },
    {
        id: "SR",
        label: "Suriname",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f7.svg"
    },
    {
        id: "SJ",
        label: "Svalbard and Jan Mayen",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ef.svg"
    },
    {
        id: "SZ",
        label: "Swaziland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ff.svg"
    },
    {
        id: "SE",
        label: "Sweden",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ea.svg"
    },
    {
        id: "CH",
        label: "Switzerland",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ed.svg"
    },
    {
        id: "SY",
        label: "Syrian Arab Republic",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fe.svg"
    },
    {
        id: "TW",
        label: "Taiwan, Province of China",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fc.svg"
    },
    {
        id: "TJ",
        label: "Tajikistan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ef.svg"
    },
    {
        id: "TZ",
        label: "Tanzania, United Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ff.svg"
    },
    {
        id: "TH",
        label: "Thailand",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ed.svg"
    },
    {
        id: "TL",
        label: "Timor-Leste",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f1.svg"
    },
    {
        id: "TG",
        label: "Togo",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ec.svg"
    },
    {
        id: "TK",
        label: "Tokelau",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f0.svg"
    },
    {
        id: "TO",
        label: "Tonga",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f4.svg"
    },
    {
        id: "TT",
        label: "Trinidad and Tobago",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f9.svg"
    },
    {
        id: "TN",
        label: "Tunisia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f3.svg"
    },
    {
        id: "TR",
        label: "Turkey",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f7.svg"
    },
    {
        id: "TM",
        label: "Turkmenistan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f2.svg"
    },
    {
        id: "TC",
        label: "Turks and Caicos Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e8.svg"
    },
    {
        id: "TV",
        label: "Tuvalu",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fb.svg"
    },
    {
        id: "UG",
        label: "Uganda",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ec.svg"
    },
    {
        id: "UA",
        label: "Ukraine",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg"
    },
    {
        id: "AE",
        label: "United Arab Emirates",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ea.svg"
    },
    {
        id: "GB",
        label: "United Kingdom",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e7.svg"
    },
    {
        id: "US",
        label: "United States",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg"
    },
    {
        id: "UM",
        label: "United States Minor Outlying Islands",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f2.svg"
    },
    {
        id: "UY",
        label: "Uruguay",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1fe.svg"
    },
    {
        id: "UZ",
        label: "Uzbekistan",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ff.svg"
    },
    {
        id: "VU",
        label: "Vanuatu",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1fa.svg"
    },
    {
        id: "VE",
        label: "Venezuela, Bolivarian Republic of",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ea.svg"
    },
    {
        id: "VN",
        label: "Viet Nam",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1f3.svg"
    },
    {
        id: "VG",
        label: "Virgin Islands, British",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ec.svg"
    },
    {
        id: "VI",
        label: "Virgin Islands, U.S.",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ee.svg"
    },
    {
        id: "WF",
        label: "Wallis and Futuna",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1eb.svg",
    },
    {
        id: "EH",
        label: "Western Sahara",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ed.svg"
    },
    {
        id: "YE",
        label: "Yemen",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1ea.svg"
    },
    {
        id: "ZM",
        label: "Zambia",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1f2.svg"
    },
    {
        id: "ZW",
        label: "Zimbabwe",
        flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1fc.svg"
    }
]