import { useEffect, useMemo, useRef, useState } from "react"
import { ColumnDef, ColumnFiltersState, OnChangeFn, PaginationState, RowSelectionState, SortingState, VisibilityState, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { APIResponse, Metadata } from "@/schemas/responses/response-base.schema"
import { areObjectsEqual } from "@/lib/search-params-utils"
import { useErrorNotification } from "../toast/use-error-notification"
import { useQueryClient } from "@tanstack/react-query"
import { FIELDS_TO_OMIT_FOR_COMPARISON, fromSearchParamsToSortingState } from "./use-fetch-data-and-total-results-for-table"
import { BaseSearchParams } from "@/schemas/request/base-search-params.schema"
import { countSearchCompanies } from "@/services/company.service"
import { IFiltersProps } from "@/shared/schemas/entity/filter.schema"
import { filters } from "@/pages/search/companies/components/filters"
import { filtersToParams, paramsToFilters } from "@/components/ui/search-filter/filter-mapper"

export function useAutoSearchTable<TSearchParams extends BaseSearchParams, TData>(
    savedSearch: TSearchParams,
    columns: ColumnDef<TData>[],
    hiddenColumns: VisibilityState,
    fetchDataFromAPI: (searchParams: TSearchParams, pagination: PaginationState, include_total_results: boolean, signal: AbortSignal) => Promise<APIResponse<TData>>,
    fetchOnMount: boolean = true
) {
    const [data, setData] = useState<TData[]>([])
    const [bulkSelectionData, setBulkSelectionData] = useState<TData[]>([])
    const [metadata, setMetadata] = useState<Metadata>({ total_results: 0, truncated_results: 0, truncated_companies: 0 })
    const [searchedSP, setSearchedSP] = useState<TSearchParams>(savedSearch)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    const [isDataFetching, setIsDataFetching] = useState<boolean>(false)
    const [wasFirstSearchTriggered, setWasFirstSearchTriggered] = useState<boolean>(false)
    const [totalResults, setTotalResults] = useState<number>(0)
    const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })
    const [sorting, setSorting] = useState<SortingState>([])
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const queryClient = useQueryClient()
    const abortControllerRef = useRef<AbortController | null>(null);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(hiddenColumns)
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([])

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const isCurrentSearchSaved = useMemo(() => {
        return areObjectsEqual(searchedSP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON)
    }, [searchedSP, savedSearch]);

    const isCurrentSearchSearched = useMemo(() => {
        if (!wasFirstSearchTriggered) return false;
        return true
    }, [wasFirstSearchTriggered]);

    useEffect(() => {
        if (!areObjectsEqual(searchedSP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON) || !wasFirstSearchTriggered) {
            const savedFilters = paramsToFilters(savedSearch, filters)
            const missingPermanentFilters = filters
                .filter(filter => filter.is_permanent)
                .filter(filter => !savedFilters.some(newFilter => newFilter.id === filter.id))
                .map(filter => ({ ...filter, operator: filter.availableOperators[0] }))
            const newFilters = [...savedFilters, ...missingPermanentFilters]
            setAppliedFilters(newFilters)

            setSearchedSP(savedSearch)
            if (!fetchOnMount) return
            setSorting(fromSearchParamsToSortingState(savedSearch))
            fetchData(savedSearch, pagination)
        }
    }, [savedSearch])

    const runSearch = () => {
        onChangeFilters(searchedSP)
    }

    const onChangeFilters = (newSearchParams: TSearchParams) => {
        if (areObjectsEqual(newSearchParams, searchedSP, FIELDS_TO_OMIT_FOR_COMPARISON) && wasFirstSearchTriggered) return
        setSearchedSP(newSearchParams)
        const newPagination = resetPagination()
        resetSelection()
        fetchData(newSearchParams, newPagination)
    }

    const onChangePagination: OnChangeFn<PaginationState> = (updaterOrValue: (PaginationState | ((old: PaginationState) => PaginationState))) => {
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newPagination = updater(pagination)
        setPagination(newPagination)
        resetSelection()
        fetchData(searchedSP, newPagination)
    }

    const onChangeSorting: OnChangeFn<SortingState> = (updaterOrValue: (SortingState | ((old: SortingState) => SortingState))) => {
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newSorting = updater(sorting)
        setSorting(newSorting)
        const newSearch = { ...searchedSP, order_by: [{ field: newSorting[0]?.id, desc: newSorting[0]?.desc }] }
        setSearchedSP(newSearch)
        const newPagination = resetPagination()
        resetSelection()
        fetchData(newSearch, newPagination)
    }

    const resetAbortController = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;
        return newAbortController.signal
    }

    const fetchData = async (search: TSearchParams, ps: PaginationState) => {
        setWasFirstSearchTriggered(true)
        setIsDataFetching(true)
        setTotalResults(-1)
        const blur_params = { ...search, blur_company_data: true }
        fetchDataFromAPI(blur_params, ps, true, resetAbortController()).then((newData) => {
            setData(newData.data)
            setMetadata(newData.metadata)
            setTotalResults(newData.metadata.total_results || 0)
            setIsDataFetching(false)
            queryClient.invalidateQueries({ queryKey: ['me'] })
        }, (e) => {
            if (e instanceof DOMException && e.name === 'AbortError') return
            setTotalResults(0)
            setIsDataFetching(false)
            console.error(e)
            showErrorNotification()
        })
    }

    const fetchAndAddRowsIncrementally = async (page: number, pageSize: number = 100) => {
        const newPagination = { pageIndex: page, pageSize: pageSize }
        const unblurred_params = { ...searchedSP, blur_company_data: false }
        return fetchDataFromAPI(unblurred_params, newPagination, false, resetAbortController()).then((response) => {
            const mergedData = page != 0 ? [...bulkSelectionData, ...response.data] : response.data
            if (page == 0) setData(mergedData.slice(0, pagination.pageSize)) // Update table revealing hidden data if is needed
            setBulkSelectionData(mergedData)
            return { is_out_of_credits: response.metadata.truncated_companies > 0 }
        }).catch((e) => {
            if (e instanceof DOMException && e.name === 'AbortError') return { is_out_of_credits: false }
            console.error(e)
            showErrorNotification()
            return { is_out_of_credits: false }
        })
    }

    const calculateCredits = async (n_companies: number) => {
        const newPagination = { pageIndex: 0, pageSize: n_companies }
        return countSearchCompanies(searchedSP, newPagination).then((response) => {
            return response.unrevealed
        }, (e) => {
            if (e instanceof DOMException && e.name === 'AbortError') return 0
            console.error(e)
            showErrorNotification()
            return 0
        })
    }

    const resetPagination = () => {
        const newPagination = { ...pagination, pageIndex: 0 }
        if (pagination.pageIndex != 0) setPagination(newPagination)
        return newPagination
    }

    const resetSelection = () => {
        setRowSelection({})
        setBulkSelectionData([])
    }

    const table = useReactTable({
        data: data,
        columns: columns,
        pageCount: Math.ceil(totalResults / pagination.pageSize),
        state: {
            sorting,
            pagination,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
        onPaginationChange: onChangePagination,
        onRowSelectionChange: setRowSelection,
        onSortingChange: onChangeSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        meta: {
            updateRow: (rowIndex, newRow: TData) => {
                setData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return { ...row, ...newRow }
                        }
                        return row
                    })
                )
            },
            getTotalResults: () => totalResults,
            getCompanySearchParams: () => searchedSP,
            fetchAndAddRowsIncrementally: fetchAndAddRowsIncrementally,
            getBulkSelectionData: () => bulkSelectionData,
            calculateCredits: calculateCredits,
        }
    });

    const onChangeFilter = (index: number, filter: IFiltersProps) => {
        const newFilters = [...appliedFilters]
        newFilters[index] = filter
        newFilters[index].hasChanged = true
        setAppliedFilters(newFilters)
        onChangeFilters(filtersToParams(newFilters, searchedSP.order_by))
    }

    const onRemoveFilter = (index: number) => {
        const newFilters = [...appliedFilters]
        newFilters.splice(index, 1)
        setAppliedFilters(newFilters)
        if (newFilters.length === 0) {
            setWasFirstSearchTriggered(false)
        } else {
            onChangeFilters(filtersToParams(newFilters, searchedSP.order_by))
        }
    }

    const addFilters = (filters: IFiltersProps[]) => {
        const newFilters = [...appliedFilters, ...filters]
        setAppliedFilters(newFilters)
    }

    const setFiltersAsNotChanged = () => {
        setAppliedFilters(appliedFilters.map(filter => ({ ...filter, hasChanged: false })))
    }

    return {
        table,
        metadata,
        columnVisibility,
        setColumnVisibility,
        searchedSP,
        data,
        isDataFetching,
        totalResults,
        pagination,
        sorting,
        rowSelection,
        onChangeFilters,
        onChangePagination,
        onChangeSorting,
        isCurrentSearchSaved,
        isCurrentSearchSearched,
        fetchAndAddRowsIncrementally,
        onChangeFilter,
        onRemoveFilter,
        appliedFilters,
        addFilters,
        setFiltersAsNotChanged,
        runSearch
    }
}