import { cn } from "@/lib/utils";
import { UserWithTeam, getIncompletePlans, hasIcompletePlans } from "@/schemas/entities/user";
import { getMyProfile } from "@/services/user.service";
import { THERE_MINUTE_IN_MS } from "@/shared/utils/date-utils";
import { useQuery } from "@tanstack/react-query";
import { AlertCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/base/button";

export function PaymentPendingBanner({ className }: { className?: string }) {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    });
    return (
        <div className={cn(className)}>
            {me && hasIcompletePlans(me) &&
                <div className="h-full flex items-start justify-between gap-4 py-4 px-8  bg-orange-100 rounded-lg flex-wrap">
                    <div className="flex items-start justify-start gap-4">
                        <AlertCircle className="hidden md:inline h-6 w-6 text-orange-800  " />
                        <div>
                            <p className="text-sm text-orange-800 font-bold"> Your plan is paused</p>
                            <p className="text-sm text-orange-800  font-normal">Your plan has been paused due to a pending payment. Please complete the payment to resume your service.</p>
                        </div>
                    </div>
                    <Button variant="outline" asChild>
                        <Link to={getIncompletePlans(me)[0]?.hosted_invoice_url || ''} target="_blank" className="text-orange-800">Complete payment</Link>
                    </Button>
                </div>
            }
        </div>
    )

}