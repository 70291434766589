import { format } from "date-fns";

export const DATES_SEPARATOR = ' - '
export const GTE = '_gte'
export const LTE = '_lte'
export const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;
export const DAY_IN_MS = 1000 * 60 * 60 * 24;
export const HOUR_IN_MS = 1000 * 60 * 60;
export const MINUTE_IN_MS = 1000 * 60;
export const THERE_MINUTE_IN_MS = MINUTE_IN_MS * 3;
export const MONTH_IN_SECONDS = 60 * 60 * 24 * 30;

export const timeAgo = (date_string: string): string => {
    const now = new Date();
    const diff = now.getTime() - new Date(date_string).getTime();
    const diffInDays = Math.floor(diff / DAY_IN_MS);
    const diffInHours = Math.floor(diff / HOUR_IN_MS);
    const diffInMinutes = Math.floor(diff / MINUTE_IN_MS);
    if (diffInDays > 0) {
        return `${diffInDays} days ago`
    } else if (diffInHours > 0) {
        return `${diffInHours} hours ago`
    } else if (diffInMinutes > 0) {
        return `${diffInMinutes} minutes ago`
    } else {
        return 'just now'
    }
}

export const formatDateStringToReadableDate = (date_string: string): string => {
    const [year, month, day] = date_string.split('-'); /*YYYY-MM-DD*/
    const monthlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return `${monthlist[parseInt(month) - 1]} ${day}, ${year}`
}

export const formatDateTimeStringToReadableDate = (date_string: string): string => {
    const date = new Date(date_string);
    return format(date, "MMM dd, yyyy")
}


export const formatDateFromDateObject = (date: Date): string => {
    return format(date, "MMM dd, yyyy")
}

export const getCurrentDateTime = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `${currentDate} ${currentTime}`

}

export function fromStringToDateRange(value: string | undefined) {
    if (!value) return undefined
    const [fromDate, toDate] = value.split(DATES_SEPARATOR)
    return {
        from: new Date(fromDate),
        to: new Date(toDate),
    }
}

export function getDaysBetweenDates(from: Date, to: Date) {
    const diffInMs = Math.abs(from.getTime() - to.getTime());
    return Math.round(diffInMs / DAY_IN_MS);
}