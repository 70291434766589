import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema"
import { JobResponse, jobsResponseSchema } from "@/schemas/responses/jobs-response.schema"
import { getHTTPHeaders, getHTTPHeadersImpersonating } from "@/lib/authentication/authentication-http-headers"
import { PaginationState } from "@tanstack/react-table"
import { isRevealCompaniesAutomaticallyEnabled } from "@/pages/settings/billing/billing.page"
import { JobCountResponse, jobsCountResponseSchema } from "@/schemas/responses/jobs-count-response.schema"
import { fetchToCurl, sourceSessionParamsObject } from "@/lib/http-utils"
import { constructURL } from "@/lib/http-utils"
import { HTTP_STATUS_CODES } from "@/lib/http-utils"

const RESOURCE_URL = '/v1/jobs/search'

export function getJobsFromSearchParamsBody(params: JobsSearchParams, pagination: PaginationState, include_total_results: boolean): JobsSearchParams {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    newParams.include_total_results = include_total_results
    if (newParams.blur_company_data === undefined) {
        newParams.blur_company_data = isRevealCompaniesAutomaticallyEnabled() ? false : true
    }
    return newParams
}

export async function getJobsFromSearchParams(params: JobsSearchParams, pagination: PaginationState, include_total_results: boolean): Promise<JobResponse> {
    const url = constructURL(import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL, sourceSessionParamsObject())
    const options = {
        method: 'POST',
        headers: getHTTPHeaders(),
        body: JSON.stringify(getJobsFromSearchParamsBody(params, pagination, include_total_results))
    }
    const res = await fetch(url, options)
    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) {
        throw new Error('Failed to fetch data')
    }
    return jobsResponseSchema.parse(await res.json())
}

export function getJobsCURL(params: JobsSearchParams, pagination: PaginationState): string {
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL
    const body = getJobsFromSearchParamsBody(params, pagination, false)
    body.blur_company_data = false
    const options = {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(body)
    }
    return fetchToCurl(url, options)
}

export async function getJobCountFromSearchParams(params: JobsSearchParams, pagination: PaginationState): Promise<JobCountResponse> {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    const url = constructURL(import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + '/counts_within_limit', sourceSessionParamsObject())
    const res = await fetch(url, { method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(newParams) })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }

    return jobsCountResponseSchema.parse(await res.json())
}
