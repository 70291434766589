

import SearchFilterBase from "@/components/ui/search-filter/search-filter-base"
import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema"
import { Table } from "@tanstack/react-table"
import ButtonAddFilter from "../../../../../components/ui/table/data-table-filters-add-button"

import useWarnIfUnsavedChanges from "@/components/hooks/use-warn-if-unsaved-changes"
import { Button } from "@/shared/ui/base/button"
import { Separator } from "@/shared/ui/base/separator"
import { IFiltersProps } from "@/shared/schemas/entity/filter.schema"
import { filters } from "@/pages/search/jobs/components/filters"
import { JobsSavedSearch } from "@/schemas/entities/saved-search.schema"
import { renameSearch, updateJobSearch } from "@/services/saved-search.service"
import { ReloadIcon } from "@radix-ui/react-icons"
import { SearchIcon } from "lucide-react"
import { useToast } from "../../../../../components/ui/base/use-toast"
import AddCompanyToList from "../../../../../components/ui/table/actions/action-add-to-company-to-list"
import FindPeopleButton from "../../../../../components/ui/table/actions/action-find-people"
import ExportJobsButton from "../../components/action-export-jobs"
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification"
import { Job } from "@/schemas/entities/job.schema"
import { companySchema } from "@/shared/schemas/entity/company.schema"
import SwitchSearchObjectTab from "@/components/ui/switch-search-object-tab"
import { Metadata } from "@/schemas/responses/response-base.schema"
import LongRunningQueryBanner, { LongRunningQueryBannerHandle, useLongRunningJobQuery } from "@/components/ui/banners/long-running-query-banner"
import { useRef } from "react"
import SearchTitleInput from "@/components/ui/search-title-input"
import APICurlButton from "@/components/ui/table/actions/action-get-api-curl"
import { getJobsCURL } from "@/services/job.service"
import { isFilterPermanent } from "@/components/ui/search-filter/filter-utils"


interface DataTableToolbarProps {
    search: JobsSavedSearch,
    setSearch: (_search: JobsSavedSearch) => void,
    table: Table<Job>,
    isDataFetching: boolean,
    isCurrentSearchSearched: boolean,
    dirtySearchParams: JobsSearchParams,
    setDirtySearchParams: (_params: JobsSearchParams) => void,
    totalResults: number,
    isCurrentSearchSaved: boolean,
    onChangeFilters: (_params: JobsSearchParams) => void,
    appliedFilters: IFiltersProps[],
    addFilters: (_filters: IFiltersProps[]) => void,
    onChangeFilter: (index: number, filter: IFiltersProps) => void,
    onRemoveFilter: (index: number) => void,
    setFiltersAsNotChanged: () => void,
    metadata: Metadata
}

export function DataTableToolbar({ search,
    isDataFetching,
    isCurrentSearchSearched,
    setSearch,
    table,
    dirtySearchParams,
    totalResults,
    isCurrentSearchSaved: isCurrentSearchSave,
    onChangeFilters,
    appliedFilters,
    addFilters,
    onChangeFilter,
    onRemoveFilter,
    setFiltersAsNotChanged,
    metadata
}: DataTableToolbarProps) {
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const { toast } = useToast()
    useWarnIfUnsavedChanges(!isCurrentSearchSave);
    const longRunningQueryBannerRef = useRef<LongRunningQueryBannerHandle>(null);
    useLongRunningJobQuery({ longRunningQueryBannerRef, appliedFilters })


    const handleSaveSearch = () => {
        const newSearch = { ...search }
        newSearch.body = dirtySearchParams
        updateJobSearch(search.id, newSearch).then((response) => {
            setSearch(response)
            setFiltersAsNotChanged()
            toast({ title: "Search saved" })
        }).catch(() => {
            showErrorNotification()
        })
    }

    const handleSearch = () => {
        onChangeFilters(dirtySearchParams)
    }

    const handleRenameSearch = (name: string) => {
        renameSearch(search.id, name).then(() => {
            setSearch({ ...search, ...{ name: name } })
            toast({ title: "Search renamed" })
        }).catch(() => {
            showErrorNotification()
        })
    }

    const isFilterRemovable = (filter: IFiltersProps) => {
        return filter.id !== 'posted_at'
    }

    return (
        <>
            <div className="flex flex-wrap md:flex-nowrap items-center justify-start gap-2">
                <SwitchSearchObjectTab totalJobs={totalResults} totalCompanies={metadata.total_companies ?? undefined} default_tab="jobs" job_filters={dirtySearchParams} was_searched={true} />
                <SearchTitleInput className="grow overflow-hidden" name={search.name} updateName={handleRenameSearch} />
                <div className="flex flex-grap gap-1 border rounded-md bg-white">
                    <ExportJobsButton isDataFetching={isDataFetching} table={table} />
                    <AddCompanyToList isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => companySchema.parse(row.company_object))} />
                    <FindPeopleButton isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => companySchema.parse(row.company_object))} />
                    <APICurlButton className="hidden lg:block" curl={getJobsCURL(dirtySearchParams, { pageIndex: 0, pageSize: 10 })} />
                </div>
                {!isDataFetching && <Button variant={isCurrentSearchSearched ? "outline" : "default"} size="sm" onClick={() => handleSearch()}> <SearchIcon className="mr-2 h-4 w-4" /> Search</Button>}
                {isDataFetching && <Button variant="outline" disabled> <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> Loading </Button>}
            </div >
            <Separator />
            <div className="flex items-start justify-between gap-1">
                <div className="flex flex-wrap items-center gap-2">
                    {appliedFilters.map((filter, index) => (
                        <SearchFilterBase
                            key={index}
                            index={index}
                            isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                            filter={filter}
                            changeFilter={onChangeFilter}
                            removeFilter={onRemoveFilter}
                            appliedFilters={appliedFilters}
                            isRemovable={isFilterRemovable(filter)}
                            hasChanged={filter.hasChanged || false} />
                    ))}
                    <ButtonAddFilter availableFilters={filters} appliedFilters={appliedFilters} addFilters={addFilters} />
                </div>
                {!isCurrentSearchSave && <Button variant="secondary" size="sm" onClick={() => handleSaveSearch()} className="bg-orange-300 hover:bg-orange-200">Save</Button>}
            </div>
            <LongRunningQueryBanner ref={longRunningQueryBannerRef} />
        </>
    )
}
