import FindPeopleButton from "@/components/ui/table/actions/action-find-people";
import { Table } from "@tanstack/react-table";
import Export from "./action-export";
import { Input } from "@/components/ui/base/input";
import { useEffect, useMemo, useState } from "react";
import debouce from "lodash.debounce";
import { CompanyFromListSearchParams } from "@/schemas/request/companies-from-list-search-params.schema";
import { CompanyFromList } from "@/schemas/entities/companies-from-company-list";
import { companySchema } from "@/shared/schemas/entity/company.schema";
;

interface DataTableToolbarProps {
    table: Table<CompanyFromList>,
    filter_by?: string,
    isDataFetching: boolean,
    onChangeFilters: (_params: CompanyFromListSearchParams) => void,
    searchedSP: CompanyFromListSearchParams,
}

export function DataTableToolbar({ table, isDataFetching, onChangeFilters, searchedSP }: DataTableToolbarProps) {
    const [searchQuery, setSearchQuery] = useState("");

    const debouncedResults = useMemo(() => {
        return debouce((e) => setSearchQuery(e.target.value), 400);
    }, []);

    useEffect(() => {
        const searchParams = { ...searchedSP, company_name_partial: searchQuery }
        onChangeFilters(searchParams)
    }, [searchQuery])

    return (
        <>
            <div className="flex flex-wrap justify-between  items-end gap-2 content-end">
                <Input
                    placeholder="Filter by company name..."
                    onChange={debouncedResults}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                <div className="flex flex-grap gap-1 border rounded-md bg-white">
                    <Export disabled={isDataFetching} table={table} />
                    <FindPeopleButton isDataFetching={isDataFetching} table={table} fromRowToCompany={(rows) => rows.map(row => companySchema.parse((row).company_object))} />
                </div>
            </div>
        </>
    )
}