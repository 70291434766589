import { Column, Row, Table } from "@tanstack/react-table";

import { cn } from "@/lib/utils";
import { WithTooltip } from "../../../shared/ui/base/tooltip";
import { getCompaniesByIds } from "@/services/company.service";
import { Company, companySchema } from "@/shared/schemas/entity/company.schema";
import { useQueryClient } from "@tanstack/react-query";
import OutOfCreditsDialog, { OutOfCreditsDialogHandle } from "../dialog/out-of-credits-dialog";
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification";
import { useRef, useState } from "react";
import { isChecked } from "./columns/column-utils";
import { ConfirmRevealCompanyDialog } from "../dialog/confirm-reveal-company-dialog";
import RevealButton from "../reveal-button";
import { FullCompany } from "@/schemas/entities/full-company.schema";

interface DataTableColumnHeaderProps
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<FullCompany>
    table: Table<FullCompany>,
    fromRowsToCompanies: (rows: Row<FullCompany>[]) => Company[]
}

export function DataTableCompanyColumnHeader({
    table,
    className,
    fromRowsToCompanies: fromRowToCompany,
}: DataTableColumnHeaderProps) {
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const outOfCreditsDialogRef = useRef<OutOfCreditsDialogHandle>(null)
    const [showConfirmRevealDialog, setShowConfirmRevealDialog] = useState(false)
    const [isDataFetching, setIsDataFetching] = useState(false)

    const are_all_company_visible = fromRowToCompany(table.getRowModel().rows).every(company => company.has_blurred_data === false)
    const selected_companies = table.getSelectedRowModel().rows.length > 0 ? fromRowToCompany(table.getSelectedRowModel().rows) : fromRowToCompany(table.getRowModel().rows)
    const companies_to_reveal = selected_companies.filter(company => company.has_blurred_data === true)

    const handleRevalAllCompanies = () => {
        const blurred_companies_id = companies_to_reveal.map(company => company.id)
        if (blurred_companies_id.length > 50) {
            setShowConfirmRevealDialog(true)
        } else {
            revealAllCompanies()
        }
    }

    const handleConfirmationRevealAllCompanies = () => {
        revealAllCompanies()
        setShowConfirmRevealDialog(false)
    }

    const revealAllCompanies = async () => {
        const rows = table.getRowModel().rows
        const blurred_companies_id = companies_to_reveal.map(company => company.id)
        if (blurred_companies_id.length === 0) return
        setIsDataFetching(true)
        getCompaniesByIds(blurred_companies_id).then((res) => {
            if (res.metadata.truncated_companies > 0) outOfCreditsDialogRef.current?.open()
            if (Array.isArray(res.data) && res.data.length > 0) {
                rows.map((row) => {
                    const company_id = fromRowToCompany([row])[0].id
                    const companies_same_id = res.data.filter(company => company.id === company_id)
                    if (companies_same_id.length > 0) {
                        const fullCompany = row.original
                        const newFullCompany = { ...fullCompany, ...companySchema.parse(companies_same_id[0]) }
                        if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, newFullCompany)
                    }
                })
            }
            queryClient.invalidateQueries({ queryKey: ['me'] })
            setIsDataFetching(false)
        }, () => {
            queryClient.invalidateQueries({ queryKey: ['me'] })
            setIsDataFetching(false)
            showErrorNotification()
        })
    }

    return (
        <>
            {!are_all_company_visible &&
                <div className={cn("flex items-center space-x-2", className)}>
                    <WithTooltip title={`Reveal ${companies_to_reveal.length} companies`} message={`Reveal ${companies_to_reveal.length} companies in return for ${companies_to_reveal.length} credits`}>
                        <RevealButton
                            isDataFetching={isDataFetching}
                            disabled={companies_to_reveal.length == 0}
                            revealCompany={handleRevalAllCompanies}
                            variant={isChecked(table) && companies_to_reveal.length > 0 ? 'default' : 'outline'}
                        />
                    </WithTooltip>
                    <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
                    {showConfirmRevealDialog && <ConfirmRevealCompanyDialog n_companies={companies_to_reveal.length} confirmAction={handleConfirmationRevealAllCompanies} cancelAction={() => setShowConfirmRevealDialog(false)} defaultOpen={true} />}
                </div>

            }
        </>
    )
}