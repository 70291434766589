import { Checkbox } from "@/components/ui/base/checkbox"
import CompanyCard from "@/components/ui/company/company-card"
import CountryCard from "@/components/ui/country/country-card"
import { DataTableColumnHeader } from "@/components/ui/table/data-table-column-header"
import { formatCompactNumber, formatNumberWithComma } from "@/shared/utils/number-utils"
import { FullCompany } from "@/schemas/entities/full-company.schema"
import { ColumnDef } from "@tanstack/react-table"
import TechnologyColumn from "./technology-column/technology-column"
import JobMatchingFiltersColumn from "./jobs-matching-filters-column"
import SelectAllCheckbox from "@/components/ui/table/data-table-select-all-checkbox"
import RevealCompanyButton from "./reveal-company-button"
import { DataTableCompanyColumnHeader } from "@/components/ui/table/data-table-company-column-header"
import HistoricalJobsColumn from "./historical-jobs-column"

export const hiddenCompanyColumns = { "company.revenue_usd": true, "num_jobs_found": false }

export const companyColumns: ColumnDef<FullCompany>[] = [
    {
        id: "select",
        header: ({ table }) => {
            return (<SelectAllCheckbox table={table} hasBlurredData={(row) => row.original.has_blurred_data || false} />)
        },
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px] ml-1.5"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        id: "reveal",
        accessorKey: "reveal",
        header: ({ column, table }) => (
            <DataTableCompanyColumnHeader column={column} title="Company" table={table} fromRowsToCompanies={(rows) => rows.map(row => row.original)} />
        ),
        cell: ({ row, table }) => {
            return (
                <RevealCompanyButton
                    company={row.original}
                    updateCompany={(company: FullCompany) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company) }} />
            )
        },
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Company" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original && <CompanyCard company={row.original} />}
                </>
            )
        },
        enableSorting: true,
    },

    {
        id: "company.country",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="HQ Country" />
        ),
        cell: ({ row }) => {
            return (
                <>
                    {row.original.country_code &&
                        <CountryCard countryIso={row.original.country_code} />

                    }
                </>
            )
        },
        enableSorting: false,
    },

    {
        id: "company.industry",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Industry" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-56 truncate font-normal">
                    {row.original.industry}
                </p>
            )
        },
        enableSorting: false,
    },
    {
        id: "employee_count",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Employees" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-20 truncate font-normal">
                    {row.original.employee_count ? formatNumberWithComma(row.original.employee_count) : ''}
                </p>
            )
        },
    },
    {
        id: "company.revenue_usd",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Revenue" />
        ),
        cell: ({ row }) => {
            return (
                <p className="max-w-20 truncate font-normal">
                    {row.original?.annual_revenue_usd ? '$' + formatCompactNumber(row.original.annual_revenue_usd) : ''}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: "company_city",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="City" />
        ),
        cell: ({ row }) => {
            return (
                <p>
                    {row.original?.city}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },

    {
        accessorKey: "num_jobs_found",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Jobs matching filters" />
        ),
        cell: ({ row, table }) => {
            return (
                <>
                    {table.options?.meta?.getCompanySearchParams &&
                        <JobMatchingFiltersColumn company={row.original} searchPams={table.options.meta.getCompanySearchParams()} updateCompany={(company: FullCompany) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company) }} />
                    }
                </>
            )
        },
        enableSorting: true,
        enableHiding: true,
    },
    {
        id: "technology_count",
        accessorKey: "company_object",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Technologies" />
        ),
        cell: ({ row, table }) => {
            return <TechnologyColumn company={row.original} updateCompany={(company: FullCompany) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company) }} />
        },
        enableSorting: false,
    },
    {
        accessorKey: "num_jobs",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Historical Jobs" />
        ),
        cell: ({ row, table }) => {
            return <HistoricalJobsColumn company={row.original} updateCompany={(company: FullCompany) => { if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company) }} />
        },
        enableSorting: true,
    },
]