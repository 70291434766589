import { getHTTPHeadersImpersonating } from "@/lib/authentication/authentication-http-headers";
import { SearchType } from "@/schemas/entities/recent-search.schema";
import { NewJobsSavedSearch, JobsSavedSearch, jobsSavedSearchSchema, SavedSearch, savedSearchSchema, CompanySavedSearch, companySavedSearchSchema, NewCompanySavedSearch } from "@/schemas/entities/saved-search.schema";
import { z } from "zod";

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/saved_searches'

export async function getAllSearches(type?: SearchType): Promise<SavedSearch[]> {
    const res = await fetch(RESOURCE_URL + (type ? `?types=${type}` : ''), { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(savedSearchSchema).parse(await res.json())
}

export async function getJobSearchById(id: number): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return jobsSavedSearchSchema.parse(await res.json())
}

export async function getCompanySearchById(id: number): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companySavedSearchSchema.parse(await res.json())
}

export async function updateJobSearch(id: number, search: JobsSavedSearch): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'PATCH', headers: getHTTPHeadersImpersonating(), body: JSON.stringify(search) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return jobsSavedSearchSchema.parse(await res.json())
}

export async function updateCompanySearch(id: number, search: CompanySavedSearch): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'PATCH', headers: getHTTPHeadersImpersonating(), body: JSON.stringify(search) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companySavedSearchSchema.parse(await res.json())
}

export async function renameSearch(id: number, name: string): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'PATCH', headers: getHTTPHeadersImpersonating(), body: JSON.stringify({ name: name }) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status
}

export async function deleteSearch(id: number): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'DELETE', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status
}

export async function createJobsSearch(newSearch: NewJobsSavedSearch): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL, { method: 'POST', headers: getHTTPHeadersImpersonating(), body: JSON.stringify(newSearch) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return jobsSavedSearchSchema.parse(await res.json())
}

export async function createCompanySearch(newSearch: NewCompanySavedSearch): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL, { method: 'POST', headers: getHTTPHeadersImpersonating(), body: JSON.stringify(newSearch) })
    if (!res.ok) throw new Error('Failed to fetch data')
    return companySavedSearchSchema.parse(await res.json())
}

