
import { useErrorNotification } from "@/components/hooks/toast/use-error-notification";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Card } from "@/shared/ui/base/card";
import { Link } from "react-router-dom";
import { getAllRecentSearches } from "@/services/recent-searches.service";
import { RecentSearch, SearchType } from "@/schemas/entities/recent-search.schema";
import { History } from "lucide-react";
import { Separator } from "@/shared/ui/base/separator";
import { DAY_IN_MS, timeAgo } from "@/shared/utils/date-utils";
import { UserWithTeam } from "@/schemas/entities/user";
import { getMyProfile } from "@/services/user.service";
import { Spinner } from "@/shared/ui/base/spinner";
import { useIntersectionObserver } from "usehooks-ts";
import { useEffect } from "react";
import { ScrollWithFadeOut } from "@/components/ui/scroll-fade-out";

export default function RecentSearchesCard({ search_type }: { search_type: SearchType }) {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: DAY_IN_MS,
    });
    const { data: searches, isLoading, isError, fetchNextPage, hasNextPage } = useInfiniteQuery<RecentSearch[]>({
        queryKey: ['recent-searches', search_type],
        queryFn: ({ pageParam }) => getAllRecentSearches(search_type, pageParam as number, me?.is_superuser ? undefined : (me?.id || 0)),
        getNextPageParam: (lastPage, allPages) => lastPage.length === 0 ? undefined : allPages.length,
        initialPageParam: 0,
        enabled: me != null,
    });
    const { isIntersecting: isVisible, ref: loadingButtonRef } = useIntersectionObserver({ threshold: 0.5 })
    useErrorNotification({ isError })

    useEffect(() => {
        if (isVisible && hasNextPage) {
            fetchNextPage();
        }
    }, [isVisible]);

    return (
        <div className="flex flex-col items-start space-y-4 w-full h-full relative">
            {isLoading &&
                < div className="flex flex-col space-y-2 w-full overflow-y-auto max-h-72">
                    {[...Array(8)].map((_, i) => (
                        <Card key={i} className="w-full flex flex-row p-2 space-x-2 items-center justify-between h-10 bg-gray-200 animate-pulse" />
                    ))
                    }
                </div>
            }
            {!isLoading && (!searches || searches.pages[0].length == 0) &&
                <div className="flex flex-col w-full items-center space-y-4 p-6">
                    <p className="text-base mt-6 text-gray-500">No recent searches yet</p>
                </div>
            }
            {!isLoading && searches && searches?.pages[0].length != 0 &&
                <ScrollWithFadeOut className="flex flex-col w-full max-h-72">
                    {searches.pages.map((group, i) => (
                        <div key={i}>
                            {group.map((recent_search, index) => (
                                <div key={recent_search.id}>
                                    <Row key={recent_search.id} recent_search={recent_search} />
                                    {index !== group.length - 1 && <Separator className="mx-1" />}
                                </div>
                            ))}
                        </div>
                    ))}
                    {hasNextPage &&
                        <div ref={loadingButtonRef} className="flex justify-center">
                            <Spinner size="sm" className="m-1" />
                        </div>
                    }
                </ScrollWithFadeOut >
            }
        </div>
    )
}

function Row({ recent_search }: { recent_search: RecentSearch }) {
    return (
        <Link to={`/search/${recent_search.type}/new?query=` + btoa(encodeURIComponent(JSON.stringify({ ...{ query: recent_search.body }, ...{ auto_search: true } })))}>
            < div className="flex flex-row p-2 space-x-2 items-center w-full hover:bg-gray-50">
                <div className="w-8 h-8 rounded-sm bg-slate-200 flex items-center justify-center shrink-0">
                    <History strokeWidth={1.5} />
                </div>
                <div className="flex flex-col space-y-1 overflow-hidden">
                    <p className="text-sm hover max-h-5 truncate  text-start"> {recent_search.name}</p>
                    <p className="text-xs text-gray-400">{timeAgo(recent_search.start_datetime)}</p>
                </div>
            </div>
        </Link >
    );
}



