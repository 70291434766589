import { useToast } from "@/components/ui/base/use-toast";
import { useEffect } from "react";

export function useErrorNotification({ isError, title = 'Uh oh! Something went wrong.', description = "There was a problem with your request.", variant = "destructive" }: { isError: boolean, title?: string, description?: string, variant?: "default" | "destructive" }) {
    const { toast } = useToast();
    useEffect(() => {
        if (isError) {
            showErrorNotification();
        }
    }, [isError]);

    const showErrorNotification = () => {
        toast({ title, description, variant });
    };

    return { showErrorNotification };

}