import { Industry, IndustryNested } from "@/schemas/entities/industry.schema";

export async function getAllIndustries(): Promise<Industry[]> {
    const url = `${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/industries?limit=1000`
    const res = await fetch(url, { method: 'GET', headers: { 'Accept': 'application/json' } })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    const json = await res.json() as { data: Industry[] };
    json.data[0].parent_id = 1
    return json.data;
}

export function buildIndustryHierarchy(industries: Industry[]): IndustryNested[] {
    function buildHierarchy(parentId: number | null): IndustryNested[] {
        return industries
            .filter(industry => industry.parent_id === parentId)
            .sort((a, b) => a.industry.localeCompare(b.industry))
            .map(industry => {
                const nestedIndustry = {
                    ...industry,
                    ...{ companies: totalChildrencompanies(industry.industry_id) + industry.companies },
                    children: buildHierarchy(industry.industry_id)
                };

                nestedIndustry.children.unshift({ ...industry, children: [] });
                return nestedIndustry;
            });
    }

    function totalChildrencompanies(industry_id: number): number {
        const directChildren = industries.filter(industry => industry.parent_id === industry_id);
        return directChildren.reduce((acc, industry) => {
            return acc + industry.companies + totalChildrencompanies(industry.industry_id);
        }, 0);
    }

    return buildHierarchy(null);
}
