import { z } from "zod"
import { jobSearchParamsSchema } from "../request/jobs-search-params.schema.ts"
import { techSearchParamsSchema } from "../request/tech-search-params.schema.ts"
import { companiesSearchParamSchema } from "../request/companies-search-params.schema.ts"

export const savedSearchTypeSchema = z.enum(['jobs', 'intent_details', 'companies'])

export type SavedSearchType = z.infer<typeof savedSearchTypeSchema>

export const savedSearchSchema = z.object({
    id: z.number(),
    name: z.string(),
    created_at: z.string(),
    updated_at: z.string(),
    emails_subscribed: z.array(z.string()),
    is_alert_active: z.boolean(),
    frequency: z.enum(['daily', 'weekly', 'monthly']).nullable().optional(),
    user_id: z.number(),
    creator: z.object({
        id: z.number(),
        email: z.string(),
        first_name: z.string().nullable().optional(),
        last_name: z.string().nullable().optional(),
    }),
    type: savedSearchTypeSchema,
})

export type SavedSearch = z.infer<typeof savedSearchSchema>

export const jobsSavedSearchSchema = savedSearchSchema.extend({
    body: jobSearchParamsSchema
})

export type JobsSavedSearch = z.infer<typeof jobsSavedSearchSchema>

export const newJobsSavedSearchSchema = z.object({
    type: savedSearchTypeSchema,
    body: jobSearchParamsSchema,
    is_alert_active: z.boolean(),
})

export type NewJobsSavedSearch = z.infer<typeof newJobsSavedSearchSchema>

export const techSavedSearchSchema = savedSearchSchema.extend({
    body: techSearchParamsSchema
})

export type TechSavedSearch = z.infer<typeof techSavedSearchSchema>

export const newTechSavedSearchSchema = z.object({
    type: savedSearchTypeSchema,
    body: techSearchParamsSchema,
    is_alert_active: z.boolean(),
})

export type NewTechSavedSearch = z.infer<typeof newTechSavedSearchSchema>

export const companySavedSearchSchema = savedSearchSchema.extend({
    body: companiesSearchParamSchema
})

export type CompanySavedSearch = z.infer<typeof companySavedSearchSchema>

export const newCompanySavedSearchSchema = z.object({
    type: savedSearchTypeSchema,
    body: companiesSearchParamSchema,
    is_alert_active: z.boolean(),
})

export type NewCompanySavedSearch = z.infer<typeof newTechSavedSearchSchema>
