
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/base/form";
import { Button } from "@/shared/ui/base/button";
import { Checkbox } from "@/components/ui/base/checkbox";
import { FormLabel } from "@/components/ui/base/form";
import { useQuery } from '@tanstack/react-query';
import { WEEK_IN_MS } from "@/shared/utils/date-utils";
import { Spinner } from "../../../shared/ui/base/spinner";
import { formatNumberWithComma } from "@/shared/utils/number-utils";
import { getAllTechnologyCategories } from "@/services/technology-category.service";
import { TechnologyCategory } from "@/schemas/entities/technology-category";
import { areObjectsEqual } from "@/lib/search-params-utils";
import { Tag } from "./components/tag";
import { Cross2Icon } from "@radix-ui/react-icons";

const FormSchema = z.object({
    items: z.array(z.string())
})

export function SearchFilterValuePreviewTechnologyCategories({ value }: { value: string[] }) {
    const { data: categories = [] } = useQuery<TechnologyCategory[]>({
        queryKey: ['technology-categories'],
        queryFn: getAllTechnologyCategories,
        staleTime: WEEK_IN_MS,
    });
    const length = value ? value.length : 0
    const valueLabels = value ? value.slice(0, 2).map((item) => categories.find((value) => value.slug === item)?.name) : []
    const valueString = valueLabels ? valueLabels.join(", ") : ''
    return <p className="text-sm">{`${valueString}${length > 2 ? `, + ${length - 2}` : ''}`}</p>
}

export function SearchFilterFormTechnologyCategories({ value, onChangeValue, onInteractOutside, closePopover }: { value: string[], onChangeValue: (_value: string[] | undefined) => void, onInteractOutside: boolean, closePopover: () => void }) {
    const [searchQuery, setSearchQuery] = useState("");
    const { data: categories = [], isLoading } = useQuery<TechnologyCategory[]>({
        queryKey: ['technology-categories'],
        queryFn: getAllTechnologyCategories,
        staleTime: WEEK_IN_MS,
    });
    const [selectedValues, setSelectedValues] = useState<TechnologyCategory[]>([]);
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            items: value || [],
        },
    })
    const formCurrentValue = useWatch({ control: form.control, name: 'items' })

    useEffect(() => {
        if (!formCurrentValue) return
        setSelectedValues(categories.filter((item) => formCurrentValue.includes(item.slug)))
    }, [formCurrentValue])

    const filteredItems = categories.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

    function onSubmit(data: z.infer<typeof FormSchema> | undefined) {
        const newValue = data && data.items && data.items.length > 0 ? data.items : undefined
        if (areObjectsEqual(newValue, value)) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }
    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues());
        }
    }, [onInteractOutside]);

    function sortbyStatus(): (a: TechnologyCategory, b: TechnologyCategory) => number {
        return (a, b) => {
            // Sort active items first
            if (value && value.includes(a.slug) && !value.includes(b.slug)) {
                return -1;
            }
            if (value && !value.includes(a.slug) && value.includes(b.slug)) {
                return 1;
            }
            // Sort remaining items alphabetically
            return a.name.localeCompare(b.name);
        };
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
                <div className={`relative flex flex-col items-start border rounded  max-h-40 overflow-y-auto ${selectedValues.length > 0 ? "p-2" : "px-3"}`} cmdk-input-wrapper="" >
                    <div className="flex flex-row gap-1 flex-wrap">
                        {selectedValues.map((item) => (
                            <Tag
                                key={item.slug}
                                id={item.slug}
                                name={item.name}
                                onClick={(slug) => {
                                    setSelectedValues(selectedValues.filter((item) => item.slug !== slug))
                                    form.setValue("items", selectedValues.filter((item) => item.slug !== slug).map((item) => item.slug))
                                }} />
                        ))}
                        <input
                            type="text"
                            value={searchQuery}
                            autoFocus={true}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                            className="h-8 min-w-12 rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                        />
                    </div>
                    {selectedValues.length > 0 && <Cross2Icon className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black" onClick={() => form.setValue("items", [])} />}
                </div>
                <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                        <FormItem className="max-h-[300px] overflow-scroll" >
                            <FormMessage />
                            {filteredItems.filter((item) => !selectedValues.map((item) => item.slug).includes(item.slug))
                                .sort(sortbyStatus())
                                .map((item) => (
                                    getFormField(item, form)
                                ))}

                            {isLoading &&
                                <div className="flex justify-center">
                                    <Spinner size="sm" />
                                </div>
                            }
                            {!isLoading && filteredItems.length === 0 &&
                                <div className="flex justify-center">
                                    <p className="text-sm text-gray-500 text-center">No results found for "{searchQuery}"</p>
                                </div>
                            }
                        </FormItem>
                    )}
                />
                <Button type="submit" size="sm">Save</Button>
            </form>
        </Form>
    )


}

function getFormField(item: TechnologyCategory, form: any) {
    return <FormField
        key={item.slug}
        control={form.control}
        name="items"
        render={({ field }) => {
            return (
                <FormItem
                    key={item.slug}
                    className="flex flex-row items-center space-x-3 space-y-0"
                >
                    <FormControl>
                        <Checkbox
                            id={item.slug}
                            checked={field.value?.includes(item.slug)}
                            onCheckedChange={(checked) => {
                                return checked
                                    ? field.onChange([...(field.value || []), item.slug])
                                    : field.onChange(
                                        field.value?.filter(
                                            (value: string) => value !== item.slug
                                        )
                                    );
                            }} />
                    </FormControl>
                    <FormLabel htmlFor={item.slug} className="text-sm font-normal cursor-pointer hover:underline">
                        <div>
                            <p className="text-sm font-normal"> {item.name} </p>
                            <p className="text-xs font-normal text-gray-500"> {formatNumberWithComma(item.number_of_companies)} companies </p>
                        </div>
                    </FormLabel>
                </FormItem>
            );
        }} />;
}
