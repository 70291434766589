import { z } from "zod";

export const currencySchema = z.enum(['eur', 'usd'])

export type Currency = z.infer<typeof currencySchema>

export const geolocationSchema = z.object({
    country_code: z.string(),
    currency: currencySchema,
});

export type Geolocation = z.infer<typeof geolocationSchema>;