
import { getHTTPHeaders } from "@/lib/authentication/authentication-http-headers"
import { TechSearchParams } from "@/schemas/request/tech-search-params.schema"
import { TechDetailsResponse, techDetailsResponseSchema } from "@/schemas/responses/tech-details-response.schema"
import { PaginationState } from "@tanstack/react-table"
import { sourceSessionParamsObject } from "@/lib/http-utils"
import { constructURL } from "@/lib/http-utils"


const RESOURCE_URL = '/v1/companies/technologies'

export async function getTechDetailsFromSearchParams(params: TechSearchParams, pagination: PaginationState, include_total_results: boolean): Promise<TechDetailsResponse> {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    newParams.include_total_results = include_total_results
    if (Array.isArray(newParams.order_by) && newParams.order_by?.length > 0 && newParams.order_by[0].field === 'company_name') {
        newParams.order_by[0].field = 'company.name'
    }
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}`, sourceSessionParamsObject())
    const res = await fetch(url, {
        method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(newParams)
    })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }

    return techDetailsResponseSchema.parse(await res.json())
}