import { DownloadIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/base/dropdown-menu";
import { Table } from "@tanstack/react-table";
import { Button } from "@/shared/ui/base/button";
import { WithTooltip } from "../../../../shared/ui/base/tooltip";
import { toStringAndSkipQuotationMarks } from "@/shared/utils/string-utils";
import { companyFromListSchema } from "@/schemas/entities/companies-from-company-list";
import { CSVLink } from 'react-csv';
import { formatNumberWithComma } from "@/shared/utils/number-utils";
import { EXCEL_LIB_MAX_CHAR_PER_LINE } from "@/pages/search/companies/components/action-export-companies";

export default function Export<TData>({ disabled, table }: { disabled: boolean, table: Table<TData> }) {

    const getTotalRows = () => table.getSelectedRowModel().rows.length !== 0 ? table.getSelectedRowModel().rows.length : table.getFilteredRowModel().rows.length

    const getData = () => {
        const rows = table.getSelectedRowModel().rows.length !== 0 ? table.getSelectedRowModel().rows : table.getFilteredRowModel().rows;
        const csvLines: string[][] = [[
            'company_name',
            'company_url',
            'company_country',
            'company_country_code',
            'company_linkedin_url',
            'company_industry',
            'company_employee_count',
            'company_seo_description',
            'company_description',
        ]];

        rows.forEach((row) => {
            const companyFromList = companyFromListSchema.parse(row.original)
            csvLines.push([
                toStringAndSkipQuotationMarks(companyFromList.company_object?.name),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.url),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.country),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.country_code),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.linkedin_url),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.industry),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.employee_count?.toString()),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.seo_description?.toString()),
                toStringAndSkipQuotationMarks(companyFromList.company_object?.long_description?.toString()),
            ]);
        });

        csvLines.forEach(line => {
            line.forEach((value, index) => {
                if (value.length > EXCEL_LIB_MAX_CHAR_PER_LINE) {
                    line[index] = value.slice(0, EXCEL_LIB_MAX_CHAR_PER_LINE);
                }
            });
        });

        return csvLines
    };
    const data = getData();
    const button = <Button variant="ghost" size="sm" className="text-sm font-normal" disabled={disabled}><DownloadIcon className="h-4 w-4 md:mr-2" /><p className="hidden lg:inline">Export</p></Button>

    return (
        <>
            {disabled &&
                <WithTooltip title="Please wait"> {button} </WithTooltip>
            }
            {!disabled &&
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        {button}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start" >
                        <DropdownMenuLabel>Export ({formatNumberWithComma(getTotalRows())})</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                            <CSVLink data={data} filename="companies.csv">.CSV</CSVLink>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            }
        </>
    )
}