


import { JobsSearchParams } from "@/schemas/request/jobs-search-params.schema"
import { JobsSavedSearch } from "@/schemas/entities/saved-search.schema"
import { DataTablePagination } from "@/components/ui/table/data-table-pagination"
import { DataTableToolbar } from "@/pages/search/jobs/[id]/components/data-table-toolbar"
import { DataTable } from "@/components/ui/table/data-table"
import { Job } from "@/schemas/entities/job.schema"
import { useFetchDataAndTotalResultsSeparatedForTable } from "@/components/hooks/data-table/use-fetch-data-and-total-results-for-table"
import { getJobsFromSearchParams } from "@/services/job.service"
import { hiddenJobColumns, jobsColumns } from "../../components/columns-job"
import { useColumnsVisibilityBasedOnSearch } from "../../components/use-columns-visibility-based-on-search"
import OutOfCreditsBanner from "@/components/ui/banners/out-of-credits-banner"
import DataTableInitialState from "@/components/ui/table/data-table-initial-state"

interface DataTableProps {
    search: JobsSavedSearch,
    setSearch: (search: JobsSavedSearch) => void
}

export function DataTableWithJobsToolbar({
    search,
    setSearch
}: DataTableProps) {
    const {
        table,
        metadata,
        searchedSP: searchedSearchParams,
        totalResults,
        columnVisibility,
        setColumnVisibility,
        dirtySP: dirtySearchParams,
        isDataFetching,
        isCurrentSearchSaved,
        isCurrentSearchSearched,
        onChangeFilters,
        setDirtySP: setDirtySearchParams,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
        setFiltersAsNotChanged,
        wasFirstSearchTriggered
    } = useFetchDataAndTotalResultsSeparatedForTable<JobsSearchParams, Job>(search.body, getJobsFromSearchParams, jobsColumns, hiddenJobColumns, true)
    useColumnsVisibilityBasedOnSearch(searchedSearchParams, columnVisibility, setColumnVisibility)

    return (
        <>
            {metadata.truncated_results > 0 &&
                <OutOfCreditsBanner hidden_results={metadata.truncated_results} />
            }
            <DataTableToolbar
                search={search}
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                setSearch={setSearch}
                table={table}
                dirtySearchParams={dirtySearchParams}
                setDirtySearchParams={setDirtySearchParams}
                totalResults={totalResults}
                isCurrentSearchSaved={isCurrentSearchSaved}
                onChangeFilters={onChangeFilters}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                setFiltersAsNotChanged={setFiltersAsNotChanged}
                metadata={metadata}
            />
            {!wasFirstSearchTriggered && <DataTableInitialState search_type="jobs" />}
            {wasFirstSearchTriggered &&
                <>
                    <DataTable table={table} columns={jobsColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            }
        </>
    )
}