import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';

export default function StyledMarkdown({ markdown }: { markdown: string }) {
    // Remove leading and trailing white spaces
    const markdown_formatted = markdown.split("\n").map(l => l.trim()).join('\n')
    return (
        <Markdown
            remarkPlugins={[remarkGfm]}

            components={{
                h1: ({ children }) => <h1 className="text-2xl font-bold mb-4">{children}</h1>,
                h2: ({ children }) => <h2 className="text-xl font-bold">{children}</h2>,
                h3: ({ children }) => <h3 className="text-lg font-bold">{children}</h3>,
                h4: ({ children }) => <h4 className="text-base font-bold">{children}</h4>,
                h5: ({ children }) => <h5 className="text-sm font-bold">{children}</h5>,
                h6: ({ children }) => <h6 className="text-sm font-bold">{children}</h6>,
                p: ({ children }) => <p className="text-gray-800  text-sm leading-relaxed mb-4">{children}</p>,
                em: ({ children }) => <em className="text-gray-800">{children}</em>,
                strong: ({ children }) => <strong>{children}</strong>,
                li: ({ children }) => <li className="text-gray-800 text-sm leading-relaxed  ml-6">{children}</li>,
                ol: ({ children }) => <ol className="list-decimal list-outside">{children}</ol>,
                ul: ({ children }) => <ul className="list-disc list-outside">{children}</ul>,
                a: ({ href, children, }) => <a href={href} className="text-primary hover:underline" target="_blank" rel="noopener noreferrer"> {children}</a>,
                blockquote: ({ children }) => <blockquote className="border-l-4 border-primary pl-4">{children}</blockquote>,
                code: ({ children }) => <code className="bg-gray-100 p-1 rounded-md">{children}</code>,
                img: ({ src, alt }) => <img src={src} alt={alt} className="w-full rounded-md" />,
                table: ({ children }) => <table className="table-auto w-full">{children}</table>,
                thead: ({ children }) => <thead className="bg-gray-200">{children}</thead>,
                tbody: ({ children }) => <tbody>{children}</tbody>,
                tr: ({ children }) => <tr>{children}</tr>,
                th: ({ children }) => <th className="text-left p-2">{children}</th>,
                td: ({ children }) => <td className="text-left p-2">{children}</td>,
            }}
        >{markdown_formatted}
        </Markdown>
    )
}