import { Button } from "@/shared/ui/base/button"
import {
    Dialog,
    DialogContent, DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "@/components/ui/base/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/base/form"
import { Input } from "@/components/ui/base/input"
import { useToast } from "@/components/ui/base/use-toast"
import { CompanyList } from "@/schemas/entities/company-list.schema"
import { createList } from "@/services/company_list.service"
import { zodResolver } from "@hookform/resolvers/zod"
import { ReactNode, useState } from "react"
import { useForm } from "react-hook-form"
import { useQueryClient } from "@tanstack/react-query"
import { z } from "zod"

const FormSchema = z.object({
    name: z.string().min(1, "You have to enter a name."),
})


export default function CreateCompanyListButton({ children, onCreate }: { children?: ReactNode, onCreate?: (id: number) => void }) {
    const queryClient = useQueryClient()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const { toast } = useToast()
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: '',
        },
    })

    function onSubmit(data: z.infer<typeof FormSchema>) {
        createList(data.name).then((company_list: CompanyList) => {
            setIsDialogOpen(false)
            queryClient.invalidateQueries({ queryKey: ['company-list'] })
            toast({ title: "List created" })
            if (onCreate) {
                onCreate(company_list.id)
            }
        })
    }


    return (
        <Dialog open={isDialogOpen} onOpenChange={() => setIsDialogOpen(!isDialogOpen)}>
            <DialogTrigger asChild>
                {children}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]" >
                <DialogHeader>
                    <DialogTitle>New company list</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="name" className="col-span-1">Name</FormLabel>
                                    <FormControl>
                                        <Input type="name"  {...field} autoComplete="off" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter className="pt-6">
                            <Button type="submit">Create</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
