import { Separator } from "@/shared/ui/base/separator"
import { SidebarNav } from "./components/sidebar-nav"
import { Outlet } from "react-router-dom"
import { PageLayout } from "@/components/ui/layout/page_layout"

const sidebarNavItems = [
    {
        title: "Billing",
        href: "/settings/billing",
    },
    {
        title: "Team",
        href: "/settings/team",
    },
    {
        title: "API",
        href: "/settings/api",
    },
    {
        title: "Integrations",
        href: "/settings/integrations",
    },
]

export default function SettingsLayout() {
    return (
        <PageLayout>
            <div className="space-y-0.5">
                <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
                <p className="text-muted-foreground">
                    Manage your account settings
                </p>
            </div>
            <Separator className="my-6" />
            <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                <aside className="lg:w-1/5">
                    <SidebarNav items={sidebarNavItems} />
                </aside>
                <div className="flex-1">
                    <Outlet />
                </div>
            </div>
        </PageLayout>
    )
}

