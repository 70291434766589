

import * as React from "react"
import {
    ColumnDef,
    ColumnFiltersState,
    OnChangeFn,
    PaginationState,
    SortingState,
    VisibilityState, getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table"

import { DataTablePagination } from "@/components/ui/table/data-table-pagination"
import { DataTable } from "@/components/ui/table/data-table"
import { DataTableToolbar } from "./data-table-toolbar"
import { CompanyFromListSearchParams } from "@/schemas/request/companies-from-list-search-params.schema"
import { CompanyFromList } from "@/schemas/entities/companies-from-company-list"


interface DataTableProps {
    columns: ColumnDef<CompanyFromList>[]
    data: CompanyFromList[],
    isDataFetching: boolean,
    sorting: SortingState,
    pagination: PaginationState,
    setPagination: OnChangeFn<PaginationState>,
    onChangeSorting: OnChangeFn<SortingState>,
    onChangeFilters: (_params: CompanyFromListSearchParams) => void,
    searchedSP: CompanyFromListSearchParams,
    totalResults: number,
    filter_by?: string,
}

export function DataTableWithPagination({
    columns,
    data,
    isDataFetching,
    sorting,
    pagination,
    searchedSP,
    setPagination,
    onChangeFilters,
    onChangeSorting,
    totalResults,
    filter_by,
}: DataTableProps) {
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])

    const table = useReactTable({
        data,
        columns,
        pageCount: Math.ceil(totalResults / pagination.pageSize),
        state: {
            sorting,
            pagination,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        manualPagination: true,
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        onSortingChange: onChangeSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    return (
        <>
            <DataTableToolbar table={table} filter_by={filter_by} isDataFetching={isDataFetching} onChangeFilters={onChangeFilters} searchedSP={searchedSP} />
            <DataTable table={table} columns={columns} isDataFetching={isDataFetching} />
            <DataTablePagination table={table} totalResults={totalResults} />
        </>

    )
}