import { z } from "zod"

export const companyListSchema = z.object({
    id: z.number(),
    name: z.string(),
    created_at: z.string(),
    deletable: z.boolean(),
    companies_count: z.number(),

})

export type CompanyList = z.infer<typeof companyListSchema>