import { getHTTPHeaders } from "@/lib/authentication/authentication-http-headers";
import { constructURL } from "@/lib/http-utils";
import { Technology } from "@/schemas/entities/technology.schema";

export async function getTechnologies(page: number, limit: number): Promise<Technology[]> {
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/technologies`, {
        limit,
        page,
        source: 'app'
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=86400' } })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    return await res.json() as Technology[];
}

export async function getTechnologiesByName(name_pattern: string): Promise<Technology[]> {
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/technologies`, {
        limit: 30,
        name_pattern,
        source: 'app'
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=600' } })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    return await res.json() as Technology[];
}

export async function getTechnologiesBySlugs(slugs: string[]): Promise<Technology[]> {
    if (!slugs || slugs.length === 0) {
        return [];
    }
    const url = constructURL(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/technologies`, {
        slugs: slugs,
        source: 'app'
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=86400' } })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    return await res.json() as Technology[];
}