
import { Button } from "../../../../shared/ui/base/button";
import { HelpCircle, MailIcon, VideoIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../base/dropdown-menu";

export default function HelpButton() {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                >
                    <HelpCircle className="w-5 h-5" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuGroup>
                    <a href="https://theirstack.com/en/docs/introduction" target="_blank" >
                        <DropdownMenuItem className="cursor-pointer">
                            Docs and Guides
                        </DropdownMenuItem>
                    </a>
                    <a href="https://join.slack.com/t/theirstack-community/shared_invite/zt-29lbkbrap-uwHDo9aOryYiRhPjDnQasg" target="_blank" >
                        <DropdownMenuItem className="cursor-pointer">
                            Join our Community in Slack
                        </DropdownMenuItem>
                    </a>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <a href="https://zcal.co/christianpalou/30min" target="_blank" >
                    <DropdownMenuItem className="cursor-pointer">
                        <VideoIcon className="w-4 h-4 mr-1" /> Book a call with us
                    </DropdownMenuItem>
                </a>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger><MailIcon className="w-4 h-4 mr-1" /> Contact us</DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                            <a href="mailto:founders@theirstack.com" target="_blank" >
                                <DropdownMenuItem>Email</DropdownMenuItem>
                            </a>
                            <a href="https://join.slack.com/t/theirstack-community/shared_invite/zt-29lbkbrap-uwHDo9aOryYiRhPjDnQasg" target="_blank" >
                                <DropdownMenuItem className="cursor-pointer">
                                    Slack
                                </DropdownMenuItem>
                            </a>
                        </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                </DropdownMenuSub>

            </DropdownMenuContent>
        </DropdownMenu >
    )
}