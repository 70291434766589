import CreditsButton from "./components/credits_button";
import HelpButton from "./components/help_button";
import UpgradeButton from "./components/upgrade_button";
import { DesktopNavLinks, MobileNavLinks } from "./components/nav_links";
import { DesktopUserNav, MobileUserNav } from "./components/user_nav";
import UserSwitcher from "./components/user_switcher";
import { Link } from "react-router-dom";
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from "../base/sheet";
import { Menu } from "lucide-react";
import { useState } from "react";
import { HOME_PATH } from "@/paths";
import { CompanySearchBar } from "./components/company-search-bar";
import { cn } from "@/lib/utils";
import { Separator } from "../../../shared/ui/base/separator";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { getMyProfile } from "@/services/user.service";
import { THERE_MINUTE_IN_MS } from "@/shared/utils/date-utils";
import { isFreePlan, UserWithTeam } from "@/schemas/entities/user";
import { useQuery } from "@tanstack/react-query";


export default function NavBar({ className }: { className?: string }) {
    const [isOpen, setIsOpen] = useState(false)
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    });

    const showUpgradeButton = me?.team && !me?.is_superuser && isFreePlan(me)

    return (
        <div className={cn("border-b bg-white", className)}>
            <div className="block lg:hidden">
                <div className="flex h-12 md:h-16 px-4 justify-between items-center ">
                    <Link to={HOME_PATH} className="-m-1.5 p-1.5 inline-flex items-center">
                        <span className="sr-only">TheirStack</span>
                        <img className="h-3.5 md:h-4" src="/theirstack-logo-name.webp" alt="TheirStack Logo" />
                    </Link>
                    <Sheet open={isOpen} onOpenChange={() => setIsOpen(!isOpen)} >
                        <SheetTrigger asChild>
                            <Menu className="h-5 w-5" />
                        </SheetTrigger>
                        <SheetContent className="max-w-[300px] overflow-auto" aria-describedby={undefined} autoFocus={false}>
                            <VisuallyHidden.Root asChild><SheetTitle>Mobile Nav Bar</SheetTitle></VisuallyHidden.Root>
                            <MobileNavLinks handleOnClick={() => setIsOpen(false)} />
                            <Separator />
                            <MobileUserNav handleOnClick={() => setIsOpen(false)} />
                            <div className="w-full mt-10 flex flex-wrap gap-2">
                                {showUpgradeButton &&
                                    <UpgradeButton />
                                }
                                <CreditsButton />
                                <HelpButton />
                            </div>
                        </SheetContent>
                    </Sheet >
                </div>
            </div>

            <div className="hidden lg:block">
                <div className="flex h-16 px-6">
                    <nav className="flex items-center space-x-2">
                        <Link to={HOME_PATH} className="-m-1.5 p-1.5 inline-flex items-center">
                            <span className="sr-only">TheirStack</span>
                            <img className="h-4" src="/theirstack-logo-name.webp" alt=" TheirStack Logo" />
                        </Link>
                        <DesktopNavLinks />
                    </nav>

                    <div className="ml-auto flex items-center space-x-2">
                        <CompanySearchBar />
                        <UserSwitcher />
                        {showUpgradeButton &&
                            <UpgradeButton />
                        }
                        <CreditsButton />
                        <HelpButton />
                        <DesktopUserNav />
                    </div>
                </div>
            </div>

        </div >
    )
}