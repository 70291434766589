import { Company } from "@/shared/schemas/entity/company.schema";
import { formatDateStringToReadableDate } from "@/shared/utils/date-utils";
import { formatNumberWithComma } from "@/shared/utils/number-utils";
import { CalendarIcon, DollarSign, FactoryIcon, FrameIcon, GlobeIcon, MapPin, TagsIcon } from "lucide-react";

export function CompanyProperties({ company }: { company: Company }) {

    const properties = [
        { name: 'Industry', value: company.industry, icon: <FactoryIcon size={15} /> },
        { name: 'Employees', value: company.employee_count ? formatNumberWithComma(company.employee_count) : undefined, icon: <FrameIcon size={15} /> },
        { name: 'HQ City', value: company.city, icon: <MapPin size={15} /> },
        { name: 'HQ Country', value: company.country, icon: <GlobeIcon size={15} /> },
        { name: 'Annual revenue', value: company.annual_revenue_usd ? "$" + formatNumberWithComma(company.annual_revenue_usd) : undefined, icon: <DollarSign size={15} /> },
        { name: 'Total funding', value: company.total_funding_usd ? "$" + formatNumberWithComma(company.total_funding_usd) : undefined, icon: <DollarSign size={15} /> },
        { name: 'Last funding round date', value: company.last_funding_round_date ? formatDateStringToReadableDate(company.last_funding_round_date) : undefined, icon: <DollarSign size={15} /> },
        { name: 'Founding year', value: company.founded_year, icon: <CalendarIcon size={15} /> },
        { name: 'Founding stage', value: company.funding_stage, icon: <DollarSign size={15} /> },
        { name: 'Keywords', value: company.company_keywords?.join(', '), icon: <TagsIcon size={15} /> },
    ]

    return (
        <table className="table-auto border-separate  border-spacing-y-2">
            <tbody>
                {properties.filter(property => property.value).map((property) => (
                    <tr key={property.name}>
                        <td className="grid grid-flow-col auto-cols-auto justify-start gap-1 items-center pr-2">
                            {property.icon}
                            <p className="text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-nowrap">{property.name}</p>
                        </td>
                        <td>
                            <p className="text-sm peer-disabled:cursor-not-allowed peer-disabled:opacity-70">{property.value}</p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table >
    )
}