import { useAuthentication } from "@/components/hooks/authentication/use-authentication"
import { Button } from "@/shared/ui/base/button"

function UserBlockedPage() {
    const { logout, isAuthenticated } = useAuthentication()

    return (
        <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-12 lg:px-8">
            <p className="text-base font-semibold leading-8 text-primary">409</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">User blocked</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Emails from disposable email providers are not allowed. Please sign up with a different email provider or contact support (founders@theirstack.com) if you think this is an error.</p>
            <div className="mt-10">
                {isAuthenticated && (
                    <Button onClick={() => logout()}>Log out</Button>
                )}
            </div>
        </main>
    )
}
export default UserBlockedPage